<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
                <el-form-item label="用户编号">
                    <el-input v-model="query.userNumber" placeholder="请输入用户编号"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item label="审核状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in statusAry" :key="label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="userNumber" label="用户编号" min-width="120" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="nickName" label="用户昵称" min-width="120" align="center"></el-table-column>
            <el-table-column prop="gender" label="性别" min-width="50" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.gender === 0" style="color: #c78b13">未知</span>
					<span v-else-if="scope.row.gender === 1" style="color: #112db9">男</span>
					<span v-else-if="scope.row.gender === 2" style="color: #b91187">女</span>
				</template>
			</el-table-column>
            <el-table-column prop="personalityName" label="社交人格" min-width="80" align="center"></el-table-column>
            <el-table-column prop="applyCount" label="提审次数" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.applyCount }}
                    <div class="color-primary link-btn" @click="showBeforeRefuse(scope.row.id)" v-if="scope.row.applyCount > 1">查看上次驳回原因</div>
                </template>
            </el-table-column>
            <el-table-column label="实名照片" min-width="140" align="center">
                <template slot-scope="scope">
                    <div class="flex">
                        <video v-if="scope.row.faceVideo" controls autoplay loop muted style="height: 60px; width: 60px; padding: 2px;" :src="fileDomain + scope.row.faceVideo" />
                        <el-image v-if="scope.row.faceImage" style="height: 60px; width: 60px; padding: 2px;" :src="fileDomain + scope.row.faceImage" :preview-src-list="[fileDomain + scope.row.faceImage]"></el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="images" label="真人照资料" min-width="300" align="center">
                <template slot-scope="scope">
                    <div class="flex">
                        <div class="images-item" v-if="scope.row?.cover.indexOf('.gif') !== -1">
                            <el-image style="width: 100%; height: 100%;" :src="fileDomain + scope.row.cover" :preview-src-list="[fileDomain + scope.row.cover]" ></el-image>
                            <i class="el-icon-s-ticket cover-icon"></i>
                        </div>
                        <div class="images-item" v-for="(file, index) in scope.row.files" :key="index">
                            <template v-if="file.indexOf('mp4') > -1">
                                <video style="width: 100%; height: 100%;" controls autoplay loop muted :src="fileDomain + file" />
                            </template>
                            <template v-else>
                                <el-image style="width: 100%; height: 100%;" :src="fileDomain + file" :preview-src-list="[fileDomain + file]" ></el-image>
                            </template>
                            <i class="el-icon-s-ticket cover-icon" v-if="scope.row.cover === file"></i>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="categorys" label="达人分类" min-width="150" align="center"></el-table-column>
            
            <el-table-column prop="price" label="期望价格（k币/分钟）" min-width="180" align="center"></el-table-column>
            <el-table-column prop="labels" label="达人介绍标签" min-width="240" align="center">
                <!-- <template slot-scope="scope">
                    
                </template> -->
            </el-table-column>
            
            <el-table-column prop="createTime" label="时间" min-width="140" align="center"></el-table-column>
            <el-table-column prop="status" label="审核状态" min-width="80" align="center">
				<template slot-scope="scope">
					<span :class="[`color-${statusColors[scope.row.status]}`]">{{statusAry[scope.row.status]}}</span>
				</template>
			</el-table-column>
            <el-table-column fixed="right" label="操作" min-width="280" align="center">
                <template slot-scope="scope">
                    <!-- <el-button v-if="scope.row.status === 0" type="success" size="mini" @click="verifyClick(scope.row.id, 1, 0, null)">一键同意</el-button> -->
                    <template v-if="scope.row.status === 0 || scope.row.status === 3">
                        <el-button type="success" size="mini" icon="el-icon-check" @click="approved(scope.row)">通过</el-button>
                        <el-button type="warning" size="mini" icon="el-icon-close" @click="refuse(scope.row)">驳回</el-button>
                    </template>
                    <template v-if="scope.row.status === 0">
                        <el-button type="danger" size="mini" icon="el-icon-release" @click="disableApply(scope.row)">禁止申请</el-button>
                    </template>
                    <!-- <template v-else-if="scope.row.status === 3">
                        <el-button type="primary" size="mini" icon="el-icon-check" @click="unblockApply(scope.row)">解禁申请</el-button>
                    </template> -->
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog title="是否通过认证？" :visible.sync="approvedDialogVisible" width="30%" :close-on-click-modal="false" :before-close="approvedDialogBeforeClose">
            <div class="flex">
                <div>该用户期望的视频聊天价格为：</div>
                <div>
                    <span class="color-warning" v-if="newPrice !== null">{{newPrice}}</span>
                    <span v-else>{{verifyRow?.price}}</span>
                    k币/分钟
                    </div>
                <div>
                    <el-button type="text" size="mini" @click="editPrice">修改</el-button>
                </div>
            </div>
        
            <div class="cover">
                <div>封面</div>
                <template v-if="verifyRow?.cover.indexOf('mp4') > -1">
                    <div v-if="newGifCover">
                        <el-image :src="fileDomain + newGifCover" style="width: 100px;" :preview-src-list="[fileDomain + newGifCover]" />
                    </div>
                    <div v-else>所选封面为视频文件 需进行截取并生成GIF</div>
                    <el-button type="primary" size="mini" @click="openGifDialog">操作</el-button>
                </template>
                <template v-else>
                    <el-image :src="fileDomain + verifyRow?.cover" style="width: 100px;" :preview-src-list="[fileDomain + verifyRow?.cover]" />
                </template>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeApprovedDialog">取 消</el-button>
				<el-button type="primary" :disabled="verifyConfirmDisabled" :loading="verifyLoading" @click="verifyApproved">确 定</el-button>
            </div>
        </el-dialog>
        
        <!-- <el-dialog title="生成gif" :visible.sync="gifDialogVisible" width="60%" :close-on-click-modal="false" :before-close="gifDialogCloseBefore">
            <el-card class="video-card">
                <div class="video-container">
                    <video ref="videoPlayer" class="video-player" controls muted crossorigin="anonymous" :src="videoUrl" :key="videoUrl"
                        @loadedmetadata="onVideoLoaded" @timeupdate="onTimeUpdate">
                    </video>

                    <div class="video-gif" v-if="gifFile">
                        <img :src="previewGifUrl" alt="GIF Preview" class="gif-preview" v-if="previewGifUrl" />
                        <div class="file-size" v-if="gifFileSize !== null">文件大小：{{formatFileSize(gifFileSize)}}</div>
                    </div>
                </div>
                <div class="time-range-selector">
                    <div class="time-slider">
                        <el-slider v-model="timeRange" range :min="0" :max="videoDuration" :step="0.1"
                            :format-tooltip="formatTime" @input="onRangeSliderChange"></el-slider>
                    </div>
                    <div class="time-inputs">
                        <span>开始时间：</span>
                        <el-input v-model="startTime" placeholder="00:00.000" @blur="validateTimeBlur"
                            @change="validateTimeChange" class="time-input" />
                        <span>结束时间：</span>
                        <el-input v-model="endTime" placeholder="00:00.000" @blur="validateTimeBlur"
                            @change="validateTimeChange" class="time-input" />
                        <div class="quick-time-buttons">
                            <el-button size="small" @click="setTimeRange(5)">5秒</el-button>
                            <el-button size="small" @click="setTimeRange(10)">10秒</el-button>
                            <el-button size="small" @click="setTimeRange(15)">15秒</el-button>
                        </div>
                    </div>
                </div>
                <div class="controls">
                    <el-button @click="gifDialogVisible = false">取 消</el-button>
                    <el-button type="primary" :loading="captureVideoing" @click="captureFrameRange"
                        :disabled="!videoUrl || !isValidTimeRange">预览GIF</el-button>
                    <el-button type="success" :loading="gifUploading" :disabled="gifFile === null" @click="uploadGif">确认</el-button>
                </div>
            </el-card>
        </el-dialog> -->

        <GifDialog ref="GifDialog" @confirm="confirmGif" />

        <el-dialog title="驳回理由" :visible.sync="refuseDialogVisible" width="600px" :close-on-click-modal="false">
            <div class="refuse-item" v-for="item in refuseFields" :key="item.field">
                <div class="title-row">
                    <div class="title">{{item.label}}</div>
                    <el-button :type="refuseData[item.field + 'Toggle'] ? 'danger' : 'primary'" size="mini" class="toggle" @click="refuseFieldToggle(item.field)">{{refuseData[item.field + 'Toggle'] ? '取消填写' : '填写理由'}}</el-button>
                </div>
                <el-input type="textarea" :rows="3" v-model="refuseData[item.field + 'Reason']" :ref="`${item.field}ReasonRef`" placeholder="请输入驳回原因" v-show="refuseData[item.field + 'Toggle']" />
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="refuseDialogVisible = false">取 消</el-button>
				<el-button type="primary" :disabled="refuseConfirmDisabled" :loading="verifyLoading" @click="verifyRefuse">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="上次驳回原因" width="600px" :visible.sync="beforeRefuseDialogVisible" v-loading="getBeforeRefuseLoading">
            <template v-if="beforeRefuseData">
                <el-descriptions :column="1">
                    <el-descriptions-item label="上次驳回时间">{{ beforeRefuseData.createTime }}</el-descriptions-item>
                </el-descriptions>
                <div style="height: 30px;"></div>
                <el-descriptions :column="1" title="驳回原因">
                    <template v-for="item in refuseFields">
                        <el-descriptions-item :label="item.label" :key="item.field" v-if="item.field + 'Status' in beforeRefuseData && beforeRefuseData[item.field + 'Status'] === 2">{{ beforeRefuseData[item.field + 'Reason'] }}</el-descriptions-item>
                    </template>
                </el-descriptions>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import GIF from 'gif.js'
import setTableHeight from '@/utils/setTableHeight'
import { getTalentApplyPage, verifyTalent, fileUpload, getTalentApplyRefuse } from '@/api/api'
import GifDialog from './components/GifDialog.vue'
export default {
    components: {
        GifDialog
    },
    data() {
        return {
            fileDomain: 'https://file.oiki.cc/',
            page: 1,
            size: 10,
            total: 0,
            query: {
                userNumber: '',
                nickName: '',
                status: 0,
            },
            tableHeight: null,
            dataList: [{}],

            statusAry: ['待审核','已通过','已驳回','禁止申请','禁用达人'],
            statusColors: ['warning','success','danger','danger','danger'],

            verifyRow: null,
            newGifCover: null,
            newPrice: null,
            verifyLoading: false,

            refuseFields: [
                { field:'category', label: '达人分类' },
                { field:'content', label: '真人照片或视频' },
                { field:'price', label: '视频聊天价格' },
                { field:'label', label: '达人介绍标签' },
            ],
            refuseData: {},

            beforeRefuseData: null,
            getBeforeRefuseLoading: false,
            beforeRefuseDialogVisible: false,

            approvedDialogVisible: false,
            refuseDialogVisible: false,
            
            gifDialogVisible: false,
            videoUrl: null,
            selectedFrames: [],
            currentTime: 0,
            sliderTime: 0,
            timeRange: [0, 0],
            frameDelay: 100,
            startTime: '',
            endTime: '',
            captureVideoing: false,
            gifWidth: 167,
            gifHeight: 210,
            frameInterval: 0.1,
            videoDuration: 0,
            previewGifUrl: null,
            gifFile: null,
            gifFileSize: null,

            gifUploading: false,
        }
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        },
        verifyRow: {
            handler() {
                this.gifFile = null
            }
        }
    },
    computed: {
        isValidTimeRange() {
            const start = this.parseTimeString(this.startTime)
            const end = this.parseTimeString(this.endTime)
            return start !== null && end !== null && start < end && end <= this.videoDuration
        },
        verifyConfirmDisabled() {
            if (this.verifyRow === null || (this.verifyRow?.cover.indexOf('mp4') > -1 && this.newGifCover === null)) {
                return true
            }
            return false
        },
        refuseConfirmDisabled() {
            return this.refuseFields.every(item => this.refuseData[item.field + 'Reason'] === '')
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getVerifyList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getVerifyList();
        },
        getVerifyList() {
            getTalentApplyPage({
                current: this.page,
                size: this.size,
                ...this.query
            }).then(({total, records}) => {
                this.total = total;
                this.dataList = records.map(item => {
                    const files = item.content?.split(',')
                    if (files) {
                        item.files = files
                    }

                    return item
                });
            })
        },
        showBeforeRefuse(id) {
            this.getBeforeRefuseLoading = true
            this.beforeRefuseDialogVisible = true
            getTalentApplyRefuse(id).then(res => {
                this.beforeRefuseData = res
                console.log(this.beforeRefuseData,'beforeRefuseData')
            }).finally(() => {
                this.getBeforeRefuseLoading = false
            })
        },
        async approved(row) {
            if (row.status === 3) {
                await new Promise((resolve) => {
                    this.$confirm('当前用户被禁止申请达人，是否要继续审核？','提示').then(() => resolve())
                })
            }

            this.verifyRow = row
            this.approvedDialogVisible = true
        },
        async refuse(row) {
            if (row.status === 3) {
                await new Promise((resolve) => {
                    this.$confirm('当前用户被禁止申请达人，是否要继续审核？','提示').then(() => resolve())
                })
            }

            this.verifyRow = row
            this.refuseDialogVisible = true
            this.initRefuseData()
        },
        disableApply(row) {
            this.$prompt('确认禁止后，该用户将无法再次申请达人认证，APP端无申诉入口，请谨慎操作！','禁止再次申请', {
                closeOnClickModal: false,
                inputType: 'textarea',
                inputPlaceholder: '请输入禁止原因',
                inputPattern: /(.+)/,
                inputErrorMessage: '请输入禁止原因'
            }).then(({ value }) => {
                verifyTalent({
                    id: row.id,
                    remark: value,
                    status: 3
                }).then(() => {
                    this.getVerifyList()
                }).finally(() => {
                    this.verifyLoading = false
                })
            })
        },
        unblockApply(row) {
            /* verifyTalent({
                id: row.id,
                status: 0
            }).then(() => {
                this.getVerifyList()
            }) */
        },
        initVerifyData() {
            this.verifyRow = null
            this.newPrice = null
        },
        initRefuseData() {
            this.refuseData = {}
            for (const item of this.refuseFields) {
                this.$set(this.refuseData, item.field + 'Toggle', false)
                this.$set(this.refuseData, item.field + 'Reason', '')
            }
        },
        refuseFieldToggle(field) {
            this.refuseData[field + 'Toggle'] = !this.refuseData[field + 'Toggle']
            if (this.refuseData[field + 'Toggle']) {
                this.$nextTick(() => {
                    this.$refs[`${field}ReasonRef`][0].focus()
                })
            }
        },
        async closeApprovedDialog() {
            if (this.newGifCover) {
                await new Promise((resolve) => {
                    this.$confirm('已生成GIF封面，是否要关闭当前弹窗？','提示').then(() => {
                        this.newGifCover = null
                        resolve()
                    })
                })
            }
            this.approvedDialogVisible = false
            this.initVerifyData()
        },
        editPrice() {
            this.$prompt('修改用户期望的视频聊天价格','聊天价格', {
                inputPlaceholder: '请输入整数数值'
            }).then(({value}) => {
                if (parseInt(value) < 0) {
                    this.$message.error('价格不能小于0')
                    return;
                }

                if (!isNaN(parseInt(value))) {
                    this.newPrice = parseInt(value)
                }
            })
        },
        approvedDialogBeforeClose(done) {
            if (this.newGifCover) {
                this.$confirm('已生成GIF封面，是否要关闭当前弹窗？','提示').then(() => {
                    this.newGifCover = null
                    done() 
                })
            } else {
                done()
            }
        },
        verifyApproved() {
            if (this.verifyConfirmDisabled) return;
            const cover = this.verifyRow?.cover.indexOf('mp4') > -1 ? this.newGifCover : this.verifyRow.cover
            const formData = {
                cover,
                price: this.newPrice || this.verifyRow.price,
                id: this.verifyRow.id,
                status: 1
            }
            console.log(formData, 'formData')
            // return;
            this.verifyLoading = true
            verifyTalent(formData).then(() => {
                this.approvedDialogVisible = false
                this.verifyRow = null
                this.newGifCover = null
                this.getVerifyList()
            }).finally(() => {
                this.verifyLoading = false
            })
        },
        verifyRefuse() {
            const refuseData = {}
            for (const item of this.refuseFields) {
                if (this.refuseData[item.field + 'Toggle']) {
                    refuseData[item.field + 'Reason'] = this.refuseData[item.field + 'Reason']
                }
            }
            const formData = {
                id: this.verifyRow.id,
                status: 2,
                ...refuseData
            }
            console.log(formData,'formData')
            // return;
            this.verifyLoading = true
            verifyTalent(formData).then(() => {
                this.refuseDialogVisible = false
                this.initRefuseData();
                this.getVerifyList()
            }).finally(() => {
                this.verifyLoading = false
            })
        },
        confirmGif(gifPath) {
            this.newGifCover = gifPath
        },
        openGifDialog() {
            if (this.verifyRow?.cover.indexOf('.mp4') > -1) {
                // this.gifDialogVisible = true;
                this.$refs.GifDialog.openGifDialog(this.verifyRow.cover)
            }
            // this.videoUrl = this.fileDomain + this.verifyRow.cover
            // console.log(this.videoUrl,'this.videoUrl')
        },
        onVideoLoaded() {
            console.log('onVideoLoaded')
            // this.$refs.videoPlayer.crossOrigin = 'anonymous'
            this.videoDuration = this.$refs.videoPlayer.duration
            this.timeRange = [0, this.videoDuration]
            this.startTime = this.formatTime(0)
            this.endTime = this.formatTime(this.videoDuration)
        },
        parseTimeString(timeStr) {
            const pattern = /^(\d{2}):(\d{2})\.(\d{3})$/
            const match = timeStr.match(pattern)
            if (!match) return null

            const minutes = parseInt(match[1])
            const seconds = parseInt(match[2])
            const milliseconds = parseInt(match[3])

            return minutes * 60 + seconds + milliseconds / 1000
        },
        validateTimeChange() {
            if (!this.startTime || !this.endTime) return
            if (!this.isValidTimeRange) {
                this.$message.warning('请输入有效的时间范围，格式为 MM:SS.mmm')
            }
        },
        validateTimeBlur() {
            const time = this.parseTimeString(this.startTime)
            const endTime = this.parseTimeString(this.endTime)

            if (time !== null) {
                // 如果开始时间有效，更新滑块的开始值
                this.timeRange = [time, this.timeRange[1]]
            }

            if (endTime !== null) {
                // 如果结束时间有效，更新滑块的结束值
                this.timeRange = [this.timeRange[0], endTime]
            }
        },
        gifDialogCloseBefore(done) {
            if (this.captureVideoing) {
                this.$confirm('正在生成GIF，是否确认要终止生成并关闭当前弹窗？','提示').then(() => {
                    done()
                })
            } else {
                done()
            }
        },
        async captureFrameRange() {
            if (!this.isValidTimeRange) {
                this.$message.warning('请先设置有效的时间范围')
                return
            }

            const startTime = this.parseTimeString(this.startTime)
            const endTime = this.parseTimeString(this.endTime)
            const video = this.$refs.videoPlayer

            // 清空之前的帧
            this.selectedFrames = []

            // 设置视频到开始时间
            video.currentTime = startTime
            this.captureVideoing = true
            // 等待视频seek完成
            await new Promise(resolve => {
                const onSeeked = () => {
                    video.removeEventListener('seeked', onSeeked)
                    resolve()
                }
                video.addEventListener('seeked', onSeeked)
            })

            // 在时间范围内捕获帧
            for (let time = startTime; time <= endTime; time += this.frameInterval) {
                if (!this.gifDialogVisible) {
                    this.selectedFrames = []
                    this.captureVideoing = false
                    return
                }
                video.currentTime = time
                await new Promise(resolve => {
                    const onSeeked = () => {
                        video.removeEventListener('seeked', onSeeked)
                        this.captureFrame(time)
                        resolve()
                    }
                    video.addEventListener('seeked', onSeeked)
                })
            }
            
            this.generateGif()
            // this.$message.success(`已捕获 ${this.selectedFrames.length} 帧`)
        },
        captureFrame(time) {
            const video = this.$refs.videoPlayer
            const canvas = document.createElement('canvas')
            canvas.width = this.gifWidth
            canvas.height = this.gifHeight
            const ctx = canvas.getContext('2d')

            const videoRatio = video.videoWidth / video.videoHeight
            const targetRatio = this.gifWidth / this.gifHeight

            let sx = 0
            let sy = 0
            let sWidth = video.videoWidth
            let sHeight = video.videoHeight

            if (videoRatio > targetRatio) {
                sWidth = video.videoHeight * targetRatio
                sx = (video.videoWidth - sWidth) / 2
            } else {
                sHeight = video.videoWidth / targetRatio
                sy = (video.videoHeight - sHeight) / 2
            }

            ctx.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, this.gifWidth, this.gifHeight)
            const frameDataUrl = canvas.toDataURL('image/png')

            this.selectedFrames.push({
                time: time,
                dataUrl: frameDataUrl
            })
        },
        onTimeUpdate() {
            this.currentTime = this.$refs.videoPlayer.currentTime
            this.sliderTime = this.currentTime
        },
        onRangeSliderChange(value) {
            if ((value[0] === value[1] && value[0] === 0) && (this.startTime === '' || this.endTime === '')) return
            this.startTime = this.formatTime(value[0])
            this.endTime = this.formatTime(value[1])
            this.validateTimeChange()
            // 更新视频播放进度到新的开始时间
            this.$refs.videoPlayer.currentTime = value[0]
        },
        formatTime(time) {
            const minutes = Math.floor(time / 60)
            const seconds = Math.floor(time % 60)
            const milliseconds = Math.floor((time % 1) * 1000)
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`
        },
        async captureCurrentFrame() {
            const video = this.$refs.videoPlayer
            const canvas = document.createElement('canvas')
            canvas.width = this.gifWidth
            canvas.height = this.gifHeight
            const ctx = canvas.getContext('2d')

            // 计算缩放和裁剪参数以保持宽高比
            const videoRatio = video.videoWidth / video.videoHeight
            const targetRatio = this.gifWidth / this.gifHeight

            let sx = 0
            let sy = 0
            let sWidth = video.videoWidth
            let sHeight = video.videoHeight

            if (videoRatio > targetRatio) {
                // 视频更宽，需要裁剪两边
                sWidth = video.videoHeight * targetRatio
                sx = (video.videoWidth - sWidth) / 2
            } else {
                // 视频更高，需要裁剪上下
                sHeight = video.videoWidth / targetRatio
                sy = (video.videoHeight - sHeight) / 2
            }

            ctx.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, this.gifWidth, this.gifHeight)
            const frameDataUrl = canvas.toDataURL('image/png')

            this.selectedFrames.push({
                time: this.currentTime,
                dataUrl: frameDataUrl
            })

            this.$message.success('已添加当前帧')
        },
        async generateGif() {
            if (this.selectedFrames.length < 2) {
                this.$message.warning('请至少选择2帧图片')
                return
            }

            const loading = this.$loading({
                lock: true,
                text: '正在生成GIF...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            try {
                const gif = new GIF({
                    workers: 4,
                    quality: 10,
                    width: this.gifWidth,
                    height: this.gifHeight,
                    workerScript: '/gif.worker.js'
                })
                
                // 等待所有图片加载完成后再添加到GIF中
                const loadImages = this.selectedFrames.map(frame => {
                    return new Promise((resolve) => {
                        const img = new Image()
                        img.onload = () => resolve(img)
                        img.src = frame.dataUrl
                    })
                })
                
                const images = await Promise.all(loadImages)
                images.forEach((img, index) => {
                    gif.addFrame(img, { delay: this.frameDelay })
                    loading.text = `正在处理第 ${index + 1}/${images.length} 帧...`
                })
                
                return new Promise((resolve, reject) => {
                    gif.on('finished', blob => {
                        if (this.previewGifUrl) {
                            URL.revokeObjectURL(this.previewGifUrl)
                        }
                        console.log(blob,'blob')
                        this.previewGifUrl = URL.createObjectURL(blob)
                        this.gifFile = new File([blob], 'video.gif', {type: blob.type})
                        console.log(this.gifFile,'this.gifFile')
                        this.gifFileSize = blob.size
                        loading.close()
                        this.captureVideoing = false
                        this.$message.success('GIF生成成功')
                        resolve()
                    })

                    gif.on('error', err => {
                        loading.close()
                        this.captureVideoing = false
                        this.$message.error('GIF生成失败：' + err.message)
                        reject(err)
                    })

                    gif.render()
                })
            } catch (error) {
                loading.close()
                this.captureVideoing = false
                this.$message.error('GIF生成失败：' + error.message)
            }
        },
        clearFrames() {
            this.selectedFrames = []
            if (this.previewGifUrl) {
                URL.revokeObjectURL(this.previewGifUrl)
                this.previewGifUrl = null
            }
            this.$message.success('已清空所有帧')
        },
        removeFrame(index) {
            this.selectedFrames.splice(index, 1)
        },
        updateFrameDelay(value) {
            this.frameDelay = value
        },
        setTimeRange(seconds) {
            let currentTime = this.$refs.videoPlayer.currentTime
            // console.log(currentTime,'currentTime')
            if (currentTime >= this.videoDuration) currentTime = 0
            const endTime = Math.min(currentTime + seconds, this.videoDuration)
            this.timeRange = [currentTime, endTime]
            this.startTime = this.formatTime(currentTime)
            this.endTime = this.formatTime(endTime)
            this.validateTimeChange()
        },
        uploadGif() {
            if (this.gifFile === null) return;
            this.gifUploading = true

            const formData = new FormData()
            formData.append('file', this.gifFile)
            formData.append('code', '7001')
            formData.append('index', 0)
            fileUpload(formData).then(path => {
                this.newGifCover = path
                this.gifDialogVisible = false
                this.gifFile = null
                this.initGif()
            }).finally(() => {
                this.gifUploading = false
            })
        },
        initGif() {
            this.videoUrl = ''
            this.selectedFrames = []
            this.currentTime = 0
            this.sliderTime = 0
            this.captureVideoing = false
            this.previewGifUrl = null
            this.gifFileSize = null
        },
        formatFileSize(bytes, decimalPlaces = 2) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPlaces)) + ' ' + sizes[i];
        }
    },
    mounted() {
        this.getVerifyList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>

<style scoped>
.link-btn {
    cursor: pointer;
    user-select: none;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.images-item {
    width: 60px;
    height: 60px;
    margin: 2px;
    position: relative;
}

.images-item .cover-icon {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 10px;
    color: #fff;
    background-color: #ff5858;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

.video-card {
    margin-bottom: 20px;
}

.video-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
}

.video-player {
    min-width: 0;
    max-width: 80%;
    max-height: 400px;
}

.video-gif {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.controls {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.time-range-selector {
    margin: 20px 0;
    padding: 0 20px;
}

.time-slider {
    margin: 0 20px 15px;
}

.quick-time-buttons {
    display: flex;
    gap: 5px;
    margin-left: 10px;
}

.time-inputs {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.time-input {
    width: 120px;
}

.frame-interval {
    display: flex;
    align-items: center;
    gap: 10px;
}

.frames-card {
    margin-top: 20px;
    text-align: center;
}

.frames-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.frames-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 10px;
}

.frames-header h3 {
    margin: 0;
}

.frames-settings {
    display: flex;
    align-items: center;
    gap: 10px;
}

.delay-label {
    color: #606266;
    font-size: 14px;
}

.frame-item {
    position: relative;
    width: 167px;
}

.frame-item img {
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-radius: 4px;
}

.frame-actions {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    gap: 5px;
}

.frame-time {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
}

.preview-card {
    margin-top: 20px;
    text-align: center;
}

.preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.gif-preview {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}

.refuse-item {
    margin-top: 20px;
}

.refuse-item .title-row {
    display: flex;
    gap: 30px;
    margin-bottom: 10px;
}
</style>