<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="id" label="动态ID" min-width="60" align="center">
                <template slot-scope="scope">
                    <a :href="'/article/' + scope.row.id" target="_blank">{{ scope.row.id }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="nickName" label="发布人" min-width="60" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="发布人ID" min-width="60" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="gender" label="性别" width="50" align="center">
                <template slot-scope="scope">
                    {{ {1: '男', 2: '女'}[scope.row.gender] }}
                </template>
            </el-table-column>
            <el-table-column prop="age" label="年龄" min-width="50" align="center"></el-table-column>
            <el-table-column prop="personalityName" label="社交人格" min-width="80" align="center"></el-table-column>
            <el-table-column prop="content" label="动态内容" min-width="150" align="center"></el-table-column>
            <el-table-column prop="images" label="动态图片" min-width="170" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.images">
                        <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images"
                            :key="index" :src="item" :preview-src-list="scope.row.images">
                        </el-image>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="video" label="动态视频" width="180" align="center">
                <template slot-scope="scope">
                    <video v-if="scope.row.video" muted class="video" :src="scope.row.video" type="video/mp4"
                        :poster="scope.row.images ? scope.row.images[0] : ''" autoplay="autoplay"
                        style="width: 150px; height: 150px; padding-top: 10px;" controls="controls" loop="-1">
                        <p>你的浏览器不支持video标签.</p>
                    </video>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="80" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="180" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="agreeClick(scope.row.id, scope.row)">同意
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="refuseClick(scope.row.id)">拒绝
                    </el-button>
                </template>
            </el-table-column>

        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <delete-dialog ref="deleteDialog" title="拒绝" :type="2" @confirm="deleteConfirm"></delete-dialog>
    </div>
</template>

<script>
import {
    articlePage,
    articleCheck
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
import deleteDialog from '@/components/deleteDialog'
export default {
    components: {
        deleteDialog
    },
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: "",
                userNumber: "",
            },
            formLabelWidth: "80px",
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getArticleList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getArticleList();
        },
        //获取待审核动态分页列表
        getArticleList() {
            articlePage({
                current: this.page,
                size: this.size,
                status: 0,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                    for (let i = 0; i < this.dataList.length; i++) {
                        let article = this.dataList[i];
                        if (article.image != null && article.image.length > 0) {
                            article.images = article.image.split(",")
                            for (let j = 0; j < article.images.length; j++) {
                                article.images[j] = "https://file.oiki.cc/" + article.images[j]
                            }
                        }
                        if (article.video != null && article.video.length > 0) {
                            article.video = "https://file.oiki.cc/" + article.video
                        }
                    }
                    console.log(this.dataList, 'getArticleList')
                })
        },
        agreeClick(id,row) {
            console.log(id, row, this.dataList, 'agreeClick')
            articleCheck({
                "id": id,
                "status": 1
            }).then(response => {
                this.getArticleList();
            })
        },
        refuseClick(id) {
            /* this.$prompt('请输入拒绝理由', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false
            }).then(({ value }) => {
                articleCheck({
                    "id": id,
                    "status": 2,
                    remark: value
                }).then(response => {
                    this.getArticleList();
                })
            }).catch(() => { }); */
            this.$refs.deleteDialog.setId(id)
            this.$refs.deleteDialog.showDialog()
        },
        deleteConfirm(data) {
            articleCheck({
                "id": data.id,
                "status": 2,
                remark: data.content
            }).then(response => {
                this.getArticleList();
            })
        }
    },
    mounted() {
        this.getArticleList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>