<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID"></el-input>
                </el-form-item>
                <el-form-item label="被评论人ID" v-if="type != 0">
                    <el-input v-model="query.passiveUserNumber" placeholder="请输入被评论人ID"></el-input>
                </el-form-item>
                <el-form-item label="性别" v-if="type == 0">
                    <el-select v-model="query.gender" placeholder="请选择性别">
                        <el-option label="男" :value="1"></el-option>
                        <el-option label="女" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核状态">
                    <el-select v-model="query.status" placeholder="请选择审核状态">
                        <el-option label="待审核" :value="0"></el-option>
						<el-option label="成功" :value="3"></el-option>
						<el-option label="拒绝" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table id="mainTable" ref="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column type="selection" min-width="80" align="center" key="selection" v-if="type != 0"></el-table-column>

            <el-table-column prop="nickName" label="用户昵称" min-width="80" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="用户ID" min-width="50" align="center"></el-table-column>
            <el-table-column prop="createTime" label="发布时间" min-width="80" align="center"></el-table-column>

            <template v-if="type == 0">
                <el-table-column label="性别" min-width="40" align="center" key="gender">
                    <template slot-scope="scope">
                        {{ gender[scope.row.gender] }}
                    </template>
                </el-table-column>
                
                <el-table-column label="实名照片" min-width="90" align="center" key="faceImage">
                    <template slot-scope="scope">
                        <el-image
                        style="width: 100px; height: 100px; padding-top: 5px;"
                        :src="fileDomain + scope.row.faceImage" 
                        :preview-src-list="[fileDomain + scope.row.faceImage]"
                        >
                        </el-image>
                    </template>
                </el-table-column>
                
                <el-table-column label="实名视频" min-width="110" align="center" key="faceVideo">
                    <template slot-scope="scope">
                        <video v-if="scope.row.faceVideo" muted class="video" :src="fileDomain + scope.row.faceVideo" type="video/mp4" :poster="fileDomain + scope.row.faceImage" style="width: 120px; height: 120px; padding-top: 10px;" controls="controls" loop="-1">
                            <p>你的浏览器不支持video标签.</p>
                        </video>
                    </template>
                </el-table-column>
                
                <el-table-column label="动态内容" min-width="180" align="center" key="articleContent">
                    <template slot-scope="scope">
                        <div class="content-box">
                            <div class="content-row" v-if="scope.row.content">
                                <div class="content">{{ scope.row.content.slice(0, 80) }}</div>
                                <el-popover
                                    width="500"
                                    trigger="click"
                                    placement="left"
                                    :content="scope.row.content"
                                    v-if="scope.row.content.length > 80"
                                >
                                    <div class="view-more" slot="reference">查看完整内容</div>
                                </el-popover>
                            </div>
                            <div class="video" v-if="scope.row.video">
                                <video muted class="video" :src="scope.row.video" type="video/mp4" :poster="scope.row.image" style="width: 120px; height: 120px; padding-top: 10px;" controls="controls" loop="-1">
                                    <p>你的浏览器不支持video标签.</p>
                                </video>
                            </div>
                            <div class="imgs" v-else-if="scope.row.images">
                                <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images" :key="index" :src="item"
                                    :preview-src-list="scope.row.images" >
                                </el-image>
                            </div>
                        </div>
                    </template>
                </el-table-column>

            </template>

            <template v-if="type != 0">
                <el-table-column label="评论内容" min-width="120" align="center" key="content">
                    <template slot-scope="scope">
                        <div style="color:#F56C6C;">{{ scope.row.content }}</div>
                    </template>
                </el-table-column>
                
                <el-table-column label="被评论内容" min-width="180" align="center" key="otherContent">
                    <template slot-scope="scope">
                        <div class="content-box">
                            <div class="content-row">
                                <div class="content">{{ scope.row[type == 1 ? 'articleContent' : 'commentContent']?.slice(0, 80) }}</div>
                                <el-popover
                                    width="500"
                                    trigger="click"
                                    placement="left"
                                    :content="scope.row[type == 1 ? 'articleContent' : 'commentContent']"
                                    v-if="scope.row[type == 1 ? 'articleContent' : 'commentContent']?.length > 80"
                                >
                                    <div class="view-more" slot="reference">查看完整内容</div>
                                </el-popover>
                            </div>
                            <div class="video" v-if="scope.row.video">
                                <video muted class="video" :src="scope.row.video" type="video/mp4" :poster="scope.row.faceImage" style="width: 120px; height: 120px; padding-top: 10px;" controls="controls" loop="-1">
                                    <p>你的浏览器不支持video标签.</p>
                                </video>
                            </div>
                            <div class="imgs" v-else-if="scope.row.images">
                                <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images" :key="index" :src="item"
                                    :preview-src-list="scope.row.images" >
                                </el-image>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="被评论人" min-width="100" align="center" key="otherUser">
                    <template slot-scope="scope">
                        <span style="color: #409EFF">{{ scope.row[type == 1 ? 'articleNickName' : 'commentNickName'] }}</span>
                        <span style="margin-left: 20px;white-space: nowrap;">{{ scope.row[type == 1 ? 'articleUserNumber' : 'commentUserNumber'] }}</span>
                    </template>
                </el-table-column>
            </template>
            
            <el-table-column prop="status" label="审核状态" min-width="80" align="center">
				<template slot-scope="scope">
                    <span v-if="scope.row.status=== 0" style="color: #E6A23C">待审核</span>
                    <span v-else-if="scope.row.status=== 3" style="color: #67C23A">成功</span>
					<span v-else-if="scope.row.status=== 2" style="color: #F56C6C">拒绝</span>
				</template>
			</el-table-column>
            <el-table-column prop="money" label="奖励金" min-width="40" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" min-width="70" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="singleCheck(scope.row)" v-if="scope.row.status === 0">审核</el-button>
                </template>
            </el-table-column>

        </el-table>
        <div class="page-row">
            <div class="left">
                <el-button type="primary" size="medium" @click="batchCheck" v-if="type != 0">批量审核</el-button>
            </div>
            <div class="right">
                <el-pagination background :hide-on-single-page="false" layout="total, prev, pager, next, sizes"
                    :page-size="size" :total="total" :page-sizes="[10, 20, 50, 100, 200]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
                </el-pagination>
            </div>
        </div>

        <el-dialog title="选择奖励金额" width="400px" :visible.sync="reviewVisible" :close-on-click-modal="false">
            <div style="text-align: left;">
                <el-radio-group v-model="reviewForm.id" class="rule-group">
                    <div class="rule-item" :class="{zero: item.money == 0}" v-for="item in ruleList">
                        <el-radio :label="item.id">
                            {{ item.money }}元
                            &nbsp;&nbsp;&nbsp;
                            {{ item.standard }}
                        </el-radio>
                    </div>
                </el-radio-group>
                <el-input v-model="reviewForm.remark" placeholder="自定义的审核原因 不填时则使用默认内容"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
				<el-button @click="reviewVisible = false">取 消</el-button>
				<el-button type="primary" :disabled="!reviewForm.id" @click="submitCheck">确 定</el-button>
			</span>
        </el-dialog>
    </div>
</template>

<script>
import {
    dict,
    articleActivityPage,
    commentActivityPage,
    replyActivityPage,
    getActivityRuleById,
    activityRuleCheck
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'

export default {
    data() {
        return {
            fileDomain: 'https://file.oiki.cc/',

            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                status: 0
            },
            gender: {
                1: '男',
                2: '女'
            },
            type: undefined,
            reviewVisible: false,
            reviewForm: {
                id: '',
                remark: '',
                businessIds: []
            },
            ruleList: []
        }
    },
    watch: {
        '$route': function(to, from) {
            this.init()
        },
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        init() {
            const { params } = this.$route

            if (params.hasOwnProperty('type')) {
                if (!isNaN(parseInt(params.type))) {
                    this.type = parseInt(params.type)
                } else {
                    this.type = 0
                }

                // this.query = {}
                this.page = 1
                this.size == this.type == 0 ? 10 : 20

                this.total = 0
                this.dataList = []

                this.getList()
            }
        },
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        handleSizeChange(size) {
            this.size = size
            this.getList()
        },
         //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getList();
        },
        search() {
            this.page = 1
            this.getList()
        },
        refresh() {
            this.page = 1
            this.query = {}
            this.getList()
        },
        //分页获取列表
        getList() {
            
            const getListFn = [articleActivityPage, commentActivityPage, replyActivityPage][this.type]
            console.log(getListFn, this.type,'getListFn')

            const queryData = {...this.query}
            if (this.type == 0) {
                delete queryData.passiveUserNumber
            } else {
                delete queryData.gender
            }

            const query = {
                current: this.page,
                size: this.size,
                ...queryData
            }


            getListFn(query)
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records.map(item => {
                        const imgField = this.type == 0 ? 'image' : 'articleImage'
                        if (item[imgField] != null && item[imgField].length > 0) {
                            const images = item[imgField].split(',')
                            item.images = []
                            for (const img of images) {
                                item.images.push(this.fileDomain + img)
                            }
                        }
                        
                        const videoField = this.type == 0 ? 'video' : 'articleVideo'
                        if (item[videoField] != null && item[videoField].length > 0) {
                            item[videoField] = this.fileDomain + item[videoField]
                        }

                        return item;
                    })
                })
        },
        // 获取活动列表
        getActivityList() {
            dict({parentCode: 'ACTIVITY_CODE'}).then(res => {
                this.activityList = res.records || []
            })
        },
        // 单条审核
        singleCheck(row) {
            // console.log(row,'row')
            this.reviewForm.businessIds = [row.id]
            this.openCheck(row.id)
        },
        // 批量审核
        batchCheck() {
            const ids = this.$refs.mainTable.selection.map(row => row.id)
            if (!ids || ids.length == 0) {
                this.$message.error('请勾选要批量操作的数据')
                return;
            }

            this.reviewForm.businessIds = ids
            this.openCheck()
        },
        // 获取审核标准并打开弹窗
        async openCheck(id) {
            this.reviewVisible = true
            this.reviewLoading = true
            this.reviewForm.id = ''
            this.reviewForm.remark = ''
            await getActivityRuleById({businessId: id, type: this.type}).then(res => {
                // console.log(res,'res')
                this.ruleList = res
                this.ruleList.sort((a,b) => b.money - a.money)
            })
            this.reviewLoading = false
        },
        //提交审核
        submitCheck() {
            console.log(this.reviewForm,'reviewForm')
            const formData = {...this.reviewForm}
            if (formData.remark == '') delete formData.remark
            activityRuleCheck(formData).then(res => {
                this.reviewVisible = false
                this.getList();
            })
        },
    },
    mounted() {
        this.init()

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>

<style scoped>
    /* .content-box {
        display: flex;
        align-items: center;
    }

    .content-box .content-row {
        display: flex;   
    }

    .content-box .content {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    } */

    .content-box .view-more {
        width: fit-content;
        color: #409EFF;
        margin: auto;
        cursor: pointer;
        user-select: none;
    }

    .content-box .video {
        margin: auto;
        flex-grow: 1;
    }

    .content-box .imgs {
        flex-grow: 1;
    }

    .content-box .content ~ .video , .content-box .content ~ .imgs {
        margin-left: 10px;
    }

    .rule-group {
        width: 100%;
    }

    .rule-item {
        margin-bottom: 10px;

        &.zero {
            margin-top: 30px;
            .el-radio {
                background-color: rgba(245,108,108,.2);

                &.is-checked {
                    background-color: rgba(245,108,108,.8);
                }
            }
        }

        .el-radio {
            width: 100%;
            background-color: #f8f8f8;
            padding: 10px 20px;
            border-radius: 10px;

            &.is-checked {
                background-color: #e8e8e8;
            }
        }
    }

    .video {
        width: 120px;
        height: 120px;
        margin-top: 10px;
    }

    .page-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }

    .page-row .right {
        text-align: right;
    }
</style>