<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
                <!-- <el-form-item label="用户编号">
                    <el-input v-model="query.userNumber" placeholder="请输入用户编号"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item> -->
                <el-form-item>
                    <!-- <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button> -->
                    <el-button type="primary" icon="el-icon-plus" @click="openCategory()">添加</el-button>

                    <span style="margin-left: 10px;">达人分类是 app连麦页列表顶部那个分类 排序是按数值从大到小</span>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="name" label="分类名称" min-width="100" align="center"></el-table-column>
            <el-table-column prop="sort" label="排序" min-width="100" align="center"></el-table-column>
            <el-table-column prop="remark" label="备注" min-width="200" align="center"></el-table-column>
            
            <el-table-column prop="createTime" label="创建时间" min-width="140" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" min-width="140" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="openCategory(scope.row)">编辑
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog width="600px" title="" :visible.sync="labelFormVisible" :close-on-click-modal="false" style="text-align: left;">
            <el-form ref="labelForm" :model="labelForm" label-width="80px" :rules="labelFormRules">
                <el-form-item prop="name" label="分类名称">
                    <el-input v-model="labelForm.name" maxlength="8" placeholder="请输入分类内容(不超过8个字)"></el-input>
                </el-form-item>
                <el-form-item prop="sort" label="排序">
                    <el-input v-model="labelForm.sort" maxlength="8"></el-input>
                </el-form-item>
                <el-form-item prop="remark" label="备注">
                    <el-input v-model="labelForm.remark" type="textarea" :rows="4"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="labelFormVisible = false">取 消</el-button>
				<el-button type="primary" :loading="labelFormLoading" @click="labelConfirm">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import setTableHeight from '@/utils/setTableHeight'
import { talentCategoryPage,
talentCategoryPost,
talentCategoryUpdate,
talentCategoryDelete } from '@/api/api'
export default {
    data() {
        return {
            fileDomain: 'https://file.oiki.cc/',
            page: 1,
            size: 10,
            total: 0,
            query: {
                userNumber: '',
                nickName: '',
                status: '',
            },
            tableHeight: null,
            dataList: [{}],

            statusAry: ['新增','已采纳','已忽略'],
            statusColors: ['warning','success','danger'],

            labelForm: {},
            labelFormRules: {
                content: [
                    { required: true, message: '请输入分类内容' }
                ],
                type: [
                    { required: true, message: '请选择分类类型' }
                ],
            },
            labelFormVisible: false,
            labelFormLoading: false,
        }
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getCategoryList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getCategoryList();
        },
        getCategoryList() {
            talentCategoryPage({
                current: this.page,
                size: this.size,
                ...this.query
            }).then(({total, records}) => {
                this.total = total;
                this.dataList = records.map(item => {
                    const files = item.content?.split(',')
                    if (files) {
                        item.files = files
                    }

                    return item
                });
            })
        },
        openCategory(item) {
            const formFields = ['id','name','sort','remark']
            for (let field of formFields) {
                const value = item ? item[field] : ''
                this.$set(this.labelForm, field, value)
            }
            this.labelFormVisible = true
            this.$nextTick(() => {
                this.$refs.labelForm.clearValidate()
            })
        },
        async labelConfirm() {
            await new Promise((resolve) => {
                this.$refs.labelForm.validate(valid => valid && resolve())
            })
            
            const formData = {...this.labelForm}
            this.labelFormLoading = true
            const send = formData.id ? talentCategoryUpdate : talentCategoryPost
            send(formData).then(() => {
                this.labelFormVisible = false
                this.labelFormLoading = false
                this.getCategoryList()
            }).catch(() => {
                this.labelFormLoading = false
            })
        },
        deleteClick(id) {
			this.$confirm('确定要删除此分类?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
                closeOnClickModal: false
			}).then(() => {
				const params = new URLSearchParams()
			    params.append("idList", [id])
				talentCategoryDelete({params}).then(() => {
					this.getCategoryList()
				})
			})
		}
    },
    mounted() {
        this.getCategoryList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>

<style scoped>

</style>