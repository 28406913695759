<template>
    <div class="statistics">
        <div class="module-box data-overview">
            <div class="head flex flex-items-end justify-between">
                <div class="module-title">数据总览</div>
                <div class="refresh" @click="getStatisticsDatas"><i class="el-icon-refresh"></i>刷新</div>
            </div>
            <div class="overview-box flex">
                <div class="item" v-for="item in overviewList" :key="item.name">
                    <div class="icon" :class="[item.name]"></div>
                    <div class="info w-full text-left">
                        <div class="label">
                            {{ item.label }}
                            <el-tooltip placement="bottom" :open-delay="1000" :content="item.description" v-if="item.description">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                        <div class="number">{{ item.data }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="module-box flex">
            <div class="flex flex-col">
                <div class="new-data-added module-card">
                    <div class="module-title">新增数据</div>
                    <div class="new-data-added-list flex justify-between flex-wrap">
                        <div class="item" v-for="(item,index) in newDataAddedList" :key="index">
                            <div class="data-head flex flex-items-center justify-between">
                                <div class="flex flex-items-center">
                                    <div class="label">
                                        {{ item.label }}
                                        <el-tooltip placement="bottom" :open-delay="1000" :content="item.description" v-if="item.description">
                                            <i class="el-icon-question"></i>
                                        </el-tooltip>
                                    </div>
                                    <div class="comparative" v-if="item.comparative">
                                        <span class="value" :class="[item.trend]">{{ item.comparative }}{{ item.suffix }}</span>
                                        昨日较前日
                                    </div>
                                </div>
                                <div>
                                    <a href="javascript:;" v-if="item.hasMore" @click="showMoreData(item.moreMethod)">查看详情</a>
                                </div>
                            </div>
                            <div class="info flex flex-items-center text-left">
                                <div class="yesterday-label"></div>
                                <div class="number data1">{{ item.data1 }}{{ item.suffix }}</div>
                                <div class="today-label"></div>
                                <div class="number data2">{{ item.data2 }}{{ item.suffix }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="coin-module module-card">
                <div class="module-title">新增数据</div>
                <div class="total-coin flex flex-items-center">
                    <div class="label">
                        用户k币总额
                        <el-tooltip placement="bottom" :open-delay="1000" content="截止到页面刷新时间 当前所有用户 K币余额总和">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                    </div>
                    <div class="value">{{ formatMoney(totalCoinNumber) }}</div>
                </div>

                <div class="row flex" v-for="(row,index) in coinList" :key="index">

                    <div class="item total-item">
                        <div class="label">
                            {{ row.label }}
                            <el-tooltip placement="bottom" :open-delay="1000" :content="row.description" v-if="row.description">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                        <div class="number">{{ row.data }}</div>
                    </div>
                    
                    <div class="coin-list flex flex-wrap justify-between" v-if="row.list">
                        <div class="item" v-for="item in row.list" :key="item.label">
                            <div class="label">
                                {{ item.label }}
                                <el-tooltip placement="bottom" :open-delay="1000" :content="item.description" v-if="item.description">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </div>
                            <div class="number">{{ item.data }}</div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="module-box">
            <div class="other-box flex module-card">
                <div class="item">
                    <div class="number color-1">{{ activeNumber }}</div>
                    <div class="label">前7日平均日活</div>
                </div>
                <div class="item">
                    <div class="number color-2">{{ pullWireNumber }}</div>
                    <div class="label">征友中 (条)</div>
                </div>
                <div class="item">
                    <div class="number color-4">{{ soberNumber }}</div>
                    <div class="label">注销中</div>
                </div>
                <div class="item">
                    <div class="number color-3">{{ logOffNumber }}</div>
                    <div class="label">已注销</div>
                </div>
            </div>
        </div>

        <div class="module-box flex">
            <!-- <div class="user-box module-card">
                <div class="module-title">用户管理</div>
                <div class="user-data">
                    <div class="data-head flex flex-items-center">
                        <div class="label">
                            {{ userData.totalData.label }}
                            <el-tooltip placement="bottom" :open-delay="1000" :content="userData.totalData.description" v-if="userData.totalData.description">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                        <div class="comparative" v-if="userData.totalData.comparative">
                            <span class="value" :class="[userData.totalData.trend]">{{ userData.totalData.comparative }}</span>
                            昨日较前日
                        </div>
                    </div>
                    <div class="list">
                        <div class="item flex flex-items-center" v-for="item in userData.dataList" :key="item.name">
                            <div :class="item.name + '-label'"></div>
                            <div class="w-full flex flex-items-center justify-between">
                                <div class="data1 content">男性<span>{{ item.data1 }}</span>人</div>
                                <div class="data2 content">女性<span>{{ item.data2 }}</span>人</div>
                            </div>
                        </div>
                    </div>
                    <div class="gender flex flex-items-center justify-between">
                        <div class="item flex flex-items-center" v-for="item in userData.genderList" :key="item.label">
                            {{ item.label }}
                            <div class="comparative">
                                <span class="value" :class="[item.trend]">{{ item.comparative }}</span>
                                昨日较前日
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="article-box common-box module-card">
                <div class="item" v-for="item in vipList" :key="item.label">
                    <div class="data-head flex flex-items-center">
                        <div class="label">
                            {{ item.label }}
                            <el-tooltip placement="bottom" :open-delay="1000" :content="item.description" v-if="item.description">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                        <div class="comparative" v-if="item.comparative">
                            <span class="value" :class="[item.trend]">{{ item.comparative }}</span>
                            昨日较前日
                        </div>
                    </div>
                    <div class="info flex flex-items-center justify-between">
                        <div class="data1">
                            <div class="yesterday-label"></div>
                            <div class="number">{{ item.data1 }}</div>
                        </div>
                        <div class="versus"><span>VS</span></div>
                        <div class="data2">
                            <div class="today-label"></div>
                            <div class="number">{{ item.data2 }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="article-box common-box module-card">
                <div class="item" v-for="item in articleList" :key="item.label">
                    <div class="data-head flex flex-items-center">
                        <div class="label">
                            {{ item.label }}
                            <el-tooltip placement="bottom" :open-delay="1000" :content="item.description" v-if="item.description">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                        <div class="comparative" v-if="item.comparative">
                            <span class="value" :class="[item.trend]">{{ item.comparative }}</span>
                            昨日较前日
                        </div>
                    </div>
                    <div class="info flex flex-items-center justify-between">
                        <div class="data1">
                            <div class="yesterday-label"></div>
                            <div class="number">{{ item.data1 }}</div>
                        </div>
                        <div class="versus"><span>VS</span></div>
                        <div class="data2">
                            <div class="today-label"></div>
                            <div class="number">{{ item.data2 }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="article-box common-box module-card">
                <div class="item" v-for="item in commentList" :key="item.label">
                    <div class="data-head flex flex-items-center">
                        <div class="label">
                            {{ item.label }}
                            <el-tooltip placement="bottom" :open-delay="1000" :content="item.description" v-if="item.description">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                        <div class="comparative" v-if="item.comparative">
                            <span class="value" :class="[item.trend]">{{ item.comparative }}</span>
                            昨日较前日
                        </div>
                    </div>
                    <div class="info flex flex-items-center justify-between">
                        <div class="data1">
                            <div class="yesterday-label"></div>
                            <div class="number">{{ item.data1 }}</div>
                        </div>
                        <div class="versus"><span>VS</span></div>
                        <div class="data2">
                            <div class="today-label"></div>
                            <div class="number">{{ item.data2 }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="article-box common-box module-card">
                <div class="item" v-for="item in rewardList" :key="item.label">
                    <div class="data-head flex flex-items-center">
                        <div class="label">
                            {{ item.label }}
                            <el-tooltip placement="bottom" :open-delay="1000" :content="item.description" v-if="item.description">
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </div>
                        <div class="comparative" v-if="item.comparative">
                            <span class="value" :class="[item.trend]">{{ item.comparative }}</span>
                            昨日较前日
                        </div>
                    </div>
                    <div class="info flex flex-items-center justify-between">
                        <div class="data1">
                            <div class="yesterday-label"></div>
                            <div class="number">{{ item.data1 }}</div>
                        </div>
                        <div class="versus"><span>VS</span></div>
                        <div class="data2">
                            <div class="today-label"></div>
                            <div class="number">{{ item.data2 }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Decimal from 'decimal.js';
import { statisticsPage } from '@/api/api'
export default {
    data() {
        return {
            // 总览数据
            userNumber: 0,                  // 总注册量
            totalRealNumber: 0,             // 实名认证总数
            totalVipNumber: 0,              // 当前贵族总数
            // totalGoldMasterNumber: 0,       // 富豪认证总数
            totalRechargeMoney: 0,          // 总充值
            totalTakeMoney: 0,              // 总提现


            // 新增数据
            twoDayRegisterNumber: 0,        // 前日新增注册用户 (人)
            yesterdayRegisterNumber: 0,     // 昨日新增注册用户 (人)
            todayRegisterNumber: 0,         // 今日新增注册用户 (人)

            twoDayRealNameNumber: 0,        // 前日新增实名认证用户 (人)
            yesterdayRealNameNumber: 0,     // 昨日新增实名认证用户 (人)
            todayRealNameNumber: 0,         // 今日新增实名认证用户 (人)

            twoDayPullWireUserNumber: 0,    // 前日新增征友发布者 (人)
            yesterdayPullWireUserNumber: 0, // 昨日新增征友发布者 (人)
            todayPullWireUserNumber: 0,     // 今日新增征友发布者 (人)

            twoDayAddFriendNumber: 0,       // 前日新增好友申请
            yesterdayAddFriendNumber: 0,    // 昨日新增好友申请
            todayAddFriendNumber: 0,        // 今日新增好友申请

            twoDayCommentNumber: 0,         // 前日新增评论
            yesterdayCommentNumber: 0,      // 昨日新增评论
            todayCommentNumber: 0,          // 今日新增评论

            twoDayRechargeMoney: 0,         // 前日充值 (元)
            yesterdayRechargeMoney: 0,      // 昨日充值 (元)
            todayRechargeMoney: 0,          // 今日充值 (元)
            

            // K币数据
            totalCoinNumber: 0,             // 用户k币总额
            
            useRewardCoinNumber: 0,         // 打赏消耗k币

            lockFriendCoinNumber: 0,        // 好友申请锁定k币
            useFriendCoinNumber: 0,         // 好友申请消耗k币
            backFriendCoinNumber: 0,         // 好友申请退还k币
            
            usePullWireCoinNumber: 0,       // 发布征友消耗k币
            usePullWireApplyCoinNumber: 0,  // 申请征友消耗K币
            lockPullWireApplyCoinNumber: 0,  // 申请征友锁定K币
            backPullWireApplyCoinNumber: 0,  // 申请征友退还K币



            // 侧边数据
            activeNumber: 0,                // 前7日平均日活
            pullWireNumber: 0,              // 征友中 (条)
            logOffNumber: 0,                // 已注销人数
            soberNumber: 0,                 // 注销中人数


            // 私人订制数据
            twoDayCustomManNumber: 0,       // 前日私人订制提审人数 (人)（男）
            yesterdayCustomManNumber: 0,    // 昨日私人订制提审人数 (人)（男）
            twoDayCustomWomenNumber: 0,     // 今日私人订制提审人数 (人)（男）

            yesterdayCustomWomenNumber: 0,  // 前日私人订制提审人数 (人)（女）
            todayCustomManNumber: 0,        // 昨日私人订制提审人数 (人)（女）
            todayCustomWomenNumber: 0,      // 今日私人订制提审人数 (人)（女）


            // 贵族会员数据
            twoDayVipPayNumber: 0,         // 前日贵族会员支付数 (人)
            yesterdayVipPayNumber: 0,      // 昨日贵族会员支付数 (人)
            todayVipPayNumber: 0,          // 今日贵族会员支付数 (人)

            twoDayVipPayMoney: 0,          // 前日贵族支付金额 (元)
            yesterdayVipPayMoney: 0,       // 昨日贵族支付金额 (元)
            todayVipPayMoney: 0,           // 今日贵族支付金额 (元)


            // 动态数据
            twoDayArticleNumber: 0,         // 前日动态发布数 (条)
            yesterdayArticleNumber: 0,      // 昨日动态发布数 (条)
            todayArticleNumber: 0,          // 今日动态发布数 (条)

            twoDayArticleUserNumber: 0,     // 前日动态发布者 (人)
            yesterdayArticleUserNumber: 0,  // 昨日动态发布者 (人)
            todayArticleUserNumber: 0,      // 今日动态发布者 (人)


            // 评论和征友数据
            twoDayCommentUserNumber: 0,     // 前日评论发布者 (人)
            yesterdayCommentUserNumber: 0,  // 昨日评论发布者 (人)
            todayCommentUserNumber: 0,      // 今日评论发布者 (人)

            twoDayApplyPullWireNumber: 0,       // 前日征友申请人数 (人)
            yesterdayApplyPullWireNumber: 0,    // 昨日征友申请人数 (人)
            todayApplyPullWireNumber: 0,        // 今日征友申请人数 (人)


            // 打赏数据
            twoDayRewardNumber: 0,          // 前日打赏人数 (人)
            yesterdayRewardNumber: 0,       // 昨日打赏人数 (人)
            todayRewardNumber: 0,           // 今日打赏人数 (人)

            twoDayRewardMoney: 0,           // 前日打赏金额 (元)
            yesterdayRewardMoney: 0,        // 昨日打赏金额 (元)
            todayRewardMoney: 0,            // 今日打赏金额 (元)

            twoDayRechargeNumber: 0,        // 前日充值人数 (人)
            yesterdayRechargeNumber: 0,     // 昨日充值人数 (人)
            todayRechargeNumber: 0,         // 今日充值人数 (人)

        }
    },
    methods: {
        /**
         * 格式化金额
         * @param {number} number 金额数值
         * @param {number} decimals 小数点位数
         * @param {string} decPoint 小数点符号
         * @param {string} thousandsSep 金额隔开符号
         */
        formatMoney(number, decimals = 0, decPoint = '.', thousandsSep = ',') {
            number = (number + '').replace(/[^0-9+-Ee.]/g, '')
            const n = !isFinite(+number) ? 0 : +number
            const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
            const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
            const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
            let s = ''
            const toFixedFix = function(n, prec) {
                const k = Math.pow(10, prec)
                return '' + Math.ceil(n * k) / k
            }
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
            const re = /(-?\d+)(\d{3})/
            while (re.test(s[0])) {
                s[0] = s[0].replace(re, '$1' + sep + '$2')
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || ''
                s[1] += new Array(prec - s[1].length + 1).join('0')
            }
            return s.join(dec)
        },
        /**
         * 对象昨日数据对比前日数据
         * @param {data1,data3} item 
         */
        comparativeData(item) {
            if (typeof item.data1 == 'number' && typeof item.data3 == 'number') {
                const { data1, data3 } = item
                if (data1 != data3) {
                    const n = 1000      // js数值计算精密度有问题，先乘1000进行数值计算后再除
                    const value = Math.abs(data3 * n - data1 * n) / n
                    console.log(data1, data3, value, item, 'comparativeData')
                    item.trend = data3 < data1 ? 'up' : 'down'
                    item.comparative = (data3 < data1 ? '+' : '-') + value
                } else {
                    item.comparative = '0'
                }
            }
            
            return item
        },
        getStatisticsDatas() {
            statisticsPage().then(res => {
                // 写入数据
                Object.keys(res).forEach(key => {
                    const value = res[key]
                    if (this.hasOwnProperty(key)) {
                        this[key] = value
                    } else {
                        // console.log(this,key)
                    }
                })
            })
        },
        showMoreData(methodName) {
            if (this[methodName]) {
                this[methodName]()
            }
        },
        openRegisterData() {
            window.open('/registerData')
        }
    },
    computed: {
        overviewList() {
            const list = [
                { data: this.userNumber, name: 'userNumber', label: '总注册量', description: '截止页面刷新时间的注册用户总量' },
                { data: this.totalRealNumber, name: 'totalRealNumber', label: '实名认证总数', description: '截止到页面刷新时间 已进行身份实名认证的用户总数' },
                { data: this.totalVipNumber, name: 'totalGoldMasterNumber', label: '当前贵族总数', description: '当前还是贵族的用户总数' },
                { data: this.totalRechargeMoney, name: 'totalRechargeMoney', label: '总充值', description: '截止页面刷新时间 平台内充值总额（已支付）' },
                { data: this.totalTakeMoney, name: 'totalTakeMoney', label: '总提现', description: '截止到页面刷新时间 提现列表已同意状态下的金额总和' },
            ]

            return list;
        },
        newDataAddedList() {
            function getRatio(val1, val2) {
                if (val1 > 0 && val2 > 0) {
                    const ratio = new Decimal(val1).div(val2).times(100).toNumber()
                    return parseFloat(ratio.toFixed(2));
                }
                return 0
            }
            
            // 流水
            const yesterdayAmountMoney = this.yesterdayRechargeMoney + this.yesterdayVipPayMoney
            const todayAmountMoney = this.todayRechargeMoney + this.todayVipPayMoney
            const twoDayAmountMoney = this.twoDayRechargeMoney + this.twoDayVipPayMoney

            // 贵族开通比例
            const yesterdayVipRatio = getRatio(this.yesterdayVipPayNumber , this.yesterdayRegisterNumber)
            const todayVipRatio = getRatio(this.todayVipPayNumber , this.todayRegisterNumber)
            const twoDayVipRatio = getRatio(this.twoDayVipPayNumber , this.twoDayRegisterNumber)

            // 充值K币人数比例
            const yesterdayRechargeNumberRatio = getRatio(this.yesterdayRechargeNumber, this.yesterdayRegisterNumber)
            const todayRechargeNumberRatio = getRatio(this.todayRechargeNumber, this.todayRegisterNumber)
            const twoDayRechargeNumberRatio = getRatio(this.twoDayRechargeNumber, this.twoDayRegisterNumber)

            const list = [
                { data1: this.yesterdayRegisterNumber, data2: this.todayRegisterNumber, data3: this.twoDayRegisterNumber, label: '新增注册用户 (人)', description: '今日新增注册人数为实时数据 昨日新增与前日新增进行对比', hasMore: true, moreMethod: 'openRegisterData' },
                { data1: this.yesterdayRealNameNumber, data2: this.todayRealNameNumber, data3: this.twoDayRealNameNumber, label: '新增实名认证用户 (人)', description: '今日新增实名认证用户的人数为实时数据 昨日新增与前日新增进行对比' },
                { data1: this.yesterdayPullWireUserNumber, data2: this.todayPullWireUserNumber, data3: this.twoDayPullWireUserNumber, label: '新增征友发布者 (人)', description: '今日新增的发布过征友的用户数为实时数据 昨日新增与前日新增进行对比' },
                { data1: this.yesterdayAddFriendNumber, data2: this.todayAddFriendNumber, data3: this.twoDayAddFriendNumber, label: '新增好友申请', description: '今日新增的申请加好友的条数为实时数据 昨日新增与前日新增进行对比' },
                { data1: this.yesterdayCommentNumber, data2: this.todayCommentNumber, data3: this.twoDayCommentNumber, label: '新增评论', description: '（评论数+回复数）' },
                { data1: this.yesterdayRechargeMoney, data2: this.todayRechargeMoney, data3: this.twoDayRechargeMoney, label: '充值 (元)', description: '今日新增的充值金额为实时数据 昨日新增与前日新增进行对比' },
                { data1: yesterdayAmountMoney, data2: todayAmountMoney, data3: twoDayAmountMoney, label: '新增流水(元)', description: '今日新增的流水金额 昨日新增与前日新增进行对比' },
                { data1: this.yesterdayRechargeNumber, data2: this.todayRechargeNumber, data3: this.twoDayRechargeNumber, label: '新增K币充值人数', description: '今日新增的K币充值人数 昨日新增与前日新增进行对比' },
                { data1: yesterdayVipRatio, data2: todayVipRatio, data3: twoDayVipRatio, label: '贵族开通比例', suffix: '%', description: '今日的贵族开通比例(贵族开通人数/注册人数) 昨日新增与前日新增进行对比' },
                { data1: yesterdayRechargeNumberRatio, data2: todayRechargeNumberRatio, data3: twoDayRechargeNumberRatio, label: '充值K币人数比例', suffix: '%', description: '今日的充值K币人数比例(充值K币人数/注册人数) 昨日新增与前日新增进行对比' },
            ]
            list.forEach(item => this.comparativeData(item))

            return list;
        },
        coinList() {
            const totalFriendCoin = this.useFriendCoinNumber + this.lockFriendCoinNumber
            const totalPullWireCoin = this.usePullWireCoinNumber + this.usePullWireApplyCoinNumber + this.lockPullWireApplyCoinNumber
            const list = [
                { data: this.useRewardCoinNumber, label: '礼物消耗k币总额', description: '动态和聊天中打赏礼物所消耗的K币数量总额' },
                {
                    data: totalFriendCoin,
                    label: '交友k币总额',
                    description: '交友消耗K币与交友锁定K币的数量总和',
                    list: [
                        { data: this.useFriendCoinNumber, label: '交友消耗K币', description: '指用户在好友申请过程中支付的k币 已进入畅聊的总额' },
                        { data: this.lockFriendCoinNumber, label: '交友锁定K币', description: '指用户在好友申请过程中支付的k币 未进入畅聊的总额' },
                        { data: this.backFriendCoinNumber, label: '交友退还K币', description: '指用户在申请好友过程中 因临时聊天未进入畅聊导致的K币退还的总额' },
                    ]
                },
                {
                    data: totalPullWireCoin,
                    label: '征友k币总额',
                    description: '发布消耗K币、申请消耗K币与征友锁定K币的数量总和',
                    list: [
                        { data: this.usePullWireCoinNumber, label: '发布消耗K币', description: '指用户在发布征友过程中消耗的k币总额' },
                        { data: this.usePullWireApplyCoinNumber, label: '申请消耗K币', description: '指用户在申请征友过程中支付的k币 已进入畅聊的总额' },
                        { data: this.lockPullWireApplyCoinNumber, label: '征友锁定K币', description: '指用户在申请征友过程中支付的k币 但处于临时聊天过程中的总额' },
                        { data: this.backPullWireApplyCoinNumber, label: '征友退还K币', description: '指用户在申请征友过程中 因临时聊天未进入畅聊导致的K币退还的总额' },
                    ]
                }
            ]

            return list;
        },
        userData() {
            const totalData = {
                data1: this.yesterdayCustomManNumber + this.yesterdayCustomWomenNumber,
                data2: this.todayCustomManNumber + this.todayCustomWomenNumber,
                data3: this.twoDayCustomManNumber + this.twoDayCustomWomenNumber,
                label: '私人订制提审人数 (人)',
                description: '提交过的人数 不考虑审核状态，不考虑二次提交'
            }
            this.comparativeData(totalData)

            const dataList = [
                { data1: this.yesterdayCustomManNumber, data2: this.yesterdayCustomWomenNumber, name: 'yesterday' },
                { data1: this.todayCustomManNumber, data2: this.todayCustomWomenNumber, name: 'today' },
            ]
            const genderList = [
                { data1: this.yesterdayCustomManNumber, data2: this.todayCustomManNumber, data3: this.twoDayCustomManNumber, label: '男性' },
                { data1: this.yesterdayCustomWomenNumber, data2: this.todayCustomWomenNumber, data3: this.twoDayCustomWomenNumber, label: '女性' }
            ]
            genderList.forEach(item => this.comparativeData(item))

            return { totalData, dataList, genderList };
        },
        vipList() {
            const list = [
                { data1: this.yesterdayVipPayNumber, data2: this.todayVipPayNumber, data3: this.twoDayVipPayNumber, label: '贵族支付人数 (人)', description: '贵族支付记录中已支付的用户数去重' },
                { data1: this.yesterdayVipPayMoney, data2: this.todayVipPayMoney, data3: this.twoDayVipPayMoney, label: '贵族支付金额 (元)', description: '贵族支付记录中已支付的订单金额总和' },
            ]
            list.forEach(item => this.comparativeData(item))

            return list;
        },
        articleList() {
            const list = [
                { data1: this.yesterdayArticleNumber, data2: this.todayArticleNumber, data3: this.twoDayArticleNumber, label: '动态发布数 (条)', description: '今日新增的动态发布条数为实时数据 昨日新增与前日新增进行对比 动态发布的条数只计算审核成功的' },
                { data1: this.yesterdayArticleUserNumber, data2: this.todayArticleUserNumber, data3: this.twoDayArticleUserNumber, label: '动态发布者 (人)', description: '今日新增的发过动态的用户数为实时数据 昨日新增与前日新增进行对比' },
            ]
            list.forEach(item => this.comparativeData(item))

            return list;
        },
        commentList() {
            const list = [
                { data1: this.yesterdayCommentUserNumber, data2: this.todayCommentUserNumber, data3: this.twoDayCommentUserNumber, label: '评论发布者 (人)', description: '成功发布过评论或回复的人数' },
                { data1: this.yesterdayApplyPullWireNumber, data2: this.todayApplyPullWireNumber, data3: this.twoDayApplyPullWireNumber, label: '征友申请人数 (人)', description: '今日新增的申请征友的用户数量为实时数据 昨日新增与前日新增进行对比' },
            ]
            list.forEach(item => this.comparativeData(item))

            return list;
        },
        rewardList() {
            const list = [
                { data1: this.yesterdayRewardNumber, data2: this.todayRewardNumber, data3: this.twoDayRewardNumber, label: '打赏人数 (人)', description: '有过打赏行为的用户数（审核通过）' },
                { data1: this.yesterdayRewardMoney, data2: this.todayRewardMoney, data3: this.twoDayRewardMoney, label: '打赏金额 (元)', description: '被审核通过的打赏金额 今日新增的为实时数据 昨日新增与前日新增进行对比' },
            ]
            list.forEach(item => this.comparativeData(item))

            return list;
        }
    },
    mounted() {
        this.getStatisticsDatas()
    }
}
</script>

<style scoped>
    .statistics {
        min-width: 1560px;
        background-color: #F2F5F7;
        padding: 20px;
        margin: -20px;
    }

    .w-full {width: 100%;}
    .text-left {text-align: left;}
    .text-center {text-align: center;}
    .text-right {text-align: right;}
    .flex {display: flex;}
    .flex-col {flex-direction: column;}
    .flex-items-center {align-items: center;}
    .flex-items-end {align-items: flex-end;}
    .justify-between {justify-content: space-between;}
    .flex-wrap {flex-wrap: wrap;}

    .module-box:not(:last-child) {margin-bottom: 24px;}
    .module-card {
        background-color: #fff;
        padding: 16px 20px;
        border-radius: 12px;
        box-sizing: border-box;
    }
    .module-title {
        font-weight: bold;
        font-size: 18px;
        color: #000;
        display: flex;
        align-items: center;
    }
    .module-title::before {
        width: 8px;
        height: 20px;
        background-color: #0085FF;
        border-radius: 4px;
        margin-right: 10px;
        content: '';
    }

    .data-head .label {
        line-height: 22px;
        font-size: 16px;
        color: #333;
        text-wrap: nowrap;
        position: relative;
        z-index: 1;
    }

    .data-head .label::before {
        width: 6px;
        height: 6px;
        background-color: #0085FF;
        border-radius: 50%;
        margin: auto;
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: -12px;
        content: '';
    }

    .data-head .label i {
        font-size: 14px;
        color: #777;
        margin-left: 6px;
    }

    .comparative {
        line-height: 20px;
        font-size: 12px;
        color: #86909C;
        margin-left: 16px;
        display: flex;
        align-items: center;
    }

    .comparative .value {
        margin-right: 4px;
        display: flex;
        align-items: center;
    }
    .comparative .value::before {
        /* width: 0;
        height: 0;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        margin-right: 4px;
        content: ''; */
    }
    .comparative .value.up {color: #F53F3F;}
    /* .comparative .value.up::before {border-bottom: 4px solid #00B42A;} */
    .comparative .value.down {color: #00B42A;}
    /* .comparative .value.down::before {border-top: 4px solid #F53F3F;} */

    .yesterday-label {
        width: 52px;
        height: 32px;
        font-size: 14px;
        color: #6F25B8;
        background-color: #F1E9F8;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }
    .yesterday-label::before {content: '昨日';}

    .today-label {
        width: 52px;
        height: 32px;
        font-size: 14px;
        color: #1094A3;
        background-color: #E7F4F6;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }
    .today-label::before {content: '今日';}

    .data-overview .head {
        margin-bottom: 20px;
    }

    .refresh {
        line-height: 24px;
        font-size: 18px;
        color: #0085FF;
        background-color: #E8EFFF;
        padding: 10px 22px;
        border-radius: 20px;
        cursor: pointer;
        user-select: none;
    }

    .refresh i {margin-right: 10px;}

    .overview-box .item {
        width: 284px;
        height: 140px;
        background-color: #fff;
        padding: 29px 30px 29px 40px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
    .overview-box .item:not(:first-child) {margin-left: 24px;}

    .overview-box .item .icon {
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin-right: 30px;
        flex-shrink: 0;
    }

    .overview-box .item .icon.userNumber{background-image: url('@/assets/images/statistics-userNumber.png');}
    .overview-box .item .icon.totalRealNumber{background-image: url('@/assets/images/statistics-totalRealNumber.png');}
    .overview-box .item .icon.totalGoldMasterNumber{background-image: url('@/assets/images/statistics-totalGoldMasterNumber.png');}
    .overview-box .item .icon.totalRechargeMoney{background-image: url('@/assets/images/statistics-totalRechargeMoney.png');}
    .overview-box .item .icon.totalTakeMoney{background-image: url('@/assets/images/statistics-totalTakeMoney.png');}

    .overview-box .item .label {
        font-size: 16px;
        color: #333;
        text-wrap: nowrap;
    }

    .overview-box .item .label i {
        font-size: 14px;
        color: #777;
        margin-left: 2px;
    }
    .overview-box .item .number {
        line-height: 50px;
        font-weight: bold;
        font-size: 36px;
        color: #333;
        margin-top: 10px;
    }

    .new-data-added {width: 900px;}
    .new-data-added-list .item {
        width: 420px;
        height: 110px;
        background-color: #F8F7FC;
        padding: 16px 24px;
        border-radius: 6px;
        margin-top: 16px;
        box-sizing: border-box;
    }

    .new-data-added-list .item .info {margin-top: 16px;}
    .new-data-added-list .item .number {
        min-width: 130px;
        line-height: 42px;
        font-weight: bold;
        font-size: 30px;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .new-data-added-list .item .data2 {color: #3552FF;}

    .coin-module {
        width: 593px;
        margin-left: 24px;
        box-sizing: border-box;
    }

    .coin-module .total-coin {
        color: #fff;
        background-color: #3751FF;
        padding: 6px 13px 6px 16px;
        border-radius: 12px;
        margin-top: 14px;
    }

    .coin-module .total-coin .label {font-size: 16px;}
    .coin-module .total-coin .label i {color: rgba(255,255,255,.6);}
    .coin-module .total-coin .value {
        line-height: 36px;
        font-weight: bold;
        font-size: 30px;
        margin-left: 10px;
    }

    .coin-module .row {background-color: #F8F7FC;padding: 16px 12px;border-radius: 12px;margin-top: 20px;text-align: left;}
    .coin-module .row .total-item {
        width: 180px;
        flex-shrink: 0;
    }

    .coin-module .row .total-item .label {font-weight: bold;font-size: 16px;color: #333;}
    .coin-module .row .total-item .label i {font-size: 14px;color: #777;}
    .coin-module .row .total-item .number {
        line-height: 42px;
        font-weight: bold;
        font-size: 30px;
        color: #6F25B8;
        margin-top: 7px;
    }

    .coin-module .row .coin-list {width: 100%;}

    .coin-module .coin-list {padding: 0 10px;}
    .coin-module .coin-list .item {
        width: 50%;
        /* margin-top: 22px; */
        text-align: left;
    }

    .coin-module .coin-list .item:nth-child(2) ~ .item {
        margin-top: 12px;
    }

    .coin-module .coin-list .item .title {
        font-size: 24px;
    }

    .coin-module .coin-list .item .label {
        line-height: 22px;
        font-size: 16px;
        color: #333;
    }
    .coin-module .coin-list .item .label i {
        font-size: 14px;
        color: #777;
    }
    .coin-module .coin-list .item .number {
        line-height: 42px;
        font-weight: bold;
        font-size: 30px;
        margin-top: 10px;
    }

    .other-box {
        margin-top: 24px;
        flex-grow: 1;
    }
    .other-box .item {
        width: 100%;
        padding: 15px 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .other-box .item .title {
        font-weight: bold;
        font-size: 16px;
    }
    
    .other-box .item .number {
        line-height: 50px;
        font-weight: bold;
        font-size: 36px;
    }
    .other-box .item .label {
        line-height: 34px;
        font-weight: bold;
        font-size: 24px;
    }

    .other-box .item .box {
        padding: 0 15px;
    }

    .other-box .item.small .number {
        font-size: 28px;
    }

    .other-box .item.small .label {
        font-size: 16px;
    }

    .other-box .item .color-1 {color: #3552FF;}
    .other-box .item .color-2 {color: #67C23A;}
    .other-box .item .color-3 {color: #CC1622;}
    .other-box .item .color-4 {color: #f59a23;}

    .user-box {
        width: 388px;
        box-sizing: border-box;
    }
    .user-box .user-data {
        padding-left: 12px;
        margin-top: 20px;
    }

    .user-box .user-data .list {margin-top: 16px;}
    .user-box .user-data .list .item {
        margin-bottom: 20px;
    }
    .user-box .user-data .list .item .content {
        width: 40%;
        font-size: 14px;
        color: #777;
        display: flex;
        align-items: center;
    }
    .user-box .user-data .list .item .content::before {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: 6px;
        content: '';
    }
    .user-box .user-data .list .item .content span {
        line-height: 28px;
        font-weight: bold;
        font-size: 20px;
        color: #333;
        margin-right: 2px;
        margin-left: 8px;
    }
    .user-box .user-data .list .item .data1 {margin-left: 10px;}
    .user-box .user-data .list .item .data1::before {background-color: #165DFF;}
    .user-box .user-data .list .item .data2 {margin-left: 10px;justify-self: flex-end;}
    .user-box .user-data .list .item .data2::before {background-color: #FF6DC9;}

    .user-box .user-data .gender {
        padding-right: 10px;
        padding-left: 40px;
    }
    .user-box .user-data .gender .item {
        line-height: 20px;
        font-size: 12px;
        color: #777;
    }
    .user-box .user-data .gender .item .comparative {margin-left: 10px;}


    .common-box {
        width: 352px;
        padding-left: 32px;
        margin-left: 24px;
    }

    .common-box .item:not(:last-child) {margin-bottom: 12px;}
    .common-box .item .info {margin-top: 12px;}
    .common-box .item .number {
        line-height: 42px;
        font-weight: bold;
        font-size: 30px;
        color: #333;
        margin-top: 7px;
    }
    .common-box .item .versus {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .common-box .item .versus span {
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 10px;
        color: #fff;
        background-color: #0085FF;
        border-radius: 50%;
        margin: 4px 0;
        text-align: center;
        user-select: none;
    }
    .common-box .item .versus::before , .common-box .item .versus::after {
        width: 1px;
        height: 18px;
        background-color: #D9D9D9;
        content: '';
    }
</style>