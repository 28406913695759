<template>
    <div>
        <div class="page-title">
            <div class="flex flex-items-center">
                <div class="title">群信息</div>
                <!-- <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat">修改群信息</el-button> -->
            </div>
        </div>
        <div class="info-rows">
            <div class="span-6 col">
                群头像：<el-image style="width: 40px;" :src="fileDomain + teamChatInfo.icon" :preview-src-list="[fileDomain + teamChatInfo.icon]" />
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['icon'])">更换</el-button>
            </div>
            <div class="span-6 col">
                群名称：{{teamChatInfo.name}}
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['name'])">修改</el-button>
            </div>
			<div class="span-6 col">
			    群ID：{{teamChatInfo.tid}}
			    <!-- <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['labels'])">修改</el-button> -->
			</div>
            <div class="span-6 col">
                群分类：{{teamChatInfo.categoryName}}
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['categoryId'])">修改</el-button>
            </div>
            
            <div class="span-6 col">
                群主：{{ teamChatInfo.nickName }}<!-- (<a :href="'/userNumber/' + teamChatInfo.owner" target="_blank" >{{ teamChatInfo.owner }}</a>) -->
                <!-- <el-button type="danger" size="mini" style="margin-left: 10px;" @click="transferVisible = true">转让</el-button> -->
            </div>
            <div class="span-6 col">
                女用户进群：<span :class="{'color-warning': teamChatInfo.womanCoinNumber > 0}">{{ teamChatInfo.womanCoinNumber > 0 ? teamChatInfo.womanCoinNumber : '免费' }}</span>
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['womanCoinNumber'])">进群费用</el-button>
            </div>
            <div class="span-6 col">
                男用户进群：<span :class="{'color-warning': teamChatInfo.manCoinNumber > 0}">{{ teamChatInfo.manCoinNumber > 0 ? teamChatInfo.manCoinNumber : '免费' }}</span>
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['manCoinNumber'])">进群费用</el-button>
            </div>
            <div class="span-6 col">
                贵族用户进群：<span :class="{'color-warning': teamChatInfo.vipCoinNumber > 0}">{{ teamChatInfo.vipCoinNumber > 0 ? teamChatInfo.vipCoinNumber : '免费' }}</span>
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['vipCoinNumber'])">进群费用</el-button>
            </div>
			<div class="span-6 col">
			    群标签：{{teamChatInfo.labels}}
			    <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['labels'])">修改</el-button>
			</div>
            <div class="span-6 col">
                不活跃自动踢出群聊天数：{{teamChatInfo.autoKickOut ? `${teamChatInfo.autoKickOut}（天）` : ''}}
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['autoKickOut'])">修改</el-button>
            </div>
            <div class="span-6 col">
                群最大容纳人数：{{teamChatInfo.totalNumber}}
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['totalNumber'])">修改</el-button>
            </div>
            <div class="span-6 col">
                群聊状态：<span :class="['color-success','color-danger'][teamChatInfo.muteType]">{{teamChatInfo.muteType === 0 ? '正常' : '禁言中'}}</span>
                <el-button type="danger" size="mini" style="margin-left: 10px;" @click="muteTeamChat(1)" v-if="teamChatInfo.muteType === 0">禁言</el-button>
                <el-button type="success" size="mini" style="margin-left: 10px;" @click="muteTeamChat(0)" v-else>解除</el-button>
            </div>
            <div class="span-6 col">
                建群时间：{{teamChatInfo.createTime}}
            </div>
            <div class="span-6 col">
                实名后自动拉入群聊：{{teamChatInfo.isRealName === 1 ? '是' : '否'}}
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['isRealName'])">修改</el-button>
            </div>
            <div class="span-24 col">
                群简介：{{teamChatInfo.intro}}
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['intro'])">修改</el-button>
            </div>
            <div class="span-24 col">
                群公告：{{teamChatInfo.notice}}
                <el-button type="warning" size="mini" style="margin-left: 10px;" @click="editTeamChat(['notice'])">修改</el-button>
            </div>
        </div>

        <el-divider />

        <div class="page-title">
            <div class="flex flex-items-center">
                <div class="title">群成员</div>
                <el-input style="width: 140px;margin-left: 10px;" v-model="query.nickName" clearable placeholder="搜索群成员昵称" size="mini" @keydown.enter.native="searchMember" />
                <el-input style="width: 140px;margin-left: 10px;" v-model="query.userNumber" clearable placeholder="搜索群成员ID" size="mini" @keydown.enter.native="searchMember" />
                <el-button style="margin-left: 10px;" type="primary" size="mini" @click="searchMember">搜索</el-button>
                <el-button type="primary" size="mini" style="margin-left: 10px;" @click="inviteVisible = true">邀请成员</el-button>
            </div>
            <div class="flex flex-items-center">
                <el-button type="danger" size="mini" style="margin-left: 10px;" @click="disbandTeamChat">解散群聊</el-button>
            </div>
        </div>
        <el-table :data="membersList" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
            <el-table-column prop="nickName" label="昵称" min-width="100" align="center"></el-table-column>
			<el-table-column prop="userNumber" label="编号" min-width="100" align="center">
                <template slot-scope="scope">
                    <!-- <a :href="'/userNumber/' + scope.row.userNumber" target="_blank">{{ scope.row.userNumber }}</a> -->
                    {{ scope.row.userNumber }}
                </template>
            </el-table-column>
			<el-table-column prop="headImg" label="头像" min-width="80" align="center">
				<template slot-scope="scope">
					<div class="img-content">
						<el-image style="width: 40px; height: 40px;" :src="fileDomain + scope.row.headImg" :preview-src-list="[fileDomain + scope.row.headImg]" v-if="scope.row.headImg" />
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="identity" label="身份" min-width="50" align="center">
				<template slot-scope="scope">
                    <span  :class="['color-info','color-warning','color-primary'][scope.row.identity]">{{ ['群员','管理员','群主'][scope.row.identity] }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="level" label="等级" min-width="50" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" min-width="100" align="center">
				<template slot-scope="scope">
                    <el-button type="danger" size="mini" @click="kickMember(scope.row)" v-if="scope.row.identity !== 2">踢出群聊</el-button>
					
                    <template v-if="scope.row.identity !== 2">
						<el-dropdown trigger="click" placement="top" style="margin-left: 10px;" @command="(muteDuration) => muteCommand(muteDuration, scope.row)" v-if="scope.row.isMute === 0">
							<el-button type="warning" size="mini">禁言</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="mute.value" v-for="(mute, index) in muteAry" :key="index">{{mute.label}}</el-dropdown-item>
								<el-dropdown-item command="">自定义</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						
                        <el-button type="success" size="mini" @click="muteUserSend(scope.row.userNumber, 0)" v-else>解除</el-button>
                    </template>
                    <!-- <el-button type="text" size="mini" @click="openDetail(scope.row)" >详情</el-button> -->
				</template>
			</el-table-column>
        </el-table>
        <div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

        <el-dialog title="转让群主" width="500px" :visible.sync="transferVisible" :close-on-click-modal="false">
            <el-select
                v-model="transferUserNumber"
                filterable
                remote
                reserve-keyword
                placeholder="请输入新群主ID"
                :remote-method="remoteSearchUser"
                :loading="transferUserLoading"
            >
                <el-option
                    v-for="item in transferUserList"
                    :key="item.userNumber"
                    :label="`${item.nickName} (${item.userNumber})`"
                    :value="item.userNumber"
                    :disabled="item.userNumber === teamChatInfo.owner"
                />
            </el-select>

            <div slot="footer" class="dialog-footer">
                <el-button @click="transferVisible = false">取消</el-button>
                <el-button type="primary" :disabled="!transferUserNumber" @click="transferSend">确定</el-button>
            </div>
        </el-dialog>
		
		<el-dialog title="自定义禁言时长" width="500px" :visible.sync="muteVisible" :close-on-click-modal="false">
			<div style="text-align: left;">
				<div>设定 {{ muteUserInfo?.nickName }} 的禁言时长为：</div>
				<el-input v-model="muteValues[0]" type="number" size="mini" :min="0" :max="30" style="width: 130px;">
					<template slot="append">天</template>
				</el-input>
				<el-input v-model="muteValues[1]" type="number" size="mini" :min="0" :max="60" style="width: 130px;">
					<template slot="append">小时</template>
				</el-input>
				<el-input v-model="muteValues[2]" type="number" size="mini" :min="0" :max="60" style="width: 130px;">
					<template slot="append">分钟</template>
				</el-input>
			</div>
			<div slot="footer" class="dialog-footer">
			    <el-button @click="muteVisible = false">取消</el-button>
			    <el-button type="primary" @click="muteUserConfirm">确定</el-button>
			</div>
		</el-dialog>

        <el-dialog title="邀请成员" width="800px" :visible.sync="inviteVisible" :close-on-click-modal="false">
            <div class="invite-box">
                <div class="search-list">
                    <div class="search flex">
                        <el-input v-model="inviteSearch.nickName" type="text" clearable :prefix-icon="inviteLoading ? 'el-icon-loading' : 'el-icon-search'" size="mini" placeholder="搜索用户昵称" @keydown.enter.native="searchUser" />
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <el-input v-model="inviteSearch.userNumber" type="number" clearable :prefix-icon="inviteLoading ? 'el-icon-loading' : 'el-icon-search'" size="mini" placeholder="搜索用户ID" @keydown.enter.native="searchUser" />
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <el-button type="success" size="mini" @click="batchDialogVisible = true">批量查询</el-button>
                    </div>
                    <div class="item" v-for="(item, index) in searchMembers" :key="index">
                        <div class="info">
                            <div class="avatar">
                                <el-image :src="fileDomain + item.headImg" />
                            </div>
                            <div class="name">{{item.nickName}}
                            <div class="flex info-gap">
                                <div class="color-primary">{{item.userNumber}}</div>
                                <div _class="color-success">{{['未知','男','女'][item.gender]}}</div>
                                <div _class="color-success">{{item.age ? item.age + '岁' : '未知年龄'}}</div>
                                <div :class="[item.vipLevel ? 'color-warning' : '']">{{item.vipLevel ? '贵族用户' : '普通用户'}}</div>
                                <div :class="[item.isRealName ? '' : 'color-danger']">{{item.isRealName ? '已实名' : '未实名'}}</div>
                            </div>
                            </div>
                        </div>
                        <div class="opts">
                            <span v-if="inviteUserNumbers.includes(item.userNumber)">已选中</span>
                            <span v-else-if="inviteIsMember.includes(item.userNumber)">已是群员</span>
                            <el-button type="primary" size="mini" @click="inviteMembersChoose(item)" v-else>选中</el-button>
                        </div>
                    </div>
                    {{ searchComplete && !searchMembers.length ? '未查询到用户' : '' }}
                </div>
                <div class="invite-list">
                    <div class="item" v-for="(item,index) in inviteMembers" :key="index">
                        <div class="info">
                            <div class="avatar">
                                <el-image :src="fileDomain + item.headImg" />
                            </div>
                            <div class="name">{{item.nickName}}<br/>(<span class="color-primary">{{item.userNumber}}</span>)</div>
                        </div>
                        <div class="opts">
                            <el-button type="danger" size="mini" @click="inviteMembers.splice(index, 1)">移除</el-button>
                        </div>
                    </div>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="inviteVisible = false">取消</el-button>
                <el-button type="primary" :disabled="!inviteMembers || !inviteMembers.length" @click="inviteTeamChat">确定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="使用ID批量查询用户" :visible.sync="batchDialogVisible">
            请输入用户ID，多个用户ID使用逗号分隔
            <el-input v-model="batchUserNumberContent" type="textarea" :rows="6" />

            <div slot="footer" class="dialog-footer">
                <el-button @click="batchDialogVisible = false">取消</el-button>
                <el-button type="primary" :disabled="!batchUserNumberContent || batchSearching" @click="batchUserNumberConfirm">确定</el-button>
            </div>
        </el-dialog>

        <InfoFormDialog ref="infoFormDialog" @success="updateInfoSuccess" />
    </div>
</template>

<script>
import {
    userPage,
    teamChatDetail,
    teamChatDetailById,
    getTeamMember,
    kickTeamChat,
    inviteTeamChat,
    disbandTeamChat,
    transferTeamChat,
    muteTeamChat,
    muteUser
} from "@/api/api";
import InfoFormDialog from './components/InfoFormDialog.vue'
export default {
    components: {
        InfoFormDialog
    },
    data() {
        return {
            id: null,
            tid: null,
            teamChatInfo: {},

            page: 1,
			size: 10,
			total: 0,
            query: {
                nickName: '',
                userNumber: '',
            },
            membersList: [],

            fileDomain: 'https://file.oiki.cc/',

            transferVisible: false,
            transferUserNumber: '',
            transferUserLoading: false,
            transferUserList: [],
            
			muteVisible: false,
			muteUserInfo: undefined,
			muteValues: [0,0,0],
			
            batchDialogVisible: false,
            batchSearching: false,
            batchUserNumberContent: '',

            inviteVisible: false,
            inviteLoading: false,
            searchComplete: false,
            inviteMaxNumber: 50,
            inviteIsMember: [],
            inviteSearch: {
                nickName: '',
                userNumber: ''
            },
            searchMembers: [],
            inviteMembers: []
        }
    },
    computed: {
        inviteUserNumbers() {
            return this.inviteMembers.map(item => item.userNumber)
        },
		muteAry() {
			return [
				{ label: '5分钟', value: 5 },
				{ label: '10分钟', value: 10 },
				{ label: '1小时', value: 60 },
				{ label: '12小时', value: 12 * 60 },
				{ label: '1天', value: 24 * 60 },
			]
		},
    },
    watch: {
        inviteVisible: {
            handler(val) {
                if (!val) {
                    this.inviteLoading = false
                    this.searchComplete = false
                    this.inviteIsMember = []
                    this.inviteSearch.nickName = ''
                    this.inviteSearch.userNumber = null
                    this.searchMembers = []
                    this.inviteMembers = []
                }
            }
        }
    },
    methods: {
        getTeamDetail() {
            const send = this.tid ? teamChatDetail(this.tid) : teamChatDetailById(this.id)

            send.then(res => {
                this.teamChatInfo = {...res}
                if (!this.tid) this.tid = this.teamChatInfo.tid
				
				this.getMemberList()
            })
        },
        editTeamChat(editFields = []) {
            if (!this.teamChatInfo || !this.tid) {
                this.$message.error('缺少关键参数，请重新从列表打开详情页')
                return;
            }

            this.$refs.infoFormDialog.openForm(this.teamChatInfo, editFields)
        },
        async muteTeamChat(type) {
            if (this.teamChatInfo.muteType === 0 && type !== 0) {
                await new Promise((resolve) => {
                    this.$confirm('确定要开启群聊禁言吗？','开启群聊禁言', {
                        type: 'warning'
                    }).then(() => {
                        resolve()
                    })
                })
            }

            muteTeamChat({tid: this.tid, muteType: type}).then(() => {
                this.teamChatInfo.muteType = type
            })
        },
		muteCommand(muteDuration, row) {
			if (muteDuration) {
				this.muteUserSend(row.userNumber, 1, muteDuration)
			} else {
				this.muteVisible = true
				this.muteUserInfo = row
				this.muteValues = [2, 0, 0]
			}
			console.log(muteDuration,'muteDuration')
		},
		muteUserConfirm() {
			console.log(this.muteValues, 'muteUserConfirm')
			let sum = 0
			let fail = false
			// 检查数值是否正确
			for (const value of this.muteValues) {
				const val = parseInt(value)
				sum += value
				if (val < 0) fail = true
			}
			if (sum <= 0) fail = true
			if (fail) {
				this.$message.error('请选择正确的禁言时间')
				return;
			}
			
			const muteDuration = this.muteValues.reduce((sum, value, index) => {
				const val = parseInt(value)
				if (index === 0) return sum + val * 24 * 60		// 天转换为分钟
				else if (index === 1) return sum + val * 60		// 小时转换为分钟
				return sum + val
			}, 0)
			
			console.log(muteDuration,'muteDuration')
			this.muteUserSend(this.muteUserInfo.userNumber, 1, muteDuration)
			this.muteVisible = false
		},
        async muteUserSend(userNumber, type, muteDuration = undefined) {
            muteUser({tid: this.tid,accid: userNumber, mute: type, muteDuration: type === 1 ? muteDuration : undefined}).then(() => {
                this.getMemberList()
            })
        },
        searchMember() {
            this.page = 1
            this.getMemberList()
        },
        handleSizeChange(size) {
			this.size = size
			this.getMemberList()
		},
		changeClick(page) {
			this.page = page
			this.getMemberList()
		},
        getMemberList() {
            getTeamMember({
                tid: this.tid,
                size: this.size,
                current: this.page,
                ...this.query,
            }).then(res => {
                this.membersList = res.records
                this.total = res.total
            })
        },
        remoteSearchUser(value) {
            // console.log(value,'value remoteSearchUser')
            if (value.length === 8 && !this.transferUserLoading) {
                this.transferUserLoading = true
                getTeamMember({tid: this.tid, userNumber: value}).then(res => {
                    this.transferUserList = res.records
                    console.log(res,'res userNumber')
                }).finally(() => {
                    this.transferUserLoading = false
                })
            }
        },
        transferSend() {
            transferTeamChat({tid: this.tid, leave: 2, newowner: this.transferUserNumber}).then(res => {
                this.transferVisible = false
                this.getTeamDetail()
                this.getMemberList()
            })
        },
        searchUser(/* value */) {
            if (this.inviteLoading) return;
            if (this.inviteSearch.nickName == '' && !this.inviteSearch.userNumber) {
                this.$message.error('请输入昵称或ID搜索用户')
                return;
            }
            // if (value.length === 8) {
                this.inviteLoading = true
                // this.inviteIsMember = false

                userPage({
                    ...this.inviteSearch,
                    current: 1,
                    size: 100
                }).then(res => {
                    this.searchMembers = res.records
                }).finally(() => {
                    this.inviteLoading = false
                    this.searchComplete = true
                })
                
                /* getTeamMember({tid: this.tid, userNumber: value}).then(res => {
                    console.log(res,'res')
                    this.inviteIsMember = res.records.length > 0
                }) */
            /* } else {
                this.searchComplete = false
                this.searchMembers = []
            } */
        },
        async batchUserNumberConfirm() {
            const content = this.batchUserNumberContent.replace(/，/g,',')  // 中文逗号替换为英文逗号
            const userNumberAry = content.split(',').filter(text => text !== '' && !isNaN(parseInt(text))).map(text => parseInt(text))  // 拆分为数组，过滤空值和非数值内容
            if (!userNumberAry.length) {
                this.$message.error('请正确填写用ID')
                return;
            }

            function sleep(time) {
                return new Promise((resolve) => {
                    setTimeout(() => resolve(), time)
                })
            }

            this.batchSearching = true
            const errNumber = []
            this.searchMembers = []
            for (const index in userNumberAry) {
                const userNumber = userNumberAry[index]

                if (this.inviteMaxNumber && this.inviteMembers.length >= this.inviteMaxNumber) {
                    const untreated = userNumberAry.splice(index).map(number => {return { userNumber: number, type:3 }})
                    errNumber.push(...untreated)
                    break;
                }

                try {
                    const { records } = await userPage({ userNumber, current: 1, size: 1 })
                    const [user] = records

                    if (!user) {
                        errNumber.push({userNumber, type: 1})
                        continue;
                    }

                    this.searchMembers.push(user)
                    this.inviteMembersChoose(user)

                    await sleep(1000)
                } catch {
                    errNumber.push({userNumber, type: 0})
                }
            }

            this.batchDialogVisible = false
            this.batchSearching = false
            this.batchUserNumberContent = ''

            const errNumbers = errNumber.map(item => item.userNumber).join(',\u2004')
            if (errNumber.length) {
                this.$alert(`${errNumbers}`,'以下数据查询失败')
            }
            // console.log(userNumberAry,'xxxx')
        },
        inviteMembersChoose(item) {
            const numbers = this.inviteMembers.map(item => item.userNumber)
            if (numbers.indexOf(item.userNumber) >= 0) return;   // 已在数组中的数据不继续处理
            if (this.inviteMaxNumber && this.inviteMembers.length >= this.inviteMaxNumber) {
                this.$message.error(`一次最多只能邀请${this.inviteMaxNumber}人`)
                return;
            }

            getTeamMember({tid: this.tid, userNumber: item.userNumber}).then(res => {
                // console.log(res,'res')
                // this.inviteIsMember = res.records.length > 0
                if (res.records.length > 0) {
                    this.inviteIsMember.push(item.userNumber)
                    this.$message.error('当前用户已是群成员')
                } else {
                    this.inviteMembers.push(item)
                }
            })
        },
        inviteTeamChat() {
            if (!this.inviteMembers.length) return;

            const members = this.inviteMembers.map(item => item.userNumber)
            inviteTeamChat({
                tid: parseInt(this.tid),
                members,
                msg: '邀请进群'
            }).then(res => {
                this.inviteVisible = false
                this.getMemberList()
            })
        },
        kickMember(userItem) {
            if (!userItem) return;
            const formData = { tid: this.tid }

            function getItemContent (item) {
                return item.nickName + (item.nick ? `(${item.nick})`: '') + `(<span class="color-primary">${item.userNumber}</span>)`
            }

            let alertContent = ''
            if (Array.isArray(userItem)) {
                formData.members = userItem.map(item => item.userNumber)
                alertContent = userItem.map(item => getItemContent(item)).join('<br/>')
            } else {
                formData.member = userItem.userNumber
                alertContent = getItemContent(userItem)
            }
            
            this.$confirm(alertContent, '是否踢出以下成员', {
                dangerouslyUseHTMLString: true
            }).then(() => {
                console.log(formData,'formData')
                // return;
                kickTeamChat(formData).then(res => {
                    this.getMemberList()
                })
            })
            
        },
        disbandTeamChat() {
            this.$confirm('此操作将解散群聊，不可恢复！','警告', {
                type: 'warning',
            }).then(() => {
                disbandTeamChat(this.tid).then(res => {
                    this.$alert('群聊已解散','解散群聊', {
                        showClose: false,
                        showCancelButton: false,
                        confirmButtonText: '返回'
                    }).then(() => {
                        this.$router.back()
                    })
                })
            })
        },
        // 修改群信息之后更新数据
        updateInfoSuccess(formData) {
            this.getTeamDetail()
            /* for (const field of Object.keys(formData)) {
                const value = formData[field]
                if (field === 'id') {
                    continue;
                } else if (field === 'labels') {
                    this.teamChatInfo[field] = value.join(',')
                // } else if (['manCoinNumber', 'womanCoinNumber', 'vipCoinNumber'].includes(field)) {
                    // this.teamChatInfo[field] = parseInt(value)
                } else {
                    this.teamChatInfo[field] = value
                }
            }
            console.log(this.teamChatInfo,'teamChatInfo')
            console.log(formData,'formData')

            sessionStorage.setItem('teamChatInfo', JSON.stringify(this.teamChatInfo)) */
        }
    },
    created() {
        this.id = this.$route.params.id     // 仅特定情况才会使用id
        this.tid = this.$route.params.tid
        // this.teamChatInfo = JSON.parse(sessionStorage.getItem('teamChatInfo'))

        this.$nextTick(() => {
            this.getTeamDetail()
        })
    }
}
</script>

<style scoped>
.flex {
    display: flex;
}

.flex-items-center {
    align-items: center;
}

.info-gap {
    gap: 10px;
}

.page-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-title .title {
    white-space: nowrap;
}

.info-rows {
    display: flex;
    flex-wrap: wrap;
}

.info-rows .span-6 {width: 25%;}
.info-rows .span-18 {width: 75%;}
.info-rows .span-24 {width: 100%;}

.col {
    min-height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.invite-box {
    display: flex;
    justify-content: space-between;
    text-align: left;
    gap: 20px;
}

.invite-box .search-list {
    flex-grow: 1;
}

.invite-box .invite-list {
    width: 300px;
    flex-shrink: 0;
}

.invite-box .search-list , .invite-box .invite-list {
    /* width: 48%; */
    height: 50vh;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow-y: auto;
}

.invite-box .item {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.invite-box .item:hover {
    background-color: #f8f8f8;
}

.invite-box .item .info {
    line-height: 20px;
    display: flex;
    align-items: center;
}

.invite-box .item .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
</style>