<template>
    <div>
        <div class="flex-shrink flex-box justify-between" style="text-align:left;">
            <div>
                <el-form :inline="true" size="mini">
                    <el-form-item>
                        <el-radio-group v-model="query.type" @change="typeChange">
                            <el-radio-button label="day">按日</el-radio-button>
                            <el-radio-button label="time">按小时</el-radio-button>
                            <el-radio-button label="contrast">对比</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="查询日期">
                        <template v-if="query.type == 'contrast'">
                            <el-date-picker v-model="queryDate[query.type][0]" type="date" value-format="yyyy-MM-dd"></el-date-picker>
                            对比
                            <el-date-picker v-model="queryDate[query.type][1]" type="date" value-format="yyyy-MM-dd"></el-date-picker>
                        </template>
                        <el-date-picker
                            v-model="queryDate[query.type]"
                            :type="query.type == 'day' ? 'daterange' : 'datetimerange'"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            :value-format="query.type == 'day' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'"
                            v-else
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div>
                <span>数据更新倒计时：</span>
                <span class="timer">{{refreshTimeNumber}}s</span>
                <el-button :type="refreshTimerRuning ? 'warning' : 'primary'" size="mini" style="margin-left: 10px;" @click="refreshTimerTap">{{refreshTimerRuning ? '暂停' : '开始'}}</el-button>
            </div>
        </div>
        <div class="table-flex-box">
            <div :class="{'table-box': showEcharts, 'w-full': !showEcharts}">
                <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border :cell-style="{ padding: '0' }"
                    :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                    :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                    <el-table-column label="时间" :prop="query.type == 'day' ? 'registerDate' : 'registerTime'" align="center"></el-table-column>
                    <el-table-column label="安卓" prop="androidNumber" align="center"></el-table-column>
                    <el-table-column label="ios" prop="iosNumber" align="center"></el-table-column>
                    <el-table-column label="总数" prop="allNumber" align="center"></el-table-column>
                </el-table>
                <div style="text-align:right;" v-if="query.type != 'contrast'">
                    <el-pagination style="margin-top: 10px;" background layout="total, prev, pager, next, sizes"
                        :page-size="size" :total="total" :page-sizes="[30, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
                    </el-pagination>
                </div>
            </div>
            <div class="echarts-box" :style="{height: tableHeight + 'px'}" v-if="showEcharts">
                <div id="table-echarts" style="height: 100%;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { userStatisticsPage } from '@/api/api';
import setTableHeight from '@/utils/setTableHeight'
import * as echarts from 'echarts';
export default {
    data() {
        return {
            page: 1,
			size: 30,
			total: 0,
            query: {
                type: 'time',
            },
            queryDate: {
                day: [],
                time: [],
                contrast: []
            },
            tableData: [],
            tableEcharts: null,
            tableHeight: 0,

            refreshTimer: null,
            refreshTimerRuning: false,
            refreshTime: 30,
            refreshTimeNumber: 0,

            contrastData: [],

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0)
                        start.setMinutes(0)
                        start.setSeconds(0)

                        end.setHours(0)
                        end.setMinutes(0)
                        end.setSeconds(0)
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
                        start.setHours(0)
                        start.setMinutes(0)
                        start.setSeconds(0)
                        
                        end.setHours(0)
                        end.setMinutes(0)
                        end.setSeconds(0)
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
                        start.setHours(0)
                        start.setMinutes(0)
                        start.setSeconds(0)
                        
                        end.setHours(0)
                        end.setMinutes(0)
                        end.setSeconds(0)
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
                setTimeout(() => {
                    if (this.showEcharts) {
                        this.setEcharts()
                    }
                }, 500)
            }
        }
    },
    computed: {
        showEcharts() {
            return this.tableData.length && ['day', 'time', 'contrast'].includes(this.query.type)
        },
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1
            if (this.query.type == 'contrast') {
                this.getContrastData()
            } else {
                this.getList()
            }
        },
        getWeek() {
            const end = new Date();
            const start = new Date();
            
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            const dateFormat = 'YYYY-MM-DD'
            return [this.getFormattedTimeString(start, dateFormat), this.getFormattedTimeString(end, dateFormat)]
        },
        getDay() {
            const end = new Date();
            const start = new Date();
            start.setHours(0)
            start.setMinutes(0)
            start.setSeconds(0)

            end.setHours(0)
            end.setMinutes(0)
            end.setSeconds(0)
            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
            return [this.getFormattedTimeString(start), this.getFormattedTimeString(end)]
        },
        getFormattedTimeString(date = new Date(), format = "YYYY-MM-DD HH:mm:ss") {
            const map = {
                "YYYY": date.getFullYear(),
                "MM": date.getMonth() + 1,
                "DD": date.getDate(),
                "HH": date.getHours(),
                "mm": date.getMinutes(),
                "ss": date.getSeconds()
            };
            
            return format.replace(/YYYY|MM|DD|HH|mm|ss/g, match => {
                const value = map[match];
                return value < 10 ? `0${value}` : value;
            });
        },
        getList() {
            userStatisticsPage({
				current: this.page,
				size: this.size,
                type: this.query.type == 'day' ? 0 : 1,
                startTime: this.queryDate[this.query.type][0] || '',
                endTime: this.queryDate[this.query.type][1] || ''
			}).then((res) => {
                this.total = res.total
                this.tableData = res.records.map(item => {
                    if (this.query.type == 'time') {
                        item.registerTime = item.registerTime + ':00'
                    }
                    return item
                })

                this.initRefreshTimer()
            })
        },
        getContrastData() {
            this.tableData = []
            if (this.queryDate.contrast[0] && this.queryDate.contrast[1]) {

                if (this.queryDate.contrast[0] == this.queryDate.contrast[1]) {
                    this.$message.error('对比日期不能相同')
                    return;
                }

                const getStartTime = (day) => day + ' 00:00:00'
                const getEndTime = (day) => day + ' 23:59:59'

                const params = {
                    current: 1,
                    size: 20,
                    type: 1,
                }

                let total = 0
                for (let index in this.queryDate.contrast) {
                    const day = this.queryDate.contrast[index]
                    const startTime = getStartTime(day)
                    const endTime = getEndTime(day)

                    userStatisticsPage({startTime, endTime, ...params}).then(res => {
                        total += res.total
                        this.total = total
                        const list = res.records.map(item => {
                            item.registerTime = item.registerTime + ':00'
                            return item
                        })

                        this.contrastData[index] = list || []
                        if (list.length) {
                            this.tableData = this.tableData.concat(list)
                        }

                        this.initRefreshTimer()
                    })
                }

            }
        },
        typeChange() {
            this.page = 1
            this.search()
        },
        handleSizeChange(size) {
			this.size = size
			this.getList()
		},
		changeClick(page) {
			this.page = page
			this.getList()
		},
        initEcharts() {
            const chartDom = document.getElementById('table-echarts')
            this.tableEcharts = echarts.init(chartDom)
            console.log(this.tableEcharts,'this.tableEcharts')
        },
        setEcharts() {
            this.initEcharts()
            this.tableEcharts.clear()   // 清除旧图表

            const xData = []
            let series = []
            const seriesOptions = {
                type: 'line',
                smooth: true,
                label: {
                    show: true
                }
            }

            const legend = {}
            
            switch (this.query.type) {
                case 'day':
                    {
                        const seriesData = []
                        const list = [...this.tableData]    // 浅拷贝
                        list.reverse()      // 数组反转
                        console.log(list,'list')
                        list.forEach(item => {
                            const date = item.registerDate.slice(5)
                            xData.push(date)
                            seriesData.push(item.allNumber)
                        })
            
                        series.push({
                            data: seriesData,
                            ...seriesOptions
                        })
                    }
                break;
                case 'time': 
                    {
                        const seriesData = []
                        const list = [...this.tableData]    // 浅拷贝
                        list.reverse()      // 数组反转
            
                        list.forEach(item => {
                            const time = item.registerTime.slice(11)
                            xData.push(time)
                            seriesData.push(item.allNumber)
                        })
            
                        series.push({
                            data: seriesData,
                            ...seriesOptions
                        })
                    }
                break;
                case 'contrast':
                    {
                        // 获取时间数组(去重)
                        const timeAry = new Set()
                        for (let item of this.tableData) {
                            const time = item.registerTime.slice(11)
                            timeAry.add(time)
                        }
                        // 填充X轴
                        for (let time of timeAry) {
                            xData.push(time)
                        }

                        // X轴时间升序
                        xData.sort((a,b) => parseInt(a) - parseInt(b))

                        legend.data = [...this.queryDate.contrast]

                        for (let index in this.contrastData) {
                            const list = [...this.contrastData[index]]
                            const seriesData = []
                            for (let time of xData) {
                                let item;
                                item = list.find(item => {
                                    const itemTime = item.registerTime.slice(11)
                                    return itemTime == time
                                })

                                seriesData.push(item ? item.allNumber : 0)
                            }

                            series.push({
                                name: this.queryDate.contrast[index],
                                data: seriesData,
                                ...seriesOptions
                            })
                        }
                    }
                break;
            }

            const option = {
                series,
                legend,
                xAxis: {
                    type: 'category',
                    data: xData
                },
                yAxis: {
                    type: 'value'
                },
                tooltip: {
                    show: true,
                    trigger: 'axis'
                }
            }

            console.log(option,'option')

            this.tableEcharts.setOption(option)
        },
        initRefreshTimer() {
            clearInterval(this.refreshTimer)
            this.refreshTimeNumber = this.refreshTime
            this.startRefreshTimer()
        },
        startRefreshTimer() {
            this.refreshTimerRuning = true
            this.refreshTimer = setTimeout(() => {
                if (this.refreshTimeNumber <= 0) {
                    this.refreshTimerEnd()
                } else if (this.refreshTimerRuning) {
                    this.refreshTimeNumber--
                    this.startRefreshTimer()
                }
                console.log(this.refreshTimeNumber,'refreshTimeNumber')
            }, 1000)
        },
        pauseRefreshTimer() {
            this.refreshTimerRuning = false
            clearTimeout(this.refreshTimer)
        },
        refreshTimerEnd() {
            console.log(this.refreshTimeNumber, 'refreshTimerEnd')
            this.search()
            this.pauseRefreshTimer()
        },
        refreshTimerTap() {
            this.refreshTimerRuning ? this.pauseRefreshTimer() : this.startRefreshTimer()
        }
    },
    created() {
        this.queryDate.day = this.getWeek()
        this.queryDate.time = this.getDay()

        this.getList()

        window.onresize = () => {
            this.setTableHeight()
        }
    },
    beforeDestroy() {
        this.pauseRefreshTimer()
    }
}
</script>

<style>
.flex-box {
    display: flex;
}

.flex-items-align {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}


.table-flex-box {
    display: flex;
    justify-content: space-between;
}

.table-flex-box .table-box {
    width: 40%;
}

.table-flex-box .w-full {
    width: 100%;
}

.table-flex-box .echarts-box {
    width: 55%;
}
</style>