<template>
    <el-dialog width="800px" title="" :visible.sync="formVisible" :close-on-click-modal="false">
        <el-form ref="form" class="text-left" :model="form" label-width="200px" :rules="formRules" size="mini">
            <el-form-item prop="icon" label="群头像" v-if="!form.tid || (form.tid && editFields.indexOf('icon') >= 0)">
                <div class="flex-rows">
                    <el-upload
                        ref="upload"
                        class="avatar-uploader"
                        action="/api/file/upload"
                        accept=".jpg,.jpeg,.png,.pneg,.gif,.Jpg,.Jpeg,.Png,.Pneg,.Gif"
                        :disabled="uploadAvatarLoading"
                        :limit="1"
                        :headers="headers"
                        :data="{code: 5001, index: 0}"
                        :show-file-list="false"
                        :before-upload="beforeUploadAvatar"
                        :on-success="handleAvatarSuccess"
                        :on-error="handleAvatarError"
                    >
                        <img v-if="form.icon" :src="fileDomain + form.icon" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <i class="avatar-loading el-icon-loading" v-if="uploadAvatarLoading"></i>
                </div>
            </el-form-item>
            <el-form-item prop="name" label="群名称" v-if="!form.tid || (form.tid && editFields.indexOf('name') >= 0)">
                <el-input v-model="form.name" placeholder="请输入群名称" maxlength="10"></el-input>
            </el-form-item>
            <el-form-item prop="categoryId" label="群分类" v-if="!form.tid || (form.tid && editFields.indexOf('categoryId') >= 0)">
                <el-select v-model="form.categoryId" placeholder="请选择群分类">
                    <el-option :label="item.name" :value="item.id" v-for="item in categoryList" :key="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="labels" label="群标签" v-if="form.labels && (!form.tid || (form.tid && editFields.indexOf('labels') >= 0))">
                <el-input v-model="form.labels[0]" maxlength="4" style="width: 110px;" />
                <el-input v-model="form.labels[1]" maxlength="4" style="width: 110px;" />
                <el-input v-model="form.labels[2]" maxlength="4" style="width: 110px;" />
            </el-form-item>
            <el-form-item prop="intro" label="群介绍" v-if="!form.tid || (form.tid && editFields.indexOf('intro') >= 0)">
                <el-input v-model="form.intro" type="textarea" :rows="3"></el-input>
            </el-form-item>
            <el-form-item prop="owner" label="群主" v-if="!form.tid">
                <el-select
                    v-model="form.owner"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入群主ID"
                    :remote-method="remoteSearchUser"
                    :loading="userSearchLoading"
                >
                    <el-option
                        v-for="item in userSearchList"
                        :key="item.userNumber"
                        :label="`${item.nickName} (${item.userNumber})`"
                        :value="item.userNumber"
                    />
                </el-select>
            </el-form-item>
            <el-form-item prop="members" label="群成员" v-if="!form.tid">
                <el-select
                    v-model="form.members"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入群成员ID"
                    :remote-method="remoteSearchService"
                    :loading="serviceSearchLoading"
                >
                    <el-option
                        v-for="item in serviceSearchList"
                        :key="item.userNumber"
                        :label="`${item.nickName} (${item.userNumber})`"
                        :value="item.userNumber"
                    />
                </el-select>
                <span class="color-danger" style="margin-left: 10px;">注：因为建群需要两个成员，默认列表为客服，可输入用户ID搜索选择用户</span>
            </el-form-item>
            <el-form-item prop="notice" label="公告" v-if="!form.tid || (form.tid && editFields.indexOf('notice') >= 0)">
                <el-input v-model="form.notice" type="textarea" :rows="3"></el-input>
            </el-form-item>

            <el-form-item prop="womanCoinType" label="女用户" v-if="!form.tid || (form.tid && editFields.indexOf('womanCoinNumber') >= 0)">
                <div class="form-row">
                    <el-radio-group v-model="form.womanCoinType">
                        <el-radio label="">免费</el-radio>
                        <el-radio label="1">
                            K币
                            <span @click="form.womanCoinType = '1'">
                                <el-input v-model="form.womanCoinNumber" type="number" placeholder="输入K币数额" style="width: 110px;" />
                            </span>
                        </el-radio>
                        <span class="color-danger" style="font-size: 12px;">注：填0也是免费</span>
                    </el-radio-group>
                </div>
            </el-form-item>

            <el-form-item prop="manCoinType" label="男用户" v-if="!form.tid || (form.tid && editFields.indexOf('manCoinNumber') >= 0)">
                <div class="form-row">
                    <el-radio-group v-model="form.manCoinType">
                        <el-radio label="">免费</el-radio>
                        <el-radio label="1">
                            K币
                            <span @click="form.manCoinType = '1'">
                                <el-input v-model="form.manCoinNumber" type="number" placeholder="输入K币数额" style="width: 110px;" />
                            </span>
                        </el-radio>
                        <span class="color-danger" style="font-size: 12px;">注：填0也是免费</span>
                    </el-radio-group>
                </div>
            </el-form-item>
            <el-form-item prop="vipCoinType" label="贵族用户" v-if="!form.tid || (form.tid && editFields.indexOf('vipCoinNumber') >= 0)">
                <div class="form-row">
                    <el-radio-group v-model="form.vipCoinType">
                        <el-radio label="">免费</el-radio>
                        <el-radio label="1">
                            K币
                            <span @click="form.vipCoinType = '1'">
                                <el-input v-model="form.vipCoinNumber" type="number" placeholder="输入K币数额" style="width: 110px;" />
                            </span>
                        </el-radio>
                        <span class="color-danger" style="font-size: 12px;">注：填0也是免费</span>
                    </el-radio-group>
                </div>
            </el-form-item>

            <el-form-item prop="isRealName" label="实名后自动拉入群聊" v-if="!form.tid || (form.tid && editFields.indexOf('isRealName') >= 0)">
                <el-radio-group v-model="form.isRealName">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="autoKickOut" label="自动踢出不活跃用户天数" v-if="!form.tid || (form.tid && editFields.indexOf('autoKickOut') >= 0)">
                <el-input v-model="form.autoKickOut" type="number" maxlength="4" placeholder="（天）" style="width: 110px;"></el-input>
            </el-form-item>
            <el-form-item prop="totalNumber" label="群最大容纳人数" _rules="[{ min: 2, message: '不可小于10', trigger: 'blur' }]" v-if="!form.tid || (form.tid && editFields.indexOf('totalNumber') >= 0)">
                <el-input v-model="form.totalNumber" type="number" maxlength="4" style="width: 110px;"></el-input>
                <span class="color-danger" style="font-size: 12px;margin-left: 10px;">注：不填则为默认配置</span>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false">取 消</el-button>
            <el-button type="primary" :disabled="uploadAvatarLoading" :loading="formLoading" @click="formConfirm">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {
    teamChatPage,
	teamChatPost,
	teamChatUpdate,
    teamChatDelete,
    userPage,
    teamCategoryPage
} from "@/api/api";
export default {
    data() {
        return {
            userSearchLoading: false,
            userSearchList: [],

            serviceSearchLoading: false,
            serviceSearchList: [],
            serviceList: [],

            headers: {
                Authorization: "Bearer " + localStorage.token
            },
            uploadAvatarLoading: false,

            fileDomain: 'https://file.oiki.cc/',

            categoryList: [],

            editFields: [],

            formVisible: false,
            formLoading: false,
            form: {},
            formRules: {},
        }
    },
    watch: {
        'form.tid': {
            handler() {
                this.formRules = {
                    icon: [
                        { required: true, message: '请上传群头像' }
                    ],
                    name: [
                        { required: true, message: '请输入群名称' }
                    ],
                    categoryId: [
                        { required: true, message: '请选择群分类' }
                    ],
                    owner: [
                        { required: true, message: '请设置群主' }
                    ],
                    members: [
                        { required: true, message: '请选择群成员' }
                    ],
                }

                if (this.form.tid) {
                    this.formRules = {}
                    // delete this.formRules.owner
                    // delete this.formRules.members
                }
            },
            immediate: true
        }
    },
    methods: {
        getCategoryList() {
            teamCategoryPage().then(res => {
                this.categoryList = res
            })
        },

        remoteSearchUser(value) {
            // console.log(value,'value remoteSearchUser')
            if (value.length === 8 && !this.userSearchLoading) {
                this.userSearchLoading = true
                userPage({userNumber: value}).then(res => {
                    this.userSearchList = res.records
                    console.log(res,'res userNumber')
                }).finally(() => {
                    this.userSearchLoading = false
                })
            }
        },

        remoteSearchService(value) {
            // console.log(value,'value remoteSearchService')
            if (value.length === 8) {
                this.serviceSearchLoading = true
                userPage({userNumber: value}).then(res => {
                    this.serviceSearchList = res.records
                    console.log(res,'res userNumber')
                }).finally(() => {
                    this.serviceSearchLoading = false
                })
            } else if (value.length === 0) {
                this.serviceSearchList = this.serviceList
            }
        },

        // 获取客服列表
        getServicesList() {
            userPage({roleCode: 'CUSTOMER'}).then(res => {
                this.serviceList = res.records
                this.serviceSearchList = this.serviceList
                if (this.serviceList.length === 1) {
                    this.$set(this.form, 'members', this.serviceList[0].userNumber)
                }
            })
        },

        beforeUploadAvatar() {
            this.uploadAvatarLoading = true
        },
        handleAvatarSuccess(res, file) {
            this.form.icon = res.data
            this.uploadAvatarLoading = false
            this.$refs.upload.clearFiles()
        },
        handleAvatarError(err, file, fileList) {
            this.uploadAvatarLoading = false
            console.log(err, file, fileList,'handleAvatarError')
            this.$refs.upload.clearFiles()
        },
        formConfirm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const formData = {...this.form}
                    if (formData.manCoinType != '1') formData.manCoinNumber = 0
                    if (formData.womanCoinType != '1') formData.womanCoinNumber = 0
                    if (formData.vipCoinType != '1') formData.vipCoinNumber = 0
                    delete formData.manCoinType
                    delete formData.womanCoinType
                    delete formData.vipCoinType
                    formData.labels = formData.labels.filter(label => label)

                    // 新建群聊时
                    if (!formData.tid) {
                        formData.members = [formData.members]
                        formData.msg = '新建群聊'
                    } else {
                        delete formData.owner   // 修改群聊时，群主不可直接变更(要变更群主需调用"转让群主"接口)
                        delete formData.members // 修改群聊时，群成员不可直接变更

                        // 仅保留当前要编辑的字段
                        for (const formField in formData) {
                            if (formField === 'tid') continue    // id 是必填项
                            if (this.editFields.indexOf(formField) === -1) delete formData[formField]
                        }
                    }

                    console.log(formData,'formData')
                    // return;

                    const send = formData.tid ? teamChatUpdate : teamChatPost

                    send(formData).then(res => {
                        this.formVisible = false
                        this.$emit('success', formData)
                    })
                }
            })
        },
        openForm(form = {}, editFields = []) {
            this.initForm(form)
            this.formVisible = true

            if (!this.form.tid) {
                if (this.serviceList.length === 0) {
                    this.getServicesList()
                } else if (this.serviceList.length === 1) {
                    this.$set(this.form, 'members', this.serviceList[0].userNumber)
                }
            } else {
                this.editFields = editFields
            }

            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        initForm(form = {}) {
            console.log(form,'initForm')
            const fields = ['tid','icon','name','categoryId','labels','intro','owner','notice','manCoinNumber','womanCoinNumber', 'vipCoinNumber','autoKickOut', 'totalNumber']
            for (const field of fields) {
                if (field === 'labels') {
                    const value = form[field] && typeof form[field] === 'string' ? form[field].split(',') : ['','','']
                    this.$set(this.form, field, value)
                } else if (field === 'manCoinNumber') {
                    this.$set(this.form, field, form[field] > 0 ? form[field] : undefined)
                    this.$set(this.form, 'manCoinType', form[field] > 0 ? '1' : '')
                } else if (field === 'womanCoinNumber') {
                    this.$set(this.form, field, form[field] > 0 ? form[field] : undefined)
                    this.$set(this.form, 'womanCoinType', form[field] > 0 ? '1' : '')
                } else if (field === 'vipCoinNumber') {
                    this.$set(this.form, field, form[field] > 0 ? form[field] : undefined)
                    this.$set(this.form, 'vipCoinType', form[field] > 0 ? '1' : '')
                } else if (field === 'tid' && !(field in form)) {
                    this.$set(this.form, field, undefined)
                } else {
                    this.$set(this.form, field, form[field] ?? '')
                }
            }
        },
    },
    created() {
        this.getCategoryList()
    }
}
</script>

<style scoped>
.text-left {
    text-align: left;
}

.flex-rows {
    display: flex;
    align-items: center;
}

.avatar-loading {
    font-size: 30px;
    margin-left: 10px;
}

.avatar-uploader >>> .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader >>> .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
.avatar {
    width: 100px;
    height: 100px;
    display: block;
}

.form-row {
    display: flex;
}

.form-row .radio-box {
    margin-right: 10px;
    flex-shrink: 0;
}

.form-row .form-check-box {
    flex-grow: 1;
}
</style>