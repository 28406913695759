<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="query.type" placeholder="请选择类型">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in typeAry" :key="value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收支">
                    <el-select v-model="query.inOut" placeholder="请选择收支">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="收入" value="0"></el-option>
						<el-option label="支出" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="用户昵称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="用户ID" min-width="80" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="number" label="K币数量" min-width="100" align="center"></el-table-column>
            <el-table-column prop="totalNumber" label="K币总数" min-width="120" align="center"></el-table-column>
            <el-table-column prop="inOut" label="收支" min-width="100" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.inOut=== 0" style="color: #67C23A">收入</span>
                    <span v-else-if="scope.row.inOut=== 1" style="color: #F56C6C">支出</span>
				</template>
            </el-table-column>
            <el-table-column prop="type" label="类型" min-width="100" align="center">
                <template slot-scope="scope">
                    {{ typeAry[scope.row.type] }}
				</template>
            </el-table-column>
            <el-table-column prop="useDesc" label="用途" min-width="180" align="center">
                <template slot-scope="scope">
                    <a :href="'/chatGroupDetailById/' + scope.row.businessId" target="_blank" v-if="scope.row.type == 9 && scope.row.businessId">{{ scope.row.useDesc }}</a>
                    <template v-else>{{ typeAry[scope.row.type] }}</template>
				</template>
                
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    coinRecordPage
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            query: {
                nickName: "",
                userNumber: "",
                type: "",
                inOut: ""
            },
            formLabelWidth: "80px",

            typeAry: {
                0 : '充值',
                1 : '打赏',
                4 : '征友',
                5 : '新征友',
                6 : '申请征友',
                2 : '申请好友',
                3 : '单聊打赏',
                7 : '购买道具',
                8 : '匹配打赏',
                9 : '加入群聊',
                10 : '专属红包',
                11 : '视频连麦'
            }
        };
    },
    methods: {
        search() {
            this.page = 1;
            this.getCoinRecordList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getCoinRecordList();
        },
        //获取待审核动态分页列表
        getCoinRecordList() {
            coinRecordPage({
                current: this.page,
                size: this.size,
                inOut: this.query.inOut,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                type: this.query.type
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                })
        }
    },
    mounted() {
        this.getCoinRecordList();
    }
};
</script>