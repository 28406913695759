<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="昵称">
					<el-input v-model="query.nickName" placeholder="昵称" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="用户编号">
					<el-input v-model="query.userNumber" placeholder="用户编号" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
                <el-form-item label="赠送时间">
					<el-date-picker v-model="selectTime" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
					<el-button type="success" @click="openForm()">开通</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="nickName" label="昵称" min-width="150" align="center"></el-table-column>
			<el-table-column prop="userNumber" label="编号" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            
            <el-table-column prop="days" label="开通天数" min-width="80" align="center"></el-table-column>
            <el-table-column prop="remark" label="原因" min-width="200" align="center"></el-table-column>
            <el-table-column prop="createTime" label="操作时间" min-width="140" align="center"></el-table-column>
			<!-- <el-table-column fixed="right" label="操作" width="60" align="center">
				<template slot-scope="scope">
					<el-link type="primary" @click="toDetail(scope.row.id)">详情</el-link>
				</template>
			</el-table-column> -->
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

		<el-dialog title="开通贵族" :visible.sync="formDialogVisible" width="300px">
            <el-form label-width="80px">
                <el-form-item label="用户ID" props="userNumber">
                    <el-input v-model="form.userNumber"></el-input>
                </el-form-item>
                <el-form-item label="天数" props="days">
                    <el-input v-model="form.days" type="number"></el-input>
                </el-form-item>
                <el-form-item label="原因" props="remark">
                    <el-input v-model="form.remark" type="textarea" :rows="3"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
				<el-button @click="formDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="checkForm">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import {
    vipGivePage,
    giveVip,
	userInfoFindByNumber
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				nickName: '',
				userNumber: '',
            },

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			selectTime: [],

            formDialogVisible: false,
            form: {
                userNumber: '',
                days: '',
                remark: ''
            }
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getGiveList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.selectTime = []

			this.page = 1
			this.getGiveList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getGiveList()
		},
		changeClick(page) {
			this.page = page
			this.getGiveList()
		},
		//获取列表接口
		getGiveList() {
			vipGivePage({
				"current": this.page,
				"size": this.size,
				"nickName": this.query.nickName,
				"userNumber": this.query.userNumber,
                "startTime": this.selectTime != null ? this.selectTime[0] : null,
                "endTime": this.selectTime != null ? this.selectTime[1] : null,
			})
				.then((response) => {
					this.total = response.total
					this.tableData = response.records
				})
		},
        openForm() {
            this.formDialogVisible = true
        },
        async checkForm() {
            if (this.form.userNumber == '') {
                this.$message.error('请输入用户ID')
                return;
            } else if (!this.form.days) {
                this.$message.error('请输入开通天数')
                return;
            } else if (!this.form.remark) {
                this.$message.error('请输入开通原因')
                return;
            }
            
            try {
                const user = await userInfoFindByNumber({userNumber: this.form.userNumber})
                console.log(user,'user')
                if (!user) {
                    this.$message.error('用户不存在')
                    return;
                }

                this.$confirm('', '确认信息', {
                    message: this.$createElement('div', {}, [
                        this.$createElement('p', {}, `用户ID：${this.form.userNumber}`),
                        this.$createElement('p', {}, `用户昵称：${user.nickName}`),
                        this.$createElement('p', {}, `开通${this.form.days}天VIP`),
                        this.$createElement('p', {}, `开通原因：${this.form.remark}`),
                    ])
                }).then(() => {
                    console.log(123)
                    giveVip({
                        userNumber: this.form.userNumber,
                        days: this.form.days,
                        remark: this.form.remark,
                    }).then(() => {
                        this.formDialogVisible = false
                        this.form.userNumber = ''
                        this.form.days = ''
                        this.form.remark = ''
                        this.getGiveList()
                    })
                })
            } catch {}
        }
	},
	created() {
		//获取列表
		this.getGiveList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style>
</style>