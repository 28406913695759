<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="标签内容">
					<el-input v-model="query.nickName" placeholder="标签内容" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="标签类型">
					<el-select v-model="query.type" placeholder="标签类型" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="tag" :value="value" v-for="(tag, value) in tagType" :key="value"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="标签状态">
					<el-select v-model="query.status" placeholder="标签状态" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="status" :value="value" v-for="(status, value) in tagStatus" :key="value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="type" label="标签类型" min-width="150" align="center">
				<template slot-scope="scope">
					{{ tagType[scope.row.type] }}
				</template>
			</el-table-column>
			<el-table-column prop="content" label="标签内容" min-width="100" align="center"></el-table-column>
			<el-table-column prop="status" label="状态" min-width="60" align="center">
				<template slot-scope="scope">
					<div :class="[['color-warning','color-success','color-danger'][scope.row.status]]">{{ tagStatus[scope.row.status] }}</div>
				</template>
			</el-table-column>
			<el-table-column prop="number" label="标签反馈人数" min-width="60" align="center"></el-table-column>
			<el-table-column fixed="right" label="操作" min-width="100" align="center">
				<template slot-scope="scope">
					<el-button size="mini" type="primary" @click="tagVerify(scope.row.id, 1)" v-if="scope.row.status !== 1">采纳</el-button>
					<el-button size="mini" type="warning" @click="tagVerify(scope.row.id,2)" v-if="scope.row.status == 0">忽略</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=false layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

    </div>
</template>

<script>
import {
    seekLabelApplyPage,
	seekLabelApplyDelete,
	seekLabelApplyVerify
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				content: '',
				type: '',
				status: 0
            },

			tagType: ['我期望','我拒绝','我的优势'],
			tagStatus: ['新增','已采纳','已忽略'],

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			powerList: [],
			lastPayTime: [],
			expireTime: []
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getTagApplyList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.lastPayTime = []
			this.expireTime = []

			this.page = 1
			this.getTagApplyList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getTagApplyList()
		},
		changeClick(page) {
			this.page = page
			this.getTagApplyList()
		},
		//获取标签申请列表接口
		getTagApplyList() {
			seekLabelApplyPage({
				"current": this.page,
				"size": this.size,
				"content": this.query.content,
				"type": this.query.type,
				"status": this.query.status
			}).then((response) => {
				this.total = response.total
				this.tableData = response.records
			})
		},
		tagVerify(id, status) {
			const action = status == 1 ? '采纳' : '忽略'
			this.$confirm(`确定要${action}此标签`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
                closeOnClickModal: false
			}).then(() => {
				seekLabelApplyVerify({id, status}).then(() => {
					this.getTagApplyList()
				})
			})
		}
	},
	created() {
		//获取标签申请列表
		this.getTagApplyList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>

</style>