import {$get, $post, $put, $delete, post} from "./request";


/**菜单模块api*/

//查询菜单列表
export const menuList = params => $get('/api/menu', params);
//增加菜单
export const menuSave = params => $post('/api/menu', params);
//更新菜单
export const menuUpdate = params => $put(`/api/menu`, params);
//删除菜单
export const menuDelete = (params) => $delete(`/api/menu`, params);
//查询菜单树状列表
export const menuTreeList = params => $get('/api/menu/tree', params);
//查询用户菜单树
export const userMenuTree = params => $get('/api/menu/userTree', params);
//获取角色绑定的菜单
export const roleMenu = (roleId,params) => $get(`/api/menu/roleMenu/${roleId}`, params);
//给角色绑定菜单
export const roleBindMenu = params => $post('/api/menu/bind', params);



/**用户模块api*/

//登录
export const userLogin = params => post('/oauth/token', params);
//退出登录
export const userLogout = params => post('/oauth/logout', params);
//增加用户
export const userSave = params => $post('/api/user', params);
//删除用户
export const userDelete = params => $delete(`/api/user`, params);
//删除用户头像
export const userDeleteHead = (userId,params) => $post(`/api/user/delHead/${userId}`, params);
//修改用户
export const userUpdate = params => $put(`/api/user`, params);
//分页查询用户列表
export const userPage = (params, signal = '') => $get('/api/user', params, {isLoading: true, ignoreToast: false, signal});
//获取登录用户详情
export const userFindInfo = params => $get('/api/user/findInfo', params);
//通过用户id获取详情
export const userFindById = (userId,params) => $get(`/api/user/${userId}`, params);
//通过用户number获取详情
export const userInfoFindByNumber = (params) => $get(`/api/user/findByUserNumber`, params);
//通过用户id获取详情
export const userInfoFindById = (id) => $get(`/api/user/` + id);
// 删除用户签名
export const userDelSign = (id) => $post(`/api/user/delSign/` + id);
// 禁用用户功能
export const userBanPost = (params) => $post('/api/user/ban', params);


// 删除相册图片
export const albumDelete = (params) => $delete(`/api/album`, params);
// 删除用户音频
export const audioDelete = (id) => $post(`/api/user/delAudio/${id}`);
//K币充值
export const userRecharge = params => $post('/api/user/recharge', params);
// 获取用户注册渠道
export const getUserChannel = params => $get('/api/user/findChannel', params);



/**角色模块api*/

//获取角色列表
export const roleList = params => $get('/api/role/list', params, {isLoading: true, ignoreToast: true});
//获取角色分页列表
export const rolePage = params => $get('/api/role', params);
//增加角色
export const roleSave = params => $post('/api/role', params);
//修改角色
export const roleUpdate = params => $put(`/api/role`, params);
//删除角色
export const roleDelete = params => $delete(`/api/role`, params);
//查询下级角色列表
export const roleLowerList = params => $get('/api/role/lowerList', params);
//获取用户绑定的角色
export const userRole = (userId,params) => $get(`/api/role/userRole/${userId}`, params);
//给用户绑定角色
export const userBindRole = params => $post('/api/role/bind', params);



/**权限模块api*/

//查询权限分页列表
export const operationPage = params => $get('/api/operation', params);
//增加权限
export const operationSave = params => $post('/api/operation', params);
//修改权限
export const operationUpdate = params => $put(`/api/operation`, params);
//删除权限
export const operationDelete = params => $delete(`/api/operation`, params);
//查询权限树状列表
export const operationTreeList = params => $get('/api/operation/tree', params);
//获取角色绑定的权限
export const roleOperation = (roleId,params) => $get(`/api/operation/roleOperation/${roleId}`, params);
//给角色绑定权限
export const roleBindOperation = params => $post('/api/operation/bind', params);
//查询用户菜单权限树
export const menuOperationTree = (roleId,params) => $get(`api/operation/tree/${roleId}`, params);



/**字典模块api*/

//查询字典列表
export const dictList = params => $get('/api/dict/list', params);
//增加字典
export const dictSave = params => $post('/api/dict', params);
//修改字典
export const dictUpdate = params => $put(`/api/dict`, params);
//删除字典
export const dictDelete = params => $delete(`/api/dict`, params);
//查询字典树状列表
export const dictTreeList = params => $get('/api/dict/tree', params);
// 查询字典
export const dict = params => $get('/api/dict', params, {isLoading: true, ignoreToast: true});



/**地区模块api*/

//获取城市树结构
export const cityTree = params => $get('/api/area/cityTree', params);



/**实名认证模块api*/

//分页查询实名认证
export const realNamePage = params => $get('/api/realName', params);



/**动态模块api*/

//查询动态列表
export const articlePage = params => $get('/api/article', params);
//更新动态
export const articleUpdate = params => $put('/api/article', params);
//动态审核
export const articleCheck = params => $post('/api/article/check', params);
//动态收益审核
export const articleVerify = params => $post('/api/article/verify', params);
//删除动态
export const articleDelete = params => $delete('/api/article', params);
// 查询动态打赏记录
export const giftRewardPage = params => $get('/api/giftReward', params);

export const articleFindById = id => $get('/api/article/findById/' + id);



/**牵线模块api*/

//查询1对多牵线列表
export const pullWirePage = params => $get('/api/pullWire', params);
//编辑征友
export const pullWireUpdate = params => $put('/api/pullWire', params);
//1对多牵线审核
export const pullWireVerify = params => $post('/api/pullWire/verify', params);
//1对多牵线收益审核通过
export const pullWireIncomePass = (id,params) => $post(`/api/pullWire/incomePass/${id}`, params);
//1对多牵线收益审核拒绝
export const pullWireIncomeRefuse = (id,params) => $post(`/api/pullWire/incomeRefuse/${id}`, params);
//查询1对1牵线列表
export const addFriendPage = params => $get('/api/addFriend', params);
export const addFriendStatistics = params => $get('/api/addFriend/statistics', params);
//1对1牵线审核通过
export const addFriendPass = (id,params) => $post(`/api/addFriend/pass/${id}`, params);
//1对1牵线审核不通过
export const addFriendNoPass = (params) => $post(`/api/addFriend/noPass/`, params);
// export const addFriendNoPass = (params) => $post(`/api/addFriend/VerifyDto`, params);


/** 新版征友 */
export const seekFriendPage = (params) => $get(`/api/seekFriend`, params);    // 分页查询新征友
export const seekFriendStatistics = (params) => $get(`/api/seekFriend/statistics`, params);    // 查询新征友发布统计
export const seekFriendReject = (params) => $post(`/api/seekFriend/reject`, params);    // 驳回新征友
export const seekFriendDelete = (params) => $post(`/api/seekFriend/delete`, params);    // 删除新征友
export const getSeekFriendById = (id) => $get(`/api/seekFriend/${id}`);    // 查询新征友详情

export const seekFriendApplyPage = (params) => $get(`/api/seekFriendApply`, params);    // 分页查询新征友申请
export const seekFriendApplyStatistics = (params) => $get(`/api/seekFriendApply/statistics`, params);    // 查询新征友申请统计

export const seekLabelPage = (params) => $get(`/api/seekLabel`, params);    // 分页查询新征友标签
export const seekLabelPost = (params) => $post(`/api/seekLabel`, params);    // 新增新征友标签
export const seekLabelDelete = (params) => $delete(`/api/seekLabel`, params);    // 删除新征友标签

export const seekLabelApplyPage = (params) => $get(`/api/seekLabelApply`, params);  // 分页查询新征友标签申请
export const seekLabelApplyDelete = (params) => $delete(`/api/seekLabelApply`, params); // 删除新征友标签申请
export const seekLabelApplyVerify = (params) => $post(`/api/seekLabelApply/verify`, params);    // 审核标签申请




/**提现模块api*/

//查询提现列表
export const takeMoneyPage = params => $get('/api/takeMoney', params);
export const takeMoneyUpload = params => $post('/api/takeMoney/upload', params); // 提交电子回单
export const takeMoneyVerify = params => $post('/api/takeMoney/verify', params);



/**礼物模块api*/

//查询提现列表
export const giftList = params => $get('/api/gift', params);
//新增礼物
export const giftSave = params => $post('/api/gift', params);
//修改礼物
export const giftUpdate = params => $put('/api/gift', params);
//删除礼物
export const giftDelete = params => $delete('/api/gift', params);



/**产品模块api*/

//查询产品列表
export const productList = params => $get('/api/product', params);
//新增产品
export const productSave = params => $post('/api/product', params);
//修改产品
export const productUpdate = params => $put('/api/product', params);
//删除产品
export const productDelete = params => $delete('/api/product', params);



/**日志模块api*/

//分页查询日志
export const logPage = params => $get('/api/apiLog', params);
//删除日志
export const logDelete = params => $delete('/api/apiLog', params);



/**版本模块api*/

//分页查询版本
export const versionPage = params => $get('/api/version', params);
//新增版本
export const versionSave = params => $post('/api/version', params);
//修改版本
export const versionUpdate = params => $put('/api/version', params);
//删除版本
export const versionDelete = params => $delete('/api/version', params);



/**安全模块api*/

//分页查询评论
export const commentPage = params => $get('/api/comment', params);
//评论通过
export const commentAgree = (id,params) => $post(`/api/comment/agree/${id}`, params);
//删除评论
export const commentDelete = params => $delete('/api/comment', params);
//分页查询回复
export const replyPage = params => $get('/api/reply', params);
//回复通过
export const replyAgree = (id,params) => $post(`/api/reply/agree/${id}`, params);
//删除回复
export const replyDelete = params => $delete('/api/reply', params);
// 获取动态评论和回复
export const articleComment = params => $get('/api/comment/findByArticleId', params)

// 评论风险控制分页查询()
export const commentRefreshPage = params => $get('/api/commentRefresh', params)
// 评论重置
export const commentRefreshPost = params => $post('/api/commentRefresh', params)
// 查询风险控制的评论或回复列表
export const commentRefreshList = params => $get('/api/commentRefresh/comment', params)



/**问题反馈模块api*/

//分页查询问题反馈
export const questionPage = params => $get('/api/question', params);
//删除问题反馈
export const questionDelete = params => $delete('/api/question', params);



/**举报模块api*/

//分页查询举报
export const reportPage = params => $get('/api/report', params);
//删除举报
export const reportDelete = params => $delete('/api/report', params);
export const reportUpdate = params => $put('/api/report', params);



/**K币模块api*/

//分页查询K币充值列表
export const coinOrderPage = params => $get('/api/coinOrder', params);
//充值统计
export const coinOrderStatistics = params => $get('/api/coinOrder/statistics', params);



/**K币记录模块api*/

//分页查询K币充值列表
export const coinRecordPage = params => $get('/api/coinRecord', params);



/**收益记录模块api*/

//分页查询收益记录列表
export const balancePage = params => $get('/api/balance', params);
// 收益记录统计
export const balanceStatistics = params => $get('/api/balance/statistics', params);
// 获取收益详情
export const balanceDetail = id => $get('/api/balance/' + id);



/**轮播图模块api*/

//分页查询banner列表
export const bannerPage = params => $get('/api/banner', params);
//新增轮播图
export const bannerSave = params => $post('/api/banner', params);
//更新轮播图
export const bannerUpdate = params => $put('/api/banner', params);
//删除轮播图
export const bannerDelete = params => $delete('/api/banner', params);



/**打卡小店模块api*/

//分页查询小店列表
export const smallStorePage = params => $get('/api/smallStore', params);
//添加小店
export const smallStoreSave = params => $post('/api/smallStore', params);
//删除小店
export const smallStoreDelete = params => $delete('/api/smallStore', params);



/**教程分类模块api*/

//查询所有教程分类
export const courseCategoryList = params => $get('/api/courseCategory', params);
//新增教程分类
export const courseCategorySave = params => $post('/api/courseCategory', params);
//删除教程分类
export const courseCategoryDelete = params => $delete('/api/courseCategory', params);



/**教程模块api*/

//分页查询教程
export const coursePage = params => $get('/api/course', params);
//新增教程
export const courseSave = params => $post('/api/course', params);
//删除教程
export const courseDelete = params => $delete('/api/course', params);



/**男士模块api*/

//分页查询男士
export const goldMasterPage = params => $get('/api/goldMaster', params);
//新增男士
export const goldMasterSave = params => $post('/api/goldMaster', params);
//修改男士
export const goldMasterUpdate = params => $put('/api/goldMaster', params);
//审核男士
export const goldMasterVerify = params => $post('/api/goldMaster/verify', params);
//删除男士
export const goldMasterDelete = (id,params) => $delete(`/api/goldMaster/${id}`, params);



/**美女模块api*/

//分页查询美女
export const beautyGirlPage = params => $get('/api/beautyGirl', params);
//新增美女
export const beautyGirlSave = params => $post('/api/beautyGirl', params);
//修改美女
export const beautyGirlUpdate = params => $put('/api/beautyGirl', params);
//审核美女
export const beautyGirlVerify = params => $post('/api/beautyGirl/verify', params);
//删除美女
export const beautyGirlDelete = (id,params) => $delete(`/api/beautyGirl/${id}`, params);
//订制成功
export const beautyGirlSuccess = params => $post('/api/beautyGirl/success', params);



/**私人订制申请模块api*/

//分页查询申请列表
export const customApplyPage = params => $get('/api/customApply', params);
//修改申请
export const customApplyUpdate = params => $put('/api/customApply', params);
//删除申请
export const customApplyDelete = params => $delete('/api/customApply', params);



/**私人订制订制成功模块api*/

//分页查询订制成功列表
export const customSuccessPage = params => $get('/api/customSuccess', params);


// 分页查询订制意向列表
export const customIntentionPage = params => $get('/api/customIntention', params);



/**推广模块api*/

//分页查询每日数据
export const everydayPage = params => $get('/api/promotion/everyday', params);
//查询渠道数据列表
export const channelList = params => $get('/api/promotion', params);



/**数据统计模块api*/
export const statisticsPage = params => $get('/api/statistics', params);

// 新增注册用户数据列表
export const userStatisticsPage = params => $get('/api/user/statistics', params);


/**违规用户内容*/
export const userFoulPage = params => $get('/api/userFoul', params);

export const userFoulDelete = params => $delete('/api/userFoul', params);



/**活动模块*/

//分页查询活动规则
export const activityRulePage = params => $get('/api/activityRule', params);
//根据业务ID获取活动规则
export const getActivityRuleById = params => $get('/api/activityRule/findById', params);
//新增活动规则
export const activityRuleSave = params => $post('/api/activityRule', params);
//修改规则
export const activityRuleUpdate = params => $put('/api/activityRule', params);
//删除规则
export const activityRuleDelete = params => $delete('/api/activityRule', params);

//分页查询参与动态激励活动的动态
export const articleActivityPage = params => $get('/api/article/activityPage', params);
//分页查询参与动态激励活动的评论
export const commentActivityPage = params => $get('/api/comment/activityPage', params);
//分页查询参与动态激励活动的回复
export const replyActivityPage = params => $get('/api/reply/activityPage', params);
//活动审核
export const activityRuleCheck = params => $post('/api/activityRule/check', params);


// 客服回复配置
export const getCustomerReply = params => $get('/api/customerReply', params);
export const putCustomerReply = params => $put('/api/customerReply', params);
export const postCustomerReply = params => $post('/api/customerReply', params);

// 批量发送消息
export const postCustomerMsg = params => $post('/api/customer', params);    // 批量发送消息
export const getMsgUserNumber = params => $get('/api/customer/number', params);    // 查询本次发送消息会有多少用户接收
export const sendMessageRecordPage = params => $get('/api/sendMessageRecord', params);  //  分页查询发送消息记录
export const sendMessageRecordDelete = params => $delete('/api/sendMessageRecord', params); //  删除发送消息记录
export const sendMessageConditionPage = params => $get('/api/sendMessageCondition', params);    //  分页查询发送消息条件记录
export const sendMessageConditionDelete = params => $delete('/api/sendMessageCondition', params);   //  删除发送消息条件记录
export const sendMessageUserPage = params => $get('/api/sendMessageUser', params);  //  分页查询发送消息指定用户记录
export const sendMessageUserDelete = params => $delete('/api/sendMessageUser', params);     //  删除发送消息指定用户记录



// 上传文件
export const fileUpload = params => $post('/api/file/upload', params, {headers: {'Content-Type': 'multipart/form-data'}});
// 上传表情图片
export const emoteUpload = params => $post('/api/file/emote', params);


/**表情管理*/
export const sysEmotePage = params => $get('/api/sysEmote', params);
export const sysEmotePost = params => $post('/api/sysEmote', params);
export const sysEmoteUpdate = params => $put('/api/sysEmote', params);
export const sysEmoteDelete = params => $delete('/api/sysEmote', params);




/**退款记录模块api*/

//分页查询退款记录
export const refundRecordPage = params => $get('/api/refundRecord', params);
//查询渠道数据列表
export const refundRecordDelete = params => $delete('/api/refundRecord', params);



/**封号申诉管理api */

// 分页查询封号申诉
export const applyUnsealPage = params => $get('/api/applyUnseal', params);
// 解封
export const applyUnsealPost = params => $post('/api/applyUnseal', params);
// 删除封号申诉
export const applyUnsealDelete = params => $delete('/api/applyUnseal', params);


// app登录记录
export const loginRecordPage = (params, signal = '') => $get('/api/loginRecord', params, {isLoading: true, ignoreToast: false, signal});
export const loginRecordDelete = params => $delete('/api/loginRecord', params);
export const loginRecordStatistics = params => $get('/api/loginRecord/statistics', params);
export const loginRecordByUser = params => $get('/api/loginRecord/findByUser', params);




/**删除配置 */
export const deleteConfigPage = params => $get('/api/deleteConfig', params);
export const deleteConfigPost = params => $post('/api/deleteConfig', params);
export const deleteConfigUpdate = params => $put('/api/deleteConfig', params);
export const deleteConfigDelete = params => $delete('/api/deleteConfig', params);
export const deleteConfigClean = params => $put('/api/deleteConfig/clean', params);


// 贵族列表
export const vipPage = params => $get('/api/vip', params);
export const vipPost = params => $post('/api/vip', params);
export const vipUpdate = params => $put('/api/vip', params);
export const vipDelete = params => $delete('/api/vip', params);
export const vipFindById = id => $get('/api/vip/' + id);
export const vipGivePage = params => $get('/api/vipGive', params);
export const giveVip = params => $post('/api/vip/give', params);

// 贵族订单
export const vipOrderPage = params => $get('/api/vipOrder', params);
export const vipOrderPost = params => $post('/api/vipOrder', params);
export const vipOrderUpdate = params => $put('/api/vipOrder', params);
export const vipOrderDelete = params => $delete('/api/vipOrder', params);
export const vipOrderFindById = id => $get('/api/vipOrder/' + id);
export const vipOrderStatistics = params => $get('/api/vipOrder/statistics', params);

// 贵族价格
export const vipPricePage = params => $get('/api/vipPrice', params);
export const vipPricePost = params => $post('/api/vipPrice', params);
export const vipPriceUpdate = params => $put('/api/vipPrice', params);
export const vipPriceDelete = params => $delete('/api/vipPrice', params);

// 贵族权限
export const vipPowerPage = params => $get('/api/vipPower', params);
export const vipPowerPost = params => $post('/api/vipPower', params);
export const vipPowerUpdate = params => $put('/api/vipPower', params);
export const vipPowerNumberUpdate = params => $put('/api/vipPower/updateNumber', params);

// 优质靓号
export const highCornetPage = params => $get('/api/highCornet', params);
export const highCornetPost = params => $post('/api/highCornet', params);
export const highCornetUpdate = params => $put('/api/highCornet', params);
export const highCornetDelete = params => $delete('/api/highCornet', params);

// 分页查询分享收入
export const shareMoneyPage = params => $get('/api/shareMoney', params);
export const shareMoneyStatistics = params => $get('/api/shareMoney/statistics', params);
// 分页查询用户关系
export const userRelationPage = params => $get('/api/userRelation', params);
// 分页查询分享行为
export const shareRecordPage = params => $get('/api/shareRecord', params);
// 分享行为数据统计
export const shareRecordStatistics = params => $get('/api/shareRecord/statistics', params);

// 分享配置
export const shareConfigPage = params => $get('/api/shareConfig', params);
export const shareConfigPost = params => $post('/api/shareConfig', params);
export const shareConfigUpdate = params => $put('/api/shareConfig', params);
export const shareConfigDelete = params => $delete('/api/shareConfig', params);
export const shareConfigCommon = params => $get('/api/shareConfig/common', params);


// 公告配置
export const sysBulletinPage = params => $get('/api/sysBulletin', params);
export const sysBulletinPost = params => $post('/api/sysBulletin', params);
export const sysBulletinUpdate = params => $put('/api/sysBulletin', params);
export const sysBulletinDelete = params => $delete('/api/sysBulletin', params);


// 拉黑设备
export const deviceBlackPage = params => $get('/api/deviceBlack', params);
export const deviceBlackPost = params => $post('/api/deviceBlack', params);
export const deviceBlackDelete = params => $delete('/api/deviceBlack', params);


// 匹配打招呼
export const matchChatPage = params => $get('/api/matchChat', params);
export const matchChatPost = params => $post('/api/matchChat', params);
export const matchChatUpdate = params => $put('/api/matchChat', params);
export const matchChatDelete = params => $delete('/api/matchChat', params);

// 匹配产品(匹配卡和同城卡)
export const matchProductPage = params => $get('/api/matchProduct', params);
export const matchProductPost = params => $post('/api/matchProduct', params);
export const matchProductUpdate = params => $put('/api/matchProduct', params);
export const matchProductDelete = params => $delete('/api/matchProduct', params);

// 匹配记录
export const matchFriendPage = params => $get('/api/matchFriend', params);
export const matchFriendStatistics = params => $get('/api/matchFriend/statistics', params);

// 匹配道具使用记录
export const matchProductRecordPage = params => $get('/api/matchProductRecord', params);

// 禁用匹配功能
export const findMatchBanInfo = params => $get('/api/matchBan', params);
export const matchBan = params => $post('/api/matchBan', params);


// 聊天群组
export const teamCategoryPage = params => $get('/api/teamCategory', params);
export const teamCategoryPost = params => $post('/api/teamCategory', params);
export const teamCategoryUpdate = params => $put('/api/teamCategory', params);
export const teamCategoryDelete = params => $delete('/api/teamCategory', params);
// 聊天群
export const teamChatPage = params => $get('/api/teamChat', params);
export const teamChatPost = params => $post('/api/teamChat', params);
export const teamChatUpdate = params => $put('/api/teamChat', params);
export const teamChatDelete = id => $delete('/api/teamChat/' + id);
export const teamChatDetail = tid => $get('/api/teamChat/' + tid);
export const teamChatDetailById = id => $get('/api/teamChat/findById/' + id);
// 群管理相关
export const getTeamMember = params => $get('/api/teamMember', params); // 获取群成员列表
export const kickTeamChat = params => $post('/api/teamChat/kick', params);  // 踢出群聊
export const inviteTeamChat = params => $post('/api/teamChat/join', params);  // 拉人进群
export const disbandTeamChat = id => $delete('/api/teamChat/' + id);  // 解散群聊
export const muteTeamChat = params => $post('/api/teamChat/mute', params);  // 禁言群组
export const muteUser = params => $post('/api/teamChat/muteUser', params);  // 禁言成员
export const transferTeamChat = params => $post('/api/teamChat/change', params);  // 转让群主


export const getTalentPage = params => $get('/api/talent', params);  // 获取达人列表
export const updateTalent = params => $put('/api/talent', params);  // 修改达人
export const deleteTalent = id => $delete('/api/talent?id=' + id);  // 删除(下架)达人
export const getTalentApplyPage = params => $get('/api/talent/apply', params);  // 获取达人申请列表
export const getTalentApplyRefuse = id => $get('/api/talent/refuse/' + id);  // 获取达人申请驳回原因
export const getTalentUpdatePage = params => $get('/api/talent/updatePage', params);  // 获取达人资料修改列表
export const checkTalent = params => $post('/api/talent/check', params);  // 审核达人资料更新
export const verifyTalent = params => $post('/api/talent/verify', params);  // 审核达人申请
export const talentLabelPage = params => $get('/api/talentLabel', params);  // 达人标签列表
export const talentLabelPost = params => $post('/api/talentLabel', params);  // 添加达人标签
export const talentLabelUpdate = params => $put('/api/talentLabel', params);  // 修改达人标签
export const talentLabelDelete = params => $delete('/api/talentLabel', params);  // 删除达人标签

export const talentCategoryPage = params => $get('/api/talentCategory', params);  // 达人分类列表
export const talentCategoryPost = params => $post('/api/talentCategory', params);  // 添加达人分类
export const talentCategoryUpdate = params => $put('/api/talentCategory', params);  // 修改达人分类
export const talentCategoryDelete = params => $delete('/api/talentCategory', params);  // 删除达人分类

export const getTalentReportPage = params => $get('/api/talent/report', params);  // 达人投诉记录列表
export const getTalentCallPage = params => $get('/api/talent/call', params);  // 达人连麦记录列表
export const getTalentLabelApplyPage = params => $get('/api/talentLabelApply', params);  // 达人标签申请列表
export const verifyTalentLabelApply = params => $post('/api/talentLabelApply/verify', params);  // 审核达人标签申请