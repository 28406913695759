<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
			<el-form :inline="true" class="demo-form-inline" size="mini">
				
				<el-form-item>
					<el-button type="primary" @click="showForm('condition')">按条件发送</el-button>
					<el-button type="primary" @click="showForm('user')">按ID发送</el-button>
					<el-button type="primary" @click="refresh">刷新</el-button>
				</el-form-item>
			</el-form>
		</div>
        <div>
            <div class="table-title">条件发送记录</div>
            <el-table :max-height="330" :data="conditionList" border style="width: 100%" :cell-style="{ padding: '0' }"
                :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                <el-table-column prop="gender" label="用户性别" min-width="60" align="center">
                    <template slot-scope="scope">
                        <span :class="rowColorClass(scope.row.gender)">{{ ['全部','男','女'][scope.row.gender] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="personality" label="社交人格" min-width="80" align="center">
                    <template slot-scope="scope">
                        <span :class="rowColorClass(scope.row.personality)">{{ rowGetPersonality(scope.row.personality) || '全部' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="age" label="年龄" min-width="60" align="center">
                    <template slot-scope="scope">
                        <span :class="rowColorClass(scope.row.minAge || scope.row.maxAge)">
                            {{ scope.row.minAge || scope.row.maxAge ? scope.row.minAge + ' - ' + scope.row.maxAge : '不限' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="isRealName" label="实名状态" min-width="60" align="center">
                    <template slot-scope="scope">
                        <span :class="rowColorClass(scope.row.isRealName)">
                            {{ ['未实名','已实名'][scope.row.isRealName] || '全部' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="vipLevel" label="贵族状态" min-width="60" align="center">
                    <template slot-scope="scope">
                        <span :class="rowColorClass('isVip' in scope.row)">
                            {{ scope.row.isVip === 0 ? '非贵族' : rowGetVip(scope.row.vipLevel) || '全部' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="loginTime" label="登录时间" min-width="110" align="center">
                    <template slot-scope="scope">
                        <div :class="rowColorClass(scope.row.startTime || scope.row.endTime)">
                            <template v-if="scope.row.startTime || scope.row.endTime">
                                <span v-if="scope.row.startTime">{{ scope.row.startTime }}</span>
                                <div v-if="scope.row.startTime && scope.row.endTime">-</div>
                                <span v-if="scope.row.endTime">{{ scope.row.endTime }}</span>
                            </template>
                            <template v-else>不限</template>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="areaName" label="IP地址" min-width="60" align="center">
                    <template slot-scope="scope">
                        <span :class="rowColorClass(scope.row.areaName)">{{ scope.row.areaName || '不限' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="isPush" label="APP推送开启状态" min-width="60" align="center">
                    <template slot-scope="scope">
                        <span :class="rowColorClass(scope.row.isPush)">{{ ['未开启','开启'][scope.row.isPush] || '全部' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="platform" label="登录来源" min-width="60" align="center">
                    <template slot-scope="scope">
                        <span :class="rowColorClass(scope.row.platform)">{{ scope.row.platform || '全部' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="text" label="消息内容" min-width="120" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.text }}</div>
                        <div style="width: 100px;height: 100px;margin: 0 auto;" v-if="scope.row.image || scope.row.video">
                            <video style="width: 100%;height: 100%;" controls muted :src="fileDomain + scope.row.video" :type="'video/' + fileSuffix" v-if="scope.row.video"></video>
                            <el-image style="width: 100%;height: 100%;margin: 0 auto;" :src="fileDomain + scope.row.image" :previewSrcList="[fileDomain + scope.row.image]" v-else-if="scope.row.image"  />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="userTotalNumber" label="发送人数" min-width="60" align="center"></el-table-column>
                <el-table-column prop="readTotalNumber" label="消息查看人数" min-width="70" align="center"></el-table-column>
                
                <el-table-column prop="createTime" label="发送时间" min-width="100" align="center"></el-table-column>
                <el-table-column prop="remark" label="备注" min-width="100" align="center"></el-table-column>
    
                <el-table-column fixed="right" label="操作" min-width="60" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showForm('condition', scope.row)">再次发送</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align:right;">
                <el-pagination
                    style="margin-top: 10px;"
                    background
                    layout="total, prev, pager, next, sizes"
                    :current-page="conditionPage"
                    :page-size="conditionSize"
                    :total="conditionTotal"
                    :page-sizes="[10, 20, 50, 100]"
                    @size-change="(size) => conditionSize = size"
                    @current-change="(page) => conditionPage = page"
                />
            </div>
        </div>
        <div>
            <div class="table-title">ID发送记录</div>
            <el-table :max-height="330" :data="userRecordList" border style="width: 100%" :cell-style="{ padding: '0' }"
                :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                <el-table-column prop="users" label="用户ID" min-width="150" align="center"></el-table-column>
                <el-table-column prop="remark" label="备注" min-width="100" align="center"></el-table-column>
                <el-table-column prop="userTotalNumber" label="发送人数" min-width="60" align="center"></el-table-column>
                <el-table-column prop="readTotalNumber" label="消息查看人数" min-width="70" align="center"></el-table-column>
                
                <el-table-column prop="text" label="消息内容" min-width="120" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.text }}</div>
                        <div style="width: 100px;height: 100px;margin: 0 auto;" v-if="scope.row.image || scope.row.video">
                            <video style="width: 100%;height: 100%;" controls muted :src="fileDomain + scope.row.video" :type="'video/' + fileSuffix" v-if="scope.row.video"></video>
                            <el-image style="width: 100%;height: 100%;" :src="fileDomain + scope.row.image" :previewSrcList="[fileDomain + scope.row.image]" v-else-if="scope.row.image"  />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="发送时间" min-width="100" align="center"></el-table-column>
    
                <el-table-column fixed="right" label="操作" min-width="60" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showForm('user', scope.row)">再次发送</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align:right;">
                <el-pagination
                    style="margin-top: 10px;"
                    background
                    layout="total, prev, pager, next, sizes"
                    :current-page="userRecordPage"
                    :page-size="userRecordSize"
                    :total="userRecordTotal"
                    :page-sizes="[10, 20, 50, 100]"
                    @size-change="(size) => userRecordSize = size"
                    @current-change="(page) => userRecordPage = page"
                />
            </div>
        </div>

        <el-dialog width="900px" v-loading="uploadLoading" class="text-left" :visible.sync="formVisible" :close-on-click-modal="false">
            <div class="form-item">
                <div class="form-title">请输入消息内容</div>
                <el-input v-model="form.text" type="textarea" :rows="4" placeholder="请输入消息内容"></el-input>
                <div class="file-box" v-if="form.image || form.video">
                    <div class="file">
                        <video style="width: 100%;height: 100%;" controls muted :src="fileDomain + form.video" :type="'video/' + fileSuffix" v-if="form.video"></video>
                        <el-image style="width: 100%;height: 100%;" :src="fileDomain + form.image" :previewSrcList="[fileDomain + form.image]" v-else-if="form.image"  />
                    </div>
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteFile">删除</el-button>
                </div>
                <el-button type="text" @click="chooseFile">上传图片/视频消息</el-button>
            </div>
            <div>
                <template v-if="formType === 'condition'">
                    <div class="form-title">请选择消息接收人</div>
                    <el-form ref="form" :model="form" label-width="150px" size="mini">
                        <el-form-item label="用户性别" prop="gender">
                            <el-select v-model="form.gender" placeholder="请选择用户性别">
                                <el-option label="全部" value="" />
                                <el-option label="男" value="1" />
                                <el-option label="女" value="2" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="社交人格" prop="personalityList">
                            <div class="form-vip-row">
                                <div class="vip-radio-box">
                                    <el-radio-group v-model="form.personality">
                                        <el-radio label="">全部</el-radio>
                                        <el-radio label="1">选择</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="vip-checkbox" @click="form.personality = '1'">
                                    <el-checkbox-group v-model="form.personalityList">
                                        <el-checkbox :label="item.value" v-for="item in personalityList" :key="item.value" >{{item.label}}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="年龄范围" prop="age">
                            <el-radio-group v-model="form.ageType">
                                <el-radio label="">不限</el-radio>
                                <el-radio label="1">
                                    范围
                                    <span @click="form.ageType = '1'">
                                        <el-input v-model="form.minAge" placeholder="输入起始年龄" style="width: 110px;" />
                                        -
                                        <el-input v-model="form.maxAge" placeholder="输入终止年龄" style="width: 110px;" />
                                    </span>
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="实名状态" prop="isRealName">
                            <el-select v-model="form.isRealName" placeholder="请选择实名状态">
                                <el-option label="全部" value="" />
                                <el-option label="是" value="1" />
                                <el-option label="否" value="0" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="贵族状态" prop="isVip">
                            <div class="form-vip-row">
                                <div class="vip-radio-box">
                                    <el-radio-group v-model="form.isVip">
                                        <el-radio label="">全部</el-radio>
                                        <el-radio label="0">非贵族</el-radio>
                                        <el-radio label="1">贵族</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="vip-checkbox" @click="form.isVip = '1'">
                                    <el-checkbox-group v-model="form.vipLevel">
                                        <el-checkbox :label="item.id" v-for="item in vipList" :key="item.id" >{{item.name}}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="最新登录时间" prop="loginTime">
                            <el-radio-group v-model="form.loginTime">
                                <el-radio label="">不限</el-radio>
                                <el-radio label="1">
                                    范围
                                    <span @click="form.loginTime = '1'">
                                        <el-date-picker v-model="form.loginDate" type="datetimerange" align="right" unlink-panels range-separator="至"
                                            start-placeholder="选择起始时间" end-placeholder="选择终止时间" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" style="width: 340px;">
                                        </el-date-picker>
                                    </span>
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="IP地址" prop="areaCode">
                            <el-cascader v-model="form.areaCode" :options="cityList" :props="{value:'areaCode', label:'areaName', children: 'childList', checkStrictly: true, multiple: false, emitPath: false}" placeholder="省份或城市" clearable />
                            &nbsp;<span style="color:red;">若是不选择，则为全部地区</span>
                        </el-form-item>
                        <el-form-item label="APP推送开启状态" prop="isPush">
                            <el-select v-model="form.isPush">
                                <el-option label="全部" value="" />
                                <el-option label="开启" value="1" />
                                <el-option label="关闭" value="0" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="最新登录来源" prop="platform">
                            <el-select v-model="form.platform">
                                <el-option label="全部" value="" />
                                <el-option label="ios" value="ios" />
                                <el-option label="android" value="android" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="运营备注" prop="remark">
                            <el-input v-model="form.remark" placeholder="请输入备注内容，可不填" />
                        </el-form-item>
                    </el-form>
                </template>
                <template v-else-if="formType === 'user'">
                    <div class="form-title">请设置消息接收人的用户ID</div>
                    <el-input v-model="form.receiveUserNumberList" type="textarea" :rows="4" placeholder="请输入消息接收人的ID，以逗号隔开。例：12345678,25635241,36530234" />
                    <el-form style="margin-top: 20px;" label-width="80px" size="mini">
                        <el-form-item label="运营备注" prop="remark">
                            <el-input v-model="form.remark" placeholder="请输入备注内容，可不填" />
                        </el-form-item>
                    </el-form>
                </template>
            </div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" :loading="submitLoading" @click="submitForm">确 定</el-button>
			</div>
		</el-dialog>

        <!-- el-upload仅作为上传图片的控件，不进行组件内容展示 -->
        <el-upload
            ref="upload"
            action="api/file/upload"
            :accept="uploadAccept"
            :limit="1"
            :headers="headers"
            :data="uploadData"
            :show-file-list="false"
            :on-success="uploadSuccess"
            :before-upload="() => uploadLoading = true"
        />
    </div>
</template>

<script>
import {
    sendMessageConditionPage,
    sendMessageUserPage,
    postCustomerMsg,
    getMsgUserNumber,
    vipPowerPage,
    cityTree,
} from "@/api/api";
export default {
    data() {
        return {
            
            conditionList: [],
            conditionPage: 1,
			conditionSize: 10,
			conditionTotal: 0,
            
            userRecordList: [],
            userRecordPage: 1,
			userRecordSize: 10,
			userRecordTotal: 0,

            personalityList: [
                { label: '支配者', value: 1},
                { label: '臣服者', value: 2},
                { label: '双向者', value: 3},
                { label: '未明确', value: 0}
            ],

            ageDate: [],
            vipLevel: [],
            vipList: [],

            cityList: [],

            headers: {
                Authorization: "Bearer " + localStorage.token
            },
            uploadData: {
                code: 5001,
                index: 0
            },
            imgAccept: ['jpg','jpeg','png','pneg','gif','JPG','JPEG','PNG','PNEG','GIF'],
            videoAccept: ['mp4','avi','mov','wmv','flv','mpeg','MP4','AVI','MOV','WMV','FLV','MPEG'],
            uploadLoading: false,

			fileDomain: 'https://file.oiki.cc/',

			headers: {
                Authorization: "Bearer " + localStorage.token
            },

			formVisible: false,
            formType: '',
			form: {
                text: '',
                image: '',
                video: '',
                gender: '',
                personality: '',
                personalityList: [],
                ageType: '',
                minAge: '',
                maxAge: '',
                isRealName: '',
                isVip: '',
                vipLevel: [],
                loginTime: '',
                loginDate: [],
                areaCode: '',
                isPush: '',
                platform: '',

                receiveUserNumberList: '',

                remark: '',
            },

			submitLoading: false
        }
    },
    computed: {
        uploadAccept() {
            return [...this.imgAccept, ...this.videoAccept].map(accept => '.' + accept).join(',')
        },
        fileSuffix() {
            if (this.form.file) {
                const ary = this.form.file.split('.')
                return ary[ary.length - 1]
            }
            return undefined
        },
    },
    watch: {
        conditionPage() {
            this.getConditionList()
        },
        conditionSize() {
            this.getConditionList()
        },
        userRecordPage() {
            this.getUserRecordList()
        },
        userRecordSize() {
            this.getUserRecordList()
        },
    },
    methods: {
        refresh() {
            this.conditionPage = 1
            this.getConditionList()

            this.userRecordPage = 1
            this.getUserRecordList()
        },
        getConditionList() {
            const params = {
				current: this.conditionPage,
				size: this.conditionSize,
			}

            sendMessageConditionPage(params).then(res => {
                function getDateString(dateStr) {
                    const date = new Date(dateStr)
                    const year = date.getFullYear()
                    const month = (date.getMonth() + 1).toString().padStart(2, '0')
                    const day = date.getDate().toString().padStart(2, '0')
                    const hour = date.getHours().toString().padStart(2, '0')
                    const minutes = date.getMinutes().toString().padStart(2, '0')
                    const seconds = date.getSeconds().toString().padStart(2, '0')
                    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
                }

                this.conditionList = res.records.map(item => {
                    if (item.vipLevel) item.vipLevel = JSON.parse(item.vipLevel)
                    if (item.startTime) item.startTime = getDateString(item.startTime)
                    if (item.endTime) item.endTime = getDateString(item.endTime)
                    return item
                })
                this.conditionTotal = res.total
            })
        },
        getUserRecordList() {
            const params = {
                current: this.userRecordPage,
				size: this.userRecordSize,
			}
            
            sendMessageUserPage(params).then(res => {
                this.userRecordList = res.records.map(item => {
                    item.users = JSON.parse(item.target).join(',')
                    return item
                })
                this.userRecordTotal = res.total
            })
        },
        rowColorClass(isColor) {
            return isColor ? 'color-warning' : 'color-info'
        },
        rowGetPersonality(personalityAry) {
            if (!personalityAry) return ''
            const ary = JSON.parse(personalityAry)
            return ary.map(val => this.personalityList.find(item => item.value === val).label).join(',')
        },
        rowGetVip(vipLevel) {
            if (!vipLevel) return ''
            const vipAry = vipLevel/* .split(',') */
            return vipAry.map(level => {
                const vip = this.vipList.find(item => level === item.level)
                return vip ? vip.name : undefined
            }).join(',')
        },
        initForm(form = {}) {
            console.log(form,'form')
            for (const key in form) {
                switch(key) {
                    case 'gender':
                        form[key] = form[key] == 0 ? '' : form[key].toString()
                    break;
                    case 'personality':
                        form.personalityList = JSON.parse(form[key])
                        form[key] = '1'
                    break;
                    case 'areaCode':
                        if (typeof form[key] === 'string') form[key] = parseInt(form[key])
                        else if (Array.isArray(form[key])) [form[key]] = form[key]
                    break;
                    case 'users':
                        form.receiveUserNumberList = form[key]
                    break;
                    default:
                        if (typeof form[key] === 'number') form[key] = form[key].toString()
                    break;
                }
            }
            if (form.startTime && form.endTime) {
                form.loginTime = '1'
                form.loginDate = [form.startTime, form.endTime]
            }

            for (const key in this.form) {
                switch(key) {
                    case 'personalityList':
                    case 'vipLevel':
                    case 'loginDate':
                        this.form[key] = form[key] ?? []
                    break;
                    default:
                        this.form[key] = form[key] ?? ''
                    break;
                }
            }

            if ('maxAge' in form || 'minAge' in form) this.form.ageType = '1'

            console.log(this.form,'this.form')
        },
		showForm(type, form = {}) {
			this.initForm({...form})
            this.formType = type
			this.formVisible = true
		},
		// 选择文件
        chooseFile() {
            const uploadDom = this.$refs.upload
            const inputDom = uploadDom.$refs['upload-inner'].$refs.input
            inputDom.click()
        },
		// 文件上传成功
        uploadSuccess(response, file) {
            console.log(response,'response')
            const filePath = response.data

            const suffixAry = filePath.split('.')
            if (this.imgAccept.indexOf(suffixAry[suffixAry.length - 1]) >= 0) this.form.image = filePath
            else if (this.videoAccept.indexOf(suffixAry[suffixAry.length - 1]) >= 0) this.form.video = filePath
            this.uploadLoading = false
            
            // 上传成功等待渲染变化之后清除组件中记录的文件列表
            this.$nextTick(() => {
                this.$refs.upload.clearFiles()
            })
        },
        deleteFile() {
            this.form.image = ''
            this.form.video = ''
        },
		async submitForm() {
            if (!this.form.text && !this.form.image && !this.form.video) {
                this.$message.error('请输入消息内容！')
                return;
            }

			if (this.formType === 'condition') {
                if (this.form.personality == '1' && !this.form.personalityList.length) {
                    this.$message.error('请勾选社交人格！')
                    return;
                } else if (this.form.ageType == '1' && (!this.form.minAge || !this.form.maxAge) || (this.form.minAge > this.form.maxAge)) {
                    this.$message.error('请正确输入年轻范围！')
                    return;
                } else if (this.form.isVip == '1' && !this.form.vipLevel.length) {
                    this.$message.error('请勾选贵族！')
                    return;
                } else if (this.form.loginTime == '1' && (!this.form.loginDate || this.form.loginDate.length < 2)) {
                    this.$message.error('请选择时间范围！')
                    return;
                }
            } else if (this.formType === 'user') {
                if (!this.form.receiveUserNumberList) {
                    this.$message.error('请输入消息接收人的用户ID！')
                    return;
                }
            }

            const ignoreField = ['ageType','personality','loginTime']
            const form = {...this.form}
            const formData = {}
            for (const key in form) {
                if (!form[key] || ignoreField.indexOf(key) >= 0) {
                    continue;
                }
                switch(key) {
                    case 'personalityList':
                        if (form[key].length && form[key].length < this.personalityList.length) formData[key] = form[key]
                    break;
                    case 'vipLevel':
                        if (form[key].length && form[key].length < this.vipList.length) formData[key] = form[key]
                    break;
                    case 'loginDate':
                        if (form[key] && form[key].length === 2) [formData.startTime, formData.endTime] = form[key]
                    break;
                    case 'receiveUserNumberList':
                        if (this.formType === 'user') formData[key] = form[key].replace(/，/g,',').replace(/\s/g,'').split(',').map(val => parseInt(val))
                    break;
                    default:
                        formData[key] = form[key]
                    break;
                }
            }

            console.log(formData,'formData')
            // return;

            const checkFormData = {}
            Object.keys(formData).forEach(key => {
                const value = formData[key]
                if (Array.isArray(value)) checkFormData[key] = value.join(',')
                else if (typeof value === 'number') checkFormData[key] = value.toString()
                else checkFormData[key] = value
            })
            // console.log(checkFormData,'checkFormData')
            // return;
            if (this.formType === 'condition') {
                await new Promise((resolve) => {
                    getMsgUserNumber(checkFormData).then(number => {
                        this.$confirm(`本次消息将会发送给${number}名用户`, '', {
                            confirmButtonText: '确认发送',  
                        }).then(() => {
                            resolve()
                        })
                    })
                })
            }

            this.submitLoading = true
            postCustomerMsg(formData).then(() => {
                if (this.formType === 'condition') {
                    this.getConditionList()
                } else {
                    this.getUserRecordList()
                }
                this.formVisible = false
            }).finally(() => {
                this.submitLoading = false
            })
		},
        getVipList() {
            vipPowerPage().then(res => {
                this.vipList = res
            })
        },
        getCityList() {
            cityTree().then(res => {
                this.cityList = res
            })
        }
    },
    created() {
		this.refresh()

        this.getVipList()
        this.getCityList()
        
	}
}
</script>

<style scoped>
.text-left {
    text-align: left;
}

.file-box {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.file-box .file {
    width: 120px;
    height: 120px;
    margin-right: 10px;
}

.form-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
}

.form-vip-row {
    display: flex;
    text-align: left;
}
.form-vip-row .vip-radio-box {
    margin-right: 10px;
    flex-shrink: 0;
}

.form-vip-row .vip-check-box {
    flex-grow: 1;
}

.upload-box {
	width: 80px;
	height: 80px;
	border: 1px dashed #ddd;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	overflow: hidden
}

.upload-box img {
	width: 100%;
	height: 100%;
}

.upload-icon {
	font-size: 38px;
}

.table-title {
    font-weight: bold;
    font-size: 20px;
    text-align: left;
}
</style>