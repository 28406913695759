import axios from 'axios' //先安装插件 npm install axios --save
import { Loading, Message } from 'element-ui'
import jsonBigint from 'json-bigint';
import router from '../router';

// 创建一个axios实例
const request = axios.create({
    timeout: 10000 //超时时间10S
})

// 配置loading加载
let loadingInstance;
const startLoading=()=> {
    loadingInstance = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}
const endLoading=()=> {
    loadingInstance.close()
}

//用来拦截用的
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.put["Content-Type"] = "application/json;charset=utf-8";

//防止number超过17位精度丢失
axios.defaults.transformResponse = [
    function (data) {
      const json = jsonBigint({
        storeAsString: true
      })
      const res = json.parse(data)
      return res
    }
]

// 请求拦截
axios.interceptors.request.use(
    req => {
        // 每次发送请求之前判断是否存在token，如果不存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        if (!req.headers.Authorization) {
            if (localStorage.token) {
                req.headers.Authorization = 'Bearer ' + localStorage.token
            }
        }
        return req
    },
    error => {
        Promise.reject(error)
    }
)

// 响应拦截
axios.interceptors.response.use(
    res => {
        if (res.status === 200) {
            if (res.data.code === 401) {
                // 清除token     
                localStorage.removeItem('token');
                router.replace({
                    path: '/',
                    query: { redirect: router.currentRoute.fullPath }
                });
            }
            return Promise.resolve(res.data);
        } else {
            // 清除token     
            localStorage.removeItem('token');
            router.replace({
                path: '/',
                query: { redirect: router.currentRoute.fullPath }
            });
            return Promise.reject(res.data);
        }
    },
    // 服务器状态码不是200的情况 
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录    
                // 未登录则跳转登录页面，并携带当前页面的路径    
                // 在登录成功后返回当前页面，这一步需要在登录页操作。    
                case 401:
                    // 清除token     
                    localStorage.removeItem('token');
                    router.replace({
                        path: '/',
                        query: { redirect: router.currentRoute.fullPath }
                    });
                    break;
                // 403 token过期
                // 登录过期对用户进行提示    
                // 清除本地token和清空vuex中token对象    
                // 跳转登录页面    
                case 403:
                    // 清除token     
                    localStorage.removeItem('token');
                    break;
                // 404请求不存在    
                case 404:
                    // alert("404")
                    Message.error({message: '接口不存在'})
                    break;
                    case 999: 
                    // alert("权限不足,无法访问")
                    Message.error({message: '权限不足,无法访问'})
                    router.push("/device.html")
                    break;
                // 其他错误，直接抛出错误提示   
                default:
                    
            }
            return Promise.reject(error.response);
        }
    }
)

/** 
 * get封装
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function $get(url, params, config = {isLoading: true, ignoreToast: false}) {
    if (config.isLoading) {
        startLoading()
    }
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            signal: config?.signal
        })
        .then(res => {
            if(config.isLoading) {
                endLoading()
            }
            if (res.code != 0) {
                if (!config.ignoreToast) Message.error({message: res.msg, center: true});
				reject();
            }else {
				resolve(res.data);
			}
        })
        .catch(err => {
            reject(err.data)
            if(config.isLoading) {
                endLoading()
            }
            // Message.error({message: err.data.message, center: true});
        })
    });
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function $post(url, params, config = {}) {
    startLoading()
    return new Promise((resolve, reject) => {
        axios.post(url, params, config)
        .then(res => {
            endLoading()
            if (res.code == 0) {
                resolve(res.data)
                Message.success({message: "操作成功！", center: true});
            }else {
                reject()
                Message.error({message: res.msg, center: true});
			}
        })
        .catch(err => {
            endLoading()
            reject(err.data)
            // Message.error({message: err.data.message, center: true});
        })
    });
}

/** 
 * put方法，对应put请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function $put(url, params) {
    startLoading()
    return new Promise((resolve, reject) => {
        axios.put(url, params)
        .then(res => {
            endLoading()
            if (res.code == 0) {
                resolve(res.data)
                Message.success({message: "操作成功！", center: true});
            }else {
                reject()
                Message.error({message: res.msg, center: true});
            }
        })
        .catch(err => {
            endLoading()
            reject(err.data)
            // Message.error({message: err.data.message, center: true});
        })
    });
}

/** 
 * delete方法，对应delete请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function $delete(url, params) {
    startLoading()
    return new Promise((resolve, reject) => {
        axios.delete(url, params)
        .then(res => {
            endLoading()
            if (res.code == 0) {
                resolve(res.data)
                Message.success({message: "操作成功！", center: true});
            }else {
                reject()
                Message.error({message: res.msg, center: true});
            }
        })
        .catch(err => {
            endLoading()
            reject(err.data)
            // Message.error({message: err.data.message, center: true});
        })
    });
}

/** 
 * post方法，对应post请求 登录专用
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 参数是form-data格式参数 
 */
export function post(url, params) {
    startLoading()
    return new Promise((resolve, reject) => {
        axios.post(url, null, {params: params})
        .then(res => {
            endLoading()
            if (res.code == 0) {
                resolve(res)
                Message.success({message: "登录成功！", center: true});
            }else {
                reject(res)
                Message.error({message: "登录失败!", center: true});
            }
        })
        .catch(err => {
            reject(err.data)
            endLoading()
        })
    });
}

// 导出api
export default request