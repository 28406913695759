<template>
    <el-dialog title="生成gif" :visible.sync="gifDialogVisible" width="60%" :close-on-click-modal="false" :before-close="gifDialogCloseBefore">
        <el-card class="video-card">
            <div class="video-container">
                <video ref="videoPlayer" class="video-player" controls muted crossorigin="anonymous" :src="videoUrl" :key="videoUrl"
                    @loadedmetadata="onVideoLoaded" @timeupdate="onTimeUpdate">
                </video>

                <div class="video-gif" v-if="gifFile">
                    <img :src="previewGifUrl" alt="GIF Preview" class="gif-preview" v-if="previewGifUrl" />
                    <div class="file-size" v-if="gifFileSize !== null">文件大小：{{formatFileSize(gifFileSize)}}</div>
                </div>
            </div>
            <div class="time-range-selector">
                <div class="time-slider">
                    <el-slider v-model="timeRange" range :min="0" :max="videoDuration" :step="0.1"
                        :format-tooltip="formatTime" @input="onRangeSliderChange"></el-slider>
                </div>
                <div class="time-inputs">
                    <span>开始时间：</span>
                    <el-input v-model="startTime" placeholder="00:00.000" @blur="validateTimeBlur"
                        @change="validateTimeChange" class="time-input" />
                    <span>结束时间：</span>
                    <el-input v-model="endTime" placeholder="00:00.000" @blur="validateTimeBlur"
                        @change="validateTimeChange" class="time-input" />
                    <div class="quick-time-buttons">
                        <el-button size="small" @click="setTimeRange(3)">3秒</el-button>
                        <el-button size="small" @click="setTimeRange(5)">5秒</el-button>
                        <!-- <el-button size="small" @click="setTimeRange(10)">10秒</el-button> -->
                        <!-- <el-button size="small" @click="setTimeRange(15)">15秒</el-button> -->
                    </div>
                </div>
                <!-- <div class="frame-interval">
                    <span>帧间隔：</span>
                    <el-input-number v-model="frameInterval" :min="0.1" :max="1" :step="0.1"
                        size="small"></el-input-number>
                    <span>秒</span>
                </div> -->
            </div>
            <div class="controls">
                <el-button @click="closeGifDialog">取 消</el-button>
                <el-button type="primary" :loading="captureVideoing" @click="captureFrameRange"
                    :disabled="!videoUrl || !isValidTimeRange">预览GIF</el-button>
                <el-button type="success" :loading="gifUploading" :disabled="gifFile === null" @click="uploadGif">确认</el-button>
            </div>
        </el-card>
    </el-dialog>
</template>

<script>
import GIF from 'gif.js'
import { fileUpload } from '@/api/api'
export default {
    /* props: {
        cover: {
            type: String,
            required: true,
        }
    },  */
    data() {
        return {
            fileDomain: 'https://file.oiki.cc/',
            gifDialogVisible: false,
            videoUrl: null,
            selectedFrames: [],
            currentTime: 0,
            sliderTime: 0,
            timeRange: [0, 0],
            frameDelay: 100,
            startTime: '',
            endTime: '',
            captureVideoing: false,
            gifWidth: 167,
            gifHeight: 210,
            frameInterval: 0.1,
            videoDuration: 0,
            previewGifUrl: null,
            gifFile: null,
            gifFileSize: null,

            gifUploading: false,
        } 
    },
    watch: {
        videoUrl: {
            handler() {
                this.gifFile = null
            }
        }
    },
    computed: {
        isValidTimeRange() {
            const start = this.parseTimeString(this.startTime)
            const end = this.parseTimeString(this.endTime)
            return start !== null && end !== null && start < end && end <= this.videoDuration
        },
    },
    methods: {
        openGifDialog(cover) {
            console.log(cover,'cover')
            if (cover) {
                this.videoUrl = this.fileDomain + cover
            }
            this.gifDialogVisible = true;
        },
        closeGifDialog() {
            if (this.captureVideoing) {
                this.$confirm('正在生成GIF，是否确认要终止生成并关闭当前弹窗？','提示').then(() => {
                    this.gifDialogVisible = false;
                })
            } else {
                this.gifDialogVisible = false;
            }
        },
        onVideoLoaded() {
            console.log('onVideoLoaded')
            // this.$refs.videoPlayer.crossOrigin = 'anonymous'
            this.videoDuration = this.$refs.videoPlayer.duration
            this.timeRange = [0, this.videoDuration]
            this.startTime = this.formatTime(0)
            this.endTime = this.formatTime(this.videoDuration)
        },
        parseTimeString(timeStr) {
            const pattern = /^(\d{2}):(\d{2})\.(\d{3})$/
            const match = timeStr.match(pattern)
            if (!match) return null

            const minutes = parseInt(match[1])
            const seconds = parseInt(match[2])
            const milliseconds = parseInt(match[3])

            return minutes * 60 + seconds + milliseconds / 1000
        },
        validateTimeChange() {
            if (!this.startTime || !this.endTime) return
            if (!this.isValidTimeRange) {
                this.$message.warning('请输入有效的时间范围，格式为 MM:SS.mmm')
            }
        },
        validateTimeBlur() {
            const time = this.parseTimeString(this.startTime)
            const endTime = this.parseTimeString(this.endTime)

            if (time !== null) {
                // 如果开始时间有效，更新滑块的开始值
                this.timeRange = [time, this.timeRange[1]]
            }

            if (endTime !== null) {
                // 如果结束时间有效，更新滑块的结束值
                this.timeRange = [this.timeRange[0], endTime]
            }
        },
        gifDialogCloseBefore(done) {
            if (this.captureVideoing) {
                this.$confirm('正在生成GIF，是否确认要终止生成并关闭当前弹窗？','提示').then(() => {
                    done()
                })
            } else {
                done()
            }
        },
        async captureFrameRange() {
            if (!this.isValidTimeRange) {
                this.$message.warning('请先设置有效的时间范围')
                return
            }

            const startTime = this.parseTimeString(this.startTime)
            const endTime = this.parseTimeString(this.endTime)
            const video = this.$refs.videoPlayer

            // 清空之前的帧
            this.selectedFrames = []

            // 设置视频到开始时间
            video.currentTime = startTime
            this.captureVideoing = true
            // 等待视频seek完成
            await new Promise(resolve => {
                const onSeeked = () => {
                    video.removeEventListener('seeked', onSeeked)
                    resolve()
                }
                video.addEventListener('seeked', onSeeked)
            })

            // 在时间范围内捕获帧
            for (let time = startTime; time <= endTime; time += this.frameInterval) {
                if (!this.gifDialogVisible) {
                    this.selectedFrames = []
                    this.captureVideoing = false
                    return
                }
                video.currentTime = time
                await new Promise(resolve => {
                    const onSeeked = () => {
                        video.removeEventListener('seeked', onSeeked)
                        this.captureFrame(time)
                        resolve()
                    }
                    video.addEventListener('seeked', onSeeked)
                })
            }
            
            this.generateGif()
            // this.$message.success(`已捕获 ${this.selectedFrames.length} 帧`)
        },
        captureFrame(time) {
            const video = this.$refs.videoPlayer
            const canvas = document.createElement('canvas')
            canvas.width = this.gifWidth
            canvas.height = this.gifHeight
            const ctx = canvas.getContext('2d')

            const videoRatio = video.videoWidth / video.videoHeight
            const targetRatio = this.gifWidth / this.gifHeight

            let sx = 0
            let sy = 0
            let sWidth = video.videoWidth
            let sHeight = video.videoHeight

            if (videoRatio > targetRatio) {
                sWidth = video.videoHeight * targetRatio
                sx = (video.videoWidth - sWidth) / 2
            } else {
                sHeight = video.videoWidth / targetRatio
                sy = (video.videoHeight - sHeight) / 2
            }

            ctx.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, this.gifWidth, this.gifHeight)
            const frameDataUrl = canvas.toDataURL('image/png')

            this.selectedFrames.push({
                time: time,
                dataUrl: frameDataUrl
            })
        },
        onTimeUpdate() {
            this.currentTime = this.$refs.videoPlayer.currentTime
            this.sliderTime = this.currentTime
        },
        onRangeSliderChange(value) {
            if ((value[0] === value[1] && value[0] === 0) && (this.startTime === '' || this.endTime === '')) return
            this.startTime = this.formatTime(value[0])
            this.endTime = this.formatTime(value[1])
            this.validateTimeChange()
            // 更新视频播放进度到新的开始时间
            this.$refs.videoPlayer.currentTime = value[0]
        },
        formatTime(time) {
            const minutes = Math.floor(time / 60)
            const seconds = Math.floor(time % 60)
            const milliseconds = Math.floor((time % 1) * 1000)
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`
        },
        async captureCurrentFrame() {
            const video = this.$refs.videoPlayer
            const canvas = document.createElement('canvas')
            canvas.width = this.gifWidth
            canvas.height = this.gifHeight
            const ctx = canvas.getContext('2d')

            // 计算缩放和裁剪参数以保持宽高比
            const videoRatio = video.videoWidth / video.videoHeight
            const targetRatio = this.gifWidth / this.gifHeight

            let sx = 0
            let sy = 0
            let sWidth = video.videoWidth
            let sHeight = video.videoHeight

            if (videoRatio > targetRatio) {
                // 视频更宽，需要裁剪两边
                sWidth = video.videoHeight * targetRatio
                sx = (video.videoWidth - sWidth) / 2
            } else {
                // 视频更高，需要裁剪上下
                sHeight = video.videoWidth / targetRatio
                sy = (video.videoHeight - sHeight) / 2
            }

            ctx.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, this.gifWidth, this.gifHeight)
            const frameDataUrl = canvas.toDataURL('image/png')

            this.selectedFrames.push({
                time: this.currentTime,
                dataUrl: frameDataUrl
            })

            this.$message.success('已添加当前帧')
        },
        async generateGif() {
            if (this.selectedFrames.length < 2) {
                this.$message.warning('请至少选择2帧图片')
                return
            }

            const loading = this.$loading({
                lock: true,
                text: '正在生成GIF...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })

            try {
                const gif = new GIF({
                    workers: 4,
                    quality: 1,
                    width: this.gifWidth,
                    height: this.gifHeight,
                    workerScript: '/gif.worker.js'
                })
                
                // 等待所有图片加载完成后再添加到GIF中
                const loadImages = this.selectedFrames.map(frame => {
                    return new Promise((resolve) => {
                        const img = new Image()
                        img.onload = () => resolve(img)
                        img.src = frame.dataUrl
                    })
                })
                
                const images = await Promise.all(loadImages)
                images.forEach((img, index) => {
                    gif.addFrame(img, { delay: this.frameDelay })
                    loading.text = `正在处理第 ${index + 1}/${images.length} 帧...`
                })
                
                return new Promise((resolve, reject) => {
                    gif.on('finished', blob => {
                        if (this.previewGifUrl) {
                            URL.revokeObjectURL(this.previewGifUrl)
                        }
                        console.log(blob,'blob')
                        this.previewGifUrl = URL.createObjectURL(blob)
                        this.gifFile = new File([blob], 'video.gif', {type: blob.type})
                        console.log(this.gifFile,'this.gifFile')
                        this.gifFileSize = blob.size
                        loading.close()
                        this.captureVideoing = false
                        this.$message.success('GIF生成成功')
                        resolve()
                    })

                    gif.on('error', err => {
                        loading.close()
                        this.captureVideoing = false
                        this.$message.error('GIF生成失败：' + err.message)
                        reject(err)
                    })

                    gif.render()
                })
            } catch (error) {
                loading.close()
                this.captureVideoing = false
                this.$message.error('GIF生成失败：' + error.message)
            }
        },
        clearFrames() {
            this.selectedFrames = []
            if (this.previewGifUrl) {
                URL.revokeObjectURL(this.previewGifUrl)
                this.previewGifUrl = null
            }
            this.$message.success('已清空所有帧')
        },
        removeFrame(index) {
            this.selectedFrames.splice(index, 1)
        },
        updateFrameDelay(value) {
            this.frameDelay = value
        },
        setTimeRange(seconds) {
            let currentTime = this.$refs.videoPlayer.currentTime
            // console.log(currentTime,'currentTime')
            if (currentTime >= this.videoDuration) currentTime = 0
            const endTime = Math.min(currentTime + seconds, this.videoDuration)
            this.timeRange = [currentTime, endTime]
            this.startTime = this.formatTime(currentTime)
            this.endTime = this.formatTime(endTime)
            this.validateTimeChange()
        },
        uploadGif() {
            if (this.gifFile === null) return;
            this.gifUploading = true

            const formData = new FormData()
            formData.append('file', this.gifFile)
            formData.append('code', '7001')
            formData.append('index', 0)
            fileUpload(formData).then(path => {
                this.newGifCover = path
                this.gifDialogVisible = false
                this.gifFile = null
                this.initGif()
                this.$emit('confirm', path)
            }).finally(() => {
                this.gifUploading = false
            })
        },
        initGif() {
            this.videoUrl = ''
            this.selectedFrames = []
            this.currentTime = 0
            this.sliderTime = 0
            this.captureVideoing = false
            this.previewGifUrl = null
            this.gifFileSize = null
        },
        formatFileSize(bytes, decimalPlaces = 2) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPlaces)) + ' ' + sizes[i];
        }
    }
}
</script>

<style scoped>

.images-item {
    width: 60px;
    height: 60px;
    margin: 2px;
    position: relative;
}

.images-item .cover-icon {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 10px;
    color: #fff;
    background-color: #ff5858;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

.video-card {
    margin-bottom: 20px;
}

.video-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
}

.video-player {
    min-width: 0;
    max-width: 80%;
    max-height: 400px;
}

.video-gif {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.controls {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.time-range-selector {
    margin: 20px 0;
    padding: 0 20px;
}

.time-slider {
    margin: 0 20px 15px;
}

.quick-time-buttons {
    display: flex;
    gap: 5px;
    margin-left: 10px;
}

.time-inputs {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.time-input {
    width: 120px;
}

.frame-interval {
    display: flex;
    align-items: center;
    gap: 10px;
}

.frames-card {
    margin-top: 20px;
    text-align: center;
}

.frames-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.frames-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 10px;
}

.frames-header h3 {
    margin: 0;
}

.frames-settings {
    display: flex;
    align-items: center;
    gap: 10px;
}
</style>