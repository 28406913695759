<template>
    <div style="text-align: left;">
        <div id="article-main-info">
            <el-card>
                <div slot="header" class="flex-box justify-between">
                    <div class="card-title">动态</div>
                    <div class="card-right">
                        <el-button type="danger" size="mini" @click="deleteClick">删除动态</el-button>
                        <el-button type="primary" size="mini" @click="setRecommend(1)" v-if="articleDetail.isRecommend == 0">设为推荐</el-button>
                        <el-button type="warning" size="mini" @click="setRecommend(0)" v-else>取消推荐</el-button>

                        <el-button type="primary" size="mini" v-if="articleDetail.isTop === 0 && articleDetail.status === 1" @click="setTop(articleDetail, 1)">置顶</el-button>
                        <el-button type="warning" size="mini" v-else-if="articleDetail.isTop === 1 && articleDetail.status === 1" @click="setTop(articleDetail, 0)">已置顶</el-button>
                    </div>
                </div>
                <div class="article-info-box">
                    <div class="item">
                        <span class="label">发布者：</span>
                        <span class="value">{{articleDetail.nickName}}</span>
                    </div>
                    <div class="item">
                        <span class="label">发布者用户编号：</span>
                        <span class="value"><a :href="'/user/' + articleDetail.userId" target="_blank">{{articleDetail.userNumber}}</a></span>
                    </div>
                    <div class="item">
                        <span class="label">动态ID：</span>
                        <span class="value">{{articleDetail.id}}</span>
                    </div>
                    <div class="item">
                        <span class="label">发布时间：</span>
                        <span class="value">{{articleDetail.createTime}}</span>
                    </div>
                    <div class="item">
                        <span class="label">点赞数：</span>
                        <span class="value">{{articleDetail.likeCount}}</span>
                    </div>
                </div>
    
                <div class="table-box">
                    <el-table :data="[articleDetail]" border :cell-style="{ padding: '0' }"
                    :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                    :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                        <el-table-column prop="content" label="文本" min-width="600" align="center">
                            <template slot-scope="scope">
                                <div v-if="editContentStatus">
                                    <el-input v-model="editContent" type="textarea" :rows="2" />
                                    <el-button type="warning" size="mini" @click="editContentStatus = false">取消</el-button>
                                    <el-button type="primary" size="mini" @click="updateContent">确定</el-button>
                                </div>
                                <div v-else>
                                    {{ scope.row.content }}
                                    <span class="content-edit" @click="editContentStatus = true" v-if="scope.row.content"><i class="el-icon-edit"></i></span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="图片" min-width="200" align="center">
                            <template slot-scope="scope">
                                <div class="table-imgs" v-if="!scope.row.video">
                                    <div class="img" v-for="(img, index) in scope.row.images" :key="index">
                                        <el-image style="height: 60px; width: 60px; padding: 2px;" :src="fileDomain + img"
                                            :preview-src-list="scope.row.images.map(img => fileDomain + img)" @load="setCardHeight" >
                                        </el-image>
                                        <div v-if="showDeleteImgBtn"><el-button type="danger" size="mini" @click="deleteImg(index)">删除</el-button></div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="视频" min-width="200" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.video">
                                    <video muted class="video" :src="fileDomain + scope.row.video" type="video/mp4" :poster="fileDomain + scope.row.images[0]" autoplay="autoplay" style="width: 200px; height: 200px; padding-top: 10px;" controls="controls" loop="-1">
                                        <p>你的浏览器不支持video标签.</p>
                                    </video>
                                    <el-button type="danger" size="mini" @click="deleteFile('video')" v-if="showDeleteVideoBtn">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="音频" min-width="240" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.audio">
                                    <audio style="width: 100%;height: 50px;" controls="controls">
                                        <source :src="fileDomain + scope.row.audio" :type="scope.row.audioType">
                                    </audio>
                                    <el-button type="danger" size="mini" @click="deleteFile('audio')" v-if="showDeleteAudioBtn">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>

        <div class="flex-box justify-between">
            <div class="lists-box comment-box">
                <el-card header="评论">
                    <div class="comment-list" :style="{height: cardHeight + 'px'}">
                        <template v-for="comment in commentList">
                            <div class="item flex-box justify-between">
                                <div class="delete" @click="deleteComment(comment)">
                                    <i class="el-icon-delete"></i>
                                </div>
                                <div class="label">
                                    <a class="name" :href="'/user/' + comment.userId" target="_blank">{{ comment.nickName }}</a>：
                                </div>
                                <div class="content" v-if="comment.ilk == 1">
                                    <audio :key="comment.id" controls>
                                        <source :src="fileDomain + comment.audio" :type="comment.audioType">
                                    </audio>
                                </div>
                                <div class="content" v-else>
                                    <div>{{ comment.content }}</div>
                                    <el-image style="height: 60px; width: 60px; padding: 2px;" :key="index" :src="fileDomain + comment.emote"
                                    :preview-src-list="[fileDomain + comment.emote]" v-if="comment.emote" />
                                </div>
                                <div class="time">{{ comment.createTime }}</div>
                            </div>
                            
                            <div class="item flex-box justify-between" v-for="reply in comment.replyList">
                                <div class="delete" @click="deleteReply(reply)">
                                    <i class="el-icon-delete"></i>
                                </div>
                                <div class="label">
                                    <a class="name" :href="'/user/' + reply.userId" target="_blank">{{ reply.nickName }}</a>
                                    <template v-if="reply.replyUserId">
                                        <span>回复</span>
                                        <a class="name" :href="'/user/' + reply.replyUserId" target="_blank">{{ reply.replyNickName }}</a>
                                    </template>
                                    ：
                                </div>
                                <div class="content" v-if="reply.ilk == 1">
                                    <audio :key="reply.id" controls>
                                        <source :src="fileDomain + reply.audio" :type="reply.audioType">
                                    </audio>
                                </div>
                                <div class="content">
                                    <div>{{ reply.content }}</div>
                                    <el-image style="height: 60px; width: 60px; padding: 2px;" :key="index" :src="fileDomain + reply.emote"
                                    :preview-src-list="[fileDomain + reply.emote]" v-if="reply.emote" />
                                </div>
                                <div class="time">{{ reply.createTime }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="pages">
                        <el-pagination style="margin-top: 10px;" background :hide-on-single-page="false" layout="total, prev, pager, next"
                            :page-size="commentSize" :total="commentTotal" :current-page="commentPage" @current-change="commentPageChangeClick">
                        </el-pagination>
                    </div>
                </el-card>
            </div>
            <div class="lists-box reward-box">
                <el-card header="打赏">
                    <el-table :height="cardHeight" :data="rewardList" border :cell-style="{ padding: '0' }"
                    :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                    :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                        <el-table-column prop="nickName" label="用户昵称" min-width="100" align="center"></el-table-column>
                        <el-table-column prop="userNumber" label="用户编号" min-width="80" align="center">
                            <template slot-scope="scope">
                                <a :href="'/user/' + scope.row.rewardUserId" target="_blank">{{ scope.row.userNumber }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="礼物" min-width="80" align="center"></el-table-column>
                        <el-table-column prop="number" label="数量" min-width="40" align="center"></el-table-column>
                        <el-table-column prop="totalPrice" label="价值(K币)" min-width="80" align="center"></el-table-column>
                    </el-table>
                    <div class="pages">
                        <el-pagination style="margin-top: 10px;" background :hide-on-single-page="false" layout="total, prev, pager, next"
                            :page-size="rewardSize" :total="rewardTotal" :current-page="rewardPage" @current-change="rewardchangeClick">
                        </el-pagination>
                    </div>
                </el-card>
            </div>
        </div>

        <delete-dialog ref="deleteDialog" title="删除动态" :type="1" @confirm="deleteConfirm"></delete-dialog>
    </div>
</template>

<script>
import {
    articleFindById,
    articleUpdate,
    articleComment,
    giftRewardPage,
    commentDelete,
    replyDelete,
    articleDelete
} from "@/api/api";
import deleteDialog from '@/components/deleteDialog'
export default {
    components: {
        deleteDialog
    },
    data() {
        return {
            id: null,
            fileDomain: 'https://file.oiki.cc/',
            articleDetail: {},
            cardHeight: null,

            editContent: '',
            editContentStatus: false,

            commentTotal: 0,
            commentPage: 1,
            commentSize: 1,
            commentList: [],
            
            rewardTotal: 0,
            rewardPage: 1,
            rewardSize: 10,
            rewardList: []
        }
    },
    computed: {
        showDeleteImgBtn() {
            return this.articleDetail.content || this.articleDetail.video || this.articleDetail.audio || this.articleDetail.images.length > 1
        },
        showDeleteVideoBtn() {
            return this.articleDetail.content || this.articleDetail.audio || this.articleDetail.image
        },
        showDeleteAudioBtn() {
            return this.articleDetail.content || this.articleDetail.video || this.articleDetail.image
        }
    },
    methods: {
        setCardHeight() {
            const mainPage = document.body.getElementsByClassName('el-main')[0]
            const mainPadding = parseFloat(mainPage.style.paddingTop) + parseFloat(mainPage.style.paddingBottom)
            const mainHeight = mainPage.clientHeight - mainPadding
            const articleHeight = document.getElementById('article-main-info').clientHeight
            const list = document.body.getElementsByClassName('comment-list')[0]
            console.log(articleHeight, mainHeight - articleHeight,'articleHeight')

            this.cardHeight = mainHeight - articleHeight - 60 - 40 - 42 - 20
        },
        getArticleDetail() {
            articleFindById(this.id).then(response => {
                
                this.editContent = response.content
                
                if (response.image != null && response.image.length > 0) {
                    response.images = response.image.split(",")
                    /* for(let j = 0; j < response.images.length; j++) {
                        response.images[j] = this.fileDomain + response.images[j]
                    } */
                }
                
                if (response.audio) {
                    const audioAry = response.audio.split('.')
                    const audioSuffix = audioAry[audioAry.length - 1]
                    response.audioType = audioSuffix == 'aac' ? 'audio/aac' : 'audio/mpeg'
                }
                
                this.$set(this, 'articleDetail', response || {})
                this.setCardHeight()
            })
        },
        getArticleComment() {
            articleComment({
                articleId: this.id,
                current: this.commentPage,
                size: this.commentSize,
            }).then(response => {
                this.commentTotal = response.total 
                this.commentList = response.records.map(comment => {
                    if (comment.ilk == 1) {
                        const audioAry = comment.audio.split('.')
                        const audioSuffix = audioAry[audioAry.length - 1]
                        comment.audioType = audioSuffix == 'aac' ? 'audio/aac' : 'audio/mpeg'
                    }

                    comment.replyList.forEach(reply => {
                        if (reply.ilk == 1) {
                            const audioAry = reply.audio.split('.')
                            const audioSuffix = audioAry[audioAry.length - 1]
                            reply.audioType = audioSuffix == 'aac' ? 'audio/aac' : 'audio/mpeg'
                        }
                    })

                    return comment;
                })
            })
        },
        commentPageChangeClick(page) {
            this.commentPage = page
            this.getArticleComment()
        },
        getArticleReward() {
            giftRewardPage({
                businessId: this.id,
                current: this.rewardPage,
                size: this.rewardSize,
            }).then(response => {
                this.rewardTotal = response.total 
                this.rewardList = response.records
            })
        },
        rewardchangeClick(page) {
            this.rewardPage = page
            this.getArticleReward()
        },
        setRecommend(recommend) {
            if (recommend === undefined) return;
            const datas = this.getUpdateFieldData('isRecommend')

            articleUpdate({
                id: this.id,
                isRecommend: recommend,
                ...datas
            }).then(() => {
                this.getArticleDetail()
            })
        },
        getUpdateFieldData(omitField) {
            const fields = ['isRecommend','isTop','content','image','video','audio']
            const datas = {}
            for (const field of fields) {
                if (omitField === field) continue;
                if (field === 'audio') {
                    datas.audioTime = this.articleDetail.audioTime
                    datas.audioVolume = this.articleDetail.audioVolume
                } else if (omitField === 'video' && field === 'image') {    // 视频会有一张图片作为封面，删除视频时也删除该封面图
                    continue;
                }
                datas[field] = this.articleDetail[field]
            }

            return datas
        },
        async updateContent() {
            console.log(this.articleDetail,'articleDetail')
            if (!this.articleDetail.image && !this.articleDetail.video && !this.articleDetail.audio && this.editContent === '') {
                this.$message.error('动态内容不可为空！')
            }

            await new Promise((resolve,reject) => {
                this.$confirm('修改操作不可撤回，是否要修改内容？','修改动态内容').then(() => {
                    resolve()
                })
            })

            const datas = this.getUpdateFieldData('content')

            articleUpdate({
                id: this.id,
                content: this.editContent,
                ...datas
            }).then(() => {
                this.editContentStatus = false
                this.getArticleDetail()
            })
        },
        async deleteImg(index) {
            await new Promise((resolve,reject) => {
                this.$confirm('删除操作不可撤回，是否要删除该图片？','删除动态图片').then(() => {
                    resolve()
                })
            })

            this.articleDetail.images.splice(index, 1)

            const images = this.articleDetail.image.split(',')
            images.splice(index, 1)
            const datas = this.getUpdateFieldData('image')

            articleUpdate({
                id: this.id,
                image: images.join(','),
                ...datas
            }).then(() => {
                this.getArticleDetail()
            })
        },
        async deleteFile(type) {
            const name = type === 'video' ? '视频' : '音频'
            await new Promise((resolve,reject) => {
                this.$confirm(`删除操作不可撤回，是否要删除该${name}？`,`删除动态${name}`).then(() => {
                    resolve()
                })
            })

            const datas = this.getUpdateFieldData(type)
            // console.log(datas,'datas')
            // return;
            articleUpdate({
                id: this.id,
                ...datas
            }).then(() => {
                this.getArticleDetail()
            })
        },
        deleteComment(item) {
            this.$confirm('确认要删除该评论吗？','提示').then(res => {
                console.log('delete')
                let params = new URLSearchParams()
			    params.append("idList", [item.id])
                commentDelete({params}).then(() => {
                    this.getArticleComment()
                })
            })
        },
        deleteReply(item) {
            this.$confirm('确认要删除该回复吗？','提示').then(res => {
                console.log('delete')
                let params = new URLSearchParams()
			    params.append("idList", [item.id])
                replyDelete({params}).then(() => {
                    this.getArticleComment()
                })
            })
        },
        deleteClick() {
            this.$refs.deleteDialog.setId(this.id)
            this.$refs.deleteDialog.showDialog()
        },
        deleteConfirm(data) {
            this.deleteArticle(data.content)
        },
        deleteArticle(remark) {
            let params = new URLSearchParams()
			params.append("idList", [this.id])
			params.append("remark", remark)
            this.deleteLoading = true
			articleDelete({ params })
				.then(() => {
                    this.$alert('动态已删除','', {
                        confirmButtonText: '关闭页面',
                        callback: action => {
                            if (action === 'confirm') {
                                window.close()
                            }
                        }
                    })
				})
        },
    },
    created() {
        this.id = this.$route.params.id
        this.getArticleDetail()
        this.getArticleComment()
        this.getArticleReward()
    },
    mounted() {
        this.setCardHeight()

        let resizeTimer
        window.onresize = () => {
            clearTimeout(resizeTimer)
            resizeTimer = setTimeout(() => {
                this.setCardHeight()
            }, 100);
        }
    }
}
</script>

<style>
.flex-box {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.article-info-box {
    display: flex;
    justify-content: space-between;
}

.table-box {
    margin-top: 20px;
}

.table-imgs {
    width: 200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}

.content-edit {
    color: #1890ff;
    cursor: pointer;
    user-select: none;
}

.lists-box {
    flex-grow: 1;
    margin-top: 20px;
}

.comment-box {
    width: 55%;
    margin-right: 20px;
}

.reward-box {
    width: 45%;
}

.comment-list {
    overflow-y: auto;
}

.comment-list .item{
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.comment-list .item .delete {
    margin-right: 10px;
    flex-shrink: 0;
    cursor: pointer;
}

.comment-list .item .label {
    flex-shrink: 0;
}

.comment-list .item .label span {
    margin: 0 10px;
}

.comment-list .item .content {
    flex-grow: 1;
}

.comment-list .item .time {
    font-size: 12px;
    color: #999;
    margin-left: 10px;
    flex-shrink: 0;
}
</style>