<template>
    <div class="form">
        <div class="main-title">设置消息内容:</div>
        <div class="radio-row">
            <el-radio-group v-model="platform">
                <el-radio label="android">Android</el-radio>
                <el-radio label="ios">ios</el-radio>
            </el-radio-group>
        </div>

        <div class="tab-content">
            <div class="row">
                <div class="sub-title">文字消息</div>
                <el-input class="textarea" type="textarea" :rows="20" placeholder="请输入内容" v-model="form[platform].oneContent" resize="none"></el-input>
            </div>

            <div class="row">
                <div class="sub-title">图片或视频消息</div>
                <div class="content-row flex flex-items-end">
                    <div class="img-box" @click="imgTap">
                        <template v-if="form[platform].twoContent">
                            <el-image v-if="fileType == 'image'" style="width: 120px; height: 120px;" :src="form[platform].twoContent"
                                :preview-src-list="[form[platform].twoContent]">
                            </el-image>
                            <video width="100%" height="100%" :src="form[platform].twoContent" controls muted v-else></video>
                        </template>

                        <i class="el-icon-plus" size="40" v-else></i>
                    </div>
                    <div v-if="form[platform].twoContent">
                        <el-button type="danger" @click="deleteMedia">删除</el-button>
                        <el-button type="primary" @click="chooseMedia">更换</el-button>
                    </div>

                    <!-- el-upload仅作为上传图片的控件，不进行组件内容展示 -->
                    <el-upload
                        ref="upload"
                        action="api/file/upload"
                        accept=".jpg,.jpeg,.png,.pneg,.gif,.Jpg,.Jpeg,.Png,.Pneg,.Gif,.mp4,.avi"
                        :limit="1"
                        :headers="headers"
                        :data="uploadData"
                        :show-file-list="false"
                        :on-success="uploadSuccess"></el-upload>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            platform: 'android',
            form: {
                android: {
                    oneContent: '',
                    twoContent: '',
                },
                ios: {
                    oneContent: '',
                    twoContent: '',
                },
            },

            headers: {
                Authorization: "Bearer " + localStorage.token
            },
            uploadData: {
                code: 5001,
                index: 0
            },

            loading: undefined
        }
    },
    props: {
        formData: {
            type: Object
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(form) {
                this.$emit('update:formData', form)
            }
        },
    },
    computed: {
        fileType() {
            return this.getFileType(this.form[this.platform].twoContent)
        }
    },
    methods: {
        getFileType(filePath) {
            const extension = filePath.slice((filePath.lastIndexOf(".") - 1 >>> 0) + 2)
            let type = 'video'
            switch (extension) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'pneg':
                case 'gif':
                case 'Jpg':
                case 'Jpeg':
                case 'Png':
                case 'Pneg':
                case 'Gif':
                    type = 'image'
                break;
            }

            return type
        },
        setForm(form) {
            for (let platform of Object.keys(form)) {
                const data = form[platform]
                if (data) {
                    for (let key of Object.keys(data)) {
                        const value = data[key]
                        if (value) this.form[platform][key] = value
                    }
                }
            }
        },
        formValidate() {
            /* let errMsg = ''
            for (let platform of ['android','ios']) {
                const data = this.form[platform]
                let valid = true
                if (data.oneContent == '' && data.twoContent == '') {
                    valid = false
                    errMsg += (errMsg ? '和' : '') + platform
                }
            }

            if (errMsg) {
                errMsg += '必须填入文字消息或上传图片/视频'
                this.$message.error(errMsg)
                return false
            } */
            return true
        },
        imgTap() {
            if (this.form[this.platform].twoContent) return;
            this.chooseFile()
        },
        // 选择文件
        chooseFile() {
            const uploadDom = this.$refs.upload
            const inputDom = this.$refs.upload.$refs['upload-inner'].$refs.input
            inputDom.click()
        },
        // 文件上传成功
        uploadSuccess(response, file) {
            const img = response.data
            console.log(response,'response')
            this.form[this.platform].twoContent = img
            
            // 上传成功等待渲染变化之后清除组件中记录的文件列表
            this.$nextTick(() => {
                this.$refs.upload.clearFiles()
            })
        },
        deleteMedia() {
            this.$confirm('是否要删除？','删除').then(() => {
                this.form[this.platform].twoContent = ''
            }).catch()
        },
        chooseMedia() {
            this.chooseFile()
        }
    }
}
</script>

<style scoped>

.flex {
    display: flex;
}

.flex-items-end {
    align-items: flex-end;
}

.form {
    text-align: left;
}

.tab-content {
    margin-top: 20px;
}

.tab-content .row {
    margin-bottom: 20px;
}

.tab-content .row .textarea {
    max-width: 400px;
}

.tab-content .sub-title {
    margin-bottom: 10px;
}

.content-row .el-button{
    margin-left: 10px;
}

.img-box {
    width: 120px;
    height: 120px;
    font-size: 40px;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
}

.uploadFileInput {
    display: none;
}
</style>