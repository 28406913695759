<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
                <el-form-item label="用户编号">
                    <el-input v-model="query.userNumber" placeholder="请输入用户编号"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item label="性别">
					<el-select v-model="query.gender" placeholder="性别" style="width: 80px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="男" value=1></el-option>
						<el-option label="女" value=2></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="社交人格">
					<el-select v-model="query.personality" placeholder="社交人格" style="width: 90px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="支配者" value=1></el-option>
						<el-option label="臣服者" value=2></el-option>
						<el-option label="双向者" value=3></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="认证时间">
					<el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
                <el-form-item label="禁止被呼叫">
                    <el-select v-model="query.status">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已禁止" value="4"></el-option>
                        <el-option label="未禁止" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
            <el-table-column prop="sort" label="排序" min-width="60" align="center"></el-table-column>
            
            <el-table-column prop="userNumber" label="用户编号" min-width="120" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="nickName" label="用户昵称" min-width="120" align="center"></el-table-column>
            <el-table-column prop="gender" label="性别" min-width="50" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.gender === 0" style="color: #c78b13">未知</span>
					<span v-else-if="scope.row.gender === 1" style="color: #112db9">男</span>
					<span v-else-if="scope.row.gender === 2" style="color: #b91187">女</span>
				</template>
			</el-table-column>
            <el-table-column prop="personalityName" label="社交人格" min-width="80" align="center"></el-table-column>
            <el-table-column prop="images" label="真人照资料" min-width="400" align="center">
                <template slot-scope="scope">
                    <div class="flex">
                        <div class="images-item" v-if="scope.row?.cover.indexOf('.gif') !== -1">
                            <el-image style="width: 100%; height: 100%;" :src="fileDomain + scope.row.cover" :preview-src-list="[fileDomain + scope.row.cover]" ></el-image>
                            <i class="el-icon-s-ticket cover-icon"></i>
                        </div>
                        <div class="images-item" v-for="(file, index) in scope.row.files" :key="index">
                            <template v-if="file.indexOf('mp4') > -1">
                                <video style="width: 100%; height: 100%;" controls autoplay loop muted :src="fileDomain + file" />
                            </template>
                            <template v-else>
                                <el-image style="width: 100%; height: 100%;" :src="fileDomain + file" :preview-src-list="[fileDomain + file]" ></el-image>
                            </template>
                            <i class="el-icon-s-ticket cover-icon" v-if="scope.row.cover === file"></i>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="categorys" label="达人分类" min-width="150" align="center"></el-table-column>
            
            <el-table-column prop="price" label="连麦价格（k币/分钟）" min-width="180" align="center"></el-table-column>
            <el-table-column prop="labels" label="达人介绍标签" min-width="240" align="center">
                <!-- <template slot-scope="scope">
                    
                </template> -->
            </el-table-column>
            
            <el-table-column prop="createTime" label="认证时间" min-width="140" align="center"></el-table-column>
            <el-table-column prop="duration" label="已连麦时长(分钟)" min-width="140" align="center"></el-table-column>
            <el-table-column prop="number" label="已连麦次数" min-width="100" align="center"></el-table-column>
            <el-table-column prop="unumber" label="已连麦人数" min-width="100" align="center"></el-table-column>
            <el-table-column prop="likeCount" label="被点赞次数" min-width="100" align="center"></el-table-column>
            <el-table-column prop="lowCount" label="被点low次数" min-width="110" align="center"></el-table-column>
            <el-table-column prop="reportCount" label="被投诉次数" min-width="100" align="center"></el-table-column>
            <el-table-column prop="realRate" label="佣金比例" min-width="100" align="center">
                <template slot-scope="scope">
                    {{ scope.row.realRate + '%' }}
                </template>
            </el-table-column>
            <el-table-column prop="realRate" label="禁止被呼叫" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="scope.row.status === 4 ? 'color-danger' : 'color-info'">{{ scope.row.status === 4 ? '已禁止' : '未禁止'  }}</span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="240" align="center">
                <template slot-scope="scope">
                    <div class="btn-flex">
                        <el-button type="primary" size="mini" icon="el-icon-edit" @click="editItem(scope.row)">修改信息</el-button>
                        <el-button type="primary" size="mini" icon="el-icon-edit" @click="editRate(scope.row)">修改佣金</el-button>
                        <el-button type="primary" size="mini" icon="el-icon-edit" @click="editSort(scope.row)">修改排序</el-button>
                        <el-button type="warning" size="mini" icon="el-icon-error" @click="disableCalled(scope.row)">禁止被呼叫</el-button>
                        <el-button type="info" size="mini" icon="el-icon-delete" @click="deleteInfo(scope.row)">下架</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
        
        <el-dialog title="修改达人信息" :visible.sync="editInfoVisible" width="500px">
            <el-form :model="editForm" label-width="70px">
                <el-form-item label="达人分类" props="categoryIds">
                    <el-select style="width: 100%;" v-model="editForm.categoryIds" multiple>
                        <el-option v-for="item of categoryList" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="介绍标签" props="labelIds">
                    <el-select style="width: 100%;" v-model="editForm.labelIds" multiple>
                        <el-option v-for="item of labelList" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="连麦价格" props="price">
                    <el-input v-model="editForm.price" type="number" :min="0">
                        <template slot="append">分钟</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="当前封面" v-if="editForm?.cover">
                    <div class="file-item">
                        <el-image :src="fileDomain + editForm.cover" style="width: 100%;height: 100%;" />
                    </div>
                </el-form-item>
                <el-form-item label="照片视频" props="categorys" style="text-align: left;">
                    <div class="file-upload">
                        <div class="file-item" v-for="(file, index) in editForm?.content" :key="file">
                            <el-image :src="fileDomain + file" style="width: 100%;height: 100%;" v-if="isImg(file)" />
                            <video :src="fileDomain + file" style="width: 100%;height: 100%;" controls muted v-else></video>
                            <div class="action">
                                <el-button type="primary" size="mini" @click="setCover(file)">封面</el-button>
                                <el-button type="warning" size="mini" @click="replaceFile(index)">替换</el-button>
                                <el-button type="danger" size="mini" @click="removeFile(index)">删除</el-button>
                            </div>
                        </div>
                        <el-upload
                            ref="upload"
                            list-type="picture-card"
                            :limit="maxFile"
                            action="api/file/upload"
                            :file-list="uploadFileList"
                            :show-file-list="false"
                            :headers="headers" 
                            :data="uploadData"
                            :on-success="uploadSuccess"
                            multiple
                            v-if="editForm?.content?.length < maxFile"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="editInfoVisible = false">取 消</el-button>
				<el-button type="primary" :loading="editLoading" @click="submitEditForm">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="禁止被呼叫" :visible.sync="disableCallVisible" width="350px">
            <div>达人昵称: {{editRow?.nickName}}</div>
            <div class="text-left">确定后 将关闭该达人的视频在线状态 且客户端无法进行开启</div>
            <div style="margin-bottom: 10px;">
                请输入禁止被呼叫的时长
                <el-input-number v-model="disableCallDuration" :min="1" :max="1000" size="mini" style="width: 100px;"></el-input-number>
                分钟
            </div>

            <div class="text-left">禁止理由:</div>
            <el-input v-model="disableCallRemark" type="textarea" :rows="4" />
            

            <div slot="footer" class="dialog-footer">
				<el-button @click="disableCallVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitDisableCall()">确 定</el-button>
			</div>
        </el-dialog>

        <GifDialog ref="GifDialog" @confirm="confirmGif" />
    </div>
</template>

<script>
import setTableHeight from '@/utils/setTableHeight'
import { getTalentPage, updateTalent, deleteTalent, talentCategoryPage, talentLabelPage } from '@/api/api'
import GifDialog from './components/GifDialog.vue'
export default {
    components: {
        GifDialog
    },
    data() {
        return {
            fileDomain: 'https://file.oiki.cc/',
            page: 1,
            size: 10,
            total: 0,
            query: {
                userNumber: '',
                nickName: '',
                status: '',
                gender: '',
                personality: '',
            },
            tableHeight: null,
            dataList: [],

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

            maxFile: 15,
            headers: {
                Authorization: "Bearer " + localStorage.token
            },
            uploadData: {
                code: "0003",
                index: 0
            },
            uploadIndex: null,

			selectDate: [],

            editRow: null,
            disableCallDuration: null,
            disableCallRemark: '',
            disableCallVisible: false,

            categoryList: [],
            labelList: [],

            editLoading: false,
            editInfoVisible: false,
            editForm: {},

            statusAry: ['待审核','已通过','已驳回','已禁止'],
            statusColors: ['warning','success','danger','danger'],

            imageSuffix: ['bmp', 'gif', 'jpeg', 'jpg', 'png', 'tiff', 'webp']
        }
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    computed: {
        uploadFileList() {
            return this.editForm?.content?.map((path) => ({ name: path, url: this.fileDomain + path })) || []
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getTalentList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getTalentList();
        },
        getTalentList() {
            getTalentPage({
                current: this.page,
                size: this.size,
                ...this.query,
                startTime: this.selectDate && this.selectDate[0] ? this.selectDate[0] : '',
                endTime: this.selectDate && this.selectDate[1]? this.selectDate[1] : '',
            }).then(({total, records}) => {
                this.total = total;
                this.dataList = records.map(item => {
                    const files = item.content?.split(',')
                    if (files) {
                        item.files = files
                    }

                    return item
                });
            })
        },
        getCategoryList() {
            talentCategoryPage({ current: 1, size: 100 }).then(({records}) => {
                this.categoryList = records
            })
        },
        getLabelList() {
            talentLabelPage({ current: 1, size: 100 }).then(({records}) => {
                this.labelList = records
            })
        },
        isImg(path) {
            if (!path) return;
            const fileSuffix = path.split('.').pop()?.toLowerCase() || '';
            return this.imageSuffix.includes(fileSuffix)
        },
        editItem(row) {
            console.log(row,'row')
            this.$set(this.editForm, 'id', row.id)
            const categoryIds = row.categorys?.split(',').map(item => this.categoryList.find(category => category.name === item)?.id || null).filter(item => item)
            const labelIds = row.labels?.split(',').map(item => this.labelList.find(label => label.name === item)?.id || null).filter(item => item)
            const content = row.content?.split(',')
            this.$set(this.editForm, 'categoryIds', categoryIds)
            this.$set(this.editForm, 'labelIds', labelIds)
            this.$set(this.editForm, 'content', content)
            this.$set(this.editForm, 'price', row.price)
            this.$set(this.editForm, 'cover', row.cover)
            this.editInfoVisible = true
        },
        editRate(row) {
            this.$prompt('', {
                title: '修改佣金比例',
                message: this.$createElement('div', [
                    this.$createElement('div', `达人昵称：${row.nickName}`),
                    '请输入修改后的佣金比例'
                ]),
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: row.realRate,
                inputPattern: /^[0-9]+$/,
                inputErrorMessage: '请输入数字'
            }).then(({ value }) => {
                updateTalent({
                    id: row.id,
                    realRate: value
                }).then(() => {
                    this.getTalentList();
                })
            })
        },
        editSort(row) {
            this.$prompt('', {
                title: '修改达人排序',
                message: this.$createElement('div', [
                    this.$createElement('div', `达人昵称：${row.nickName}`),
                    '请输入修改后的排序数值'
                ]),
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: row.sort,
                inputPattern: /^[0-9]+$/,
                inputErrorMessage: '请输入数字'
            }).then(({ value }) => {
                updateTalent({
                    id: row.id,
                    sort: value
                }).then(() => {
                    this.getTalentList();
                })
            })
        },
        disableCalled(row) {
            this.editRow = row
            this.disableCallVisible = true
        },
        submitDisableCall() {
            if (!this.editRow || !this.editRow?.id) {
                this.$message.error('缺少重要信息，请重试')
                return
            }

            if (!this.disableCallDuration) {
                this.$message.error('请输入禁止被呼叫时长')
                return
            }
            if (!this.disableCallRemark) {
                this.$message.error('请输入禁止被呼叫理由')
                return
            }

            console.log(this.editRow,'editRow');

            updateTalent({
                id: this.editRow.id,
                duration: this.disableCallDuration,
                remark: this.disableCallRemark,
                isOnLine: 0,
                status: 4
            }).then(() => {
                this.disableCallVisible = false
                this.editRow = null
                this.disableCallDuration = null
                this.disableCallRemark = ''
                this.getTalentList();
            })
        },
        deleteInfo(row) {
            this.$confirm('', '下架', {
                message: this.$createElement('div', [
                    this.$createElement('div', `达人昵称：${row.nickName}`),
                    '确定后 将禁用达人相关功能 并将该用户的资料从达人信息中移除，请谨慎操作'
                ]),
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                deleteTalent(row.id).then(() => {
                    this.getTalentList();
                })
            })
        },
        uploadSuccess(response, file, fileList) {
            const path = response.data
            if (this.uploadIndex !== null) {
                this.editForm.content.splice(this.uploadIndex, 1, path)
                this.uploadIndex = null
            } else {
                this.editForm.content.push(path)
            }
            console.log(response, file, fileList,'uploadSuccess')
        },
        confirmGif(gifPath) {
            this.editForm.cover = gifPath
        },
        setCover(filePath) {
            if (this.isImg(filePath)) {
                this.editForm.cover = filePath
            } else {
                this.$refs.GifDialog.openGifDialog(filePath)
            }
        },
        replaceFile(index) {
            this.uploadIndex = index
            const inputDom = this.$refs.upload.$refs['upload-inner'].$refs.input
            inputDom.click()
            // this.$refs.upload.clearFiles()
        },
        removeFile(index) {
            this.editForm.content.splice(index, 1)
        },
        submitEditForm() {
            console.log(this.editForm,'editForm')
            if (this.editForm.price < 0) {
                this.$message.error('请输入正确的价格')
                return
            }
            
            const { content, ...form } = this.editForm
            const formData = {
                ...form,
                content: content?.join(','),
            }

            this.editLoading = true
            updateTalent(formData).then(() => {
                this.editInfoVisible = false
                this.editForm = {}
                this.getTalentList();
            }).finally(() => {
                this.editLoading = false
            })
        }
    },
    mounted() {
        this.getTalentList();
        this.getCategoryList()
        this.getLabelList()

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>

<style scoped>
.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.btn-flex {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.text-left {
    text-align: left;
}

.file-upload {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
}

.file-item {
    width: 110px;
    height: 110px;
    border-radius: 6px;
    position: relative;
}

.file-item .action {
    background-color: rgba(0,0,0, .6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity .3s;
}

.file-item:hover .action {
    opacity: 1;
}

.file-item .action .el-button {
    margin: 0;
}

.file-upload >>> .el-upload--picture-card {
    width: 110px;
    height: 110px;
    line-height: 114px;
}

.images-item {
    width: 60px;
    height: 60px;
    margin: 2px;
    position: relative;
}

.images-item .cover-icon {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 10px;
    color: #fff;
    background-color: #ff5858;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}
</style>