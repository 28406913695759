<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-button type="primary" @click="addClick" size="mini" icon="el-icon-plus" style="margin-bottom: 10px">添加
            </el-button>
        </div>
        <el-table :data="dataList" row-key="id" border style="width: 100%" :tree-props="{ children: 'childList' }"
            :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
            <el-table-column prop="code" label="字典编码" min-width="80" align="center">
            </el-table-column>
            <el-table-column prop="name" label="字典名称" min-width="80" align="center">
            </el-table-column>
            <el-table-column prop="value" label="字典值" min-width="80" align="center">
            </el-table-column>
            <el-table-column prop="remark" label="备注" min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="100" align="center">
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="120" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">编辑
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteById(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="添加字典" :visible.sync="addShow" :close-on-click-modal="false" width="25%" center>
            <el-form :model="addForm">
                <el-form-item label="字典编码" :label-width="formLabelWidth">
                    <el-input v-model="addForm.code" placeholder="请输入字典key" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="字典名称" :label-width="formLabelWidth">
                    <el-input v-model="addForm.name" placeholder="请输入字典名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="字典值" :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="3" v-model="addForm.value" placeholder="请输入字典value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="上级字典" :label-width="formLabelWidth">
                    <el-cascader placeholder="不选默认一级字典" v-model="selectOption" :options="dataList" :props="defaultParams"
                        @change="handleChange" filterable :show-all-levels="false"></el-cascader>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="3" v-model="addForm.remark" placeholder="选填" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addShow = false">取 消</el-button>
                <el-button type="primary" @click="addDict">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑字典" :visible.sync="updateShow" :close-on-click-modal="false" width="25%" center>
            <el-form :model="updateForm">
                <el-form-item label="字典编码" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.code" placeholder="请输入字典key" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="字典名称" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.name" placeholder="请输入字典名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="字典值" :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="3" v-model="updateForm.value" placeholder="请输入字典value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="上级字典" :label-width="formLabelWidth">
                    <el-cascader placeholder="不选默认一级字典" v-model="selectOption" :options="dataList" :props="defaultParams"
                        @change="handleChange" filterable :show-all-levels="false"></el-cascader>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="3" v-model="updateForm.remark" placeholder="选填" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="updateShow = false">取 消</el-button>
                <el-button type="primary" @click="submitUpdate">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    dictSave,
    dictDelete,
    dictUpdate,
    dictTreeList
} from "@/api/api";
export default {
    data() {
        return {
            dataList: [],
            addShow: false,
            updateShow: false,
            parentId: 0,
            addForm: {
                code: "",
                value: "",
                name: "",
                remark: ""
            },
            updateForm: {
                id: "",
                code: "",
                value: "",
                name: "",
                remark: ""
            },
            formLabelWidth: "80px",

            //级联选择器信息
            selectOption: [],
            defaultParams: {
                label: "name",
                value: "id",
                children: "childList",
                checkStrictly: true
            },
            temp: []
        };
    },
    methods: {
        //添加字典事件
        addClick() {
            this.addShow = true;
            this.selectOption = [];
            this.parentId = 0;
        },
        search() {
            this.getTreeList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //级联选择器选择事件
        handleChange(value) {
            this.selectOption = value;
        },
        //获取字典树结构
        getTreeList() {
            dictTreeList(null)
                .then(response => {
                    this.dataList = this.cleanEmptyDict(response);
                })
        },
        //递归解决数组为空导致的级联错误
        cleanEmptyDict(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].childList == null || data[i].childList.length < 1) {
                    data[i].childList = undefined;
                } else {
                    this.cleanEmptyDict(data[i].childList);
                }
            }
            return data;
        },
        //提交字典编辑事件
        submitUpdate() {
            this.updateShow = false;
            let parentCode
            if (this.selectOption.length > 0) {
                this.parentId = this.selectOption[this.selectOption.length - 1];
                parentCode = this.getSelectedDataCode(this.dataList, [...this.selectOption])
            }
            dictUpdate({
                id: this.updateForm.id,
                code: this.updateForm.code,
                name: this.updateForm.name,
                value: this.updateForm.value,
                remark: this.updateForm.remark,
                parentId: this.parentId,
                parentCode
            })
                .then(() => {
                    this.getTreeList();
                })
        },
        //编辑字典事件
        updateClick(row) {
            this.selectOption = [];
            this.updateShow = true;
            this.updateForm.id = row.id;
            this.updateForm.code = row.code;
            this.updateForm.name = row.name;
            this.updateForm.value = row.value;
            this.updateForm.remark = row.remark;
            this.parentId = 0;
            if (row.parentId != 0) {
                //循环获取上级
                this.getSelectOption(row.parentId, this.dataList);
            }
        },
        //循环获取上级
        getSelectOption(parentId, dictList) {
            for (let i = 0; i < dictList.length; i++) {
                let dict = dictList[i];
                if (dict.id == parentId) {
                    this.selectOption.unshift(dict.id);
                    if (dict.parentId != 0) {
                        //循环获取上级
                        this.getSelectOption(dict.parentId, this.dataList);
                    }
                }
                if (
                    dict.childList != null &&
                    dict.childList != undefined &&
                    dict.childList.length > 0
                ) {
                    this.getSelectOption(parentId, dict.childList);
                }
            }
        },

        //删除字典事件
        deleteById(id) {
            this.$confirm("确定要删除此字典?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.deleteDict(id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                        center: true
                    });
                });
        },
        //删除字典接口
        deleteDict(id) {
            let params = new URLSearchParams()
            params.append("idList", [id])
            dictDelete({ params })
                .then(() => {
                    window.location.reload(); //刷新页面
                    this.getTreeList(); //删除成功，重新获取数据
                })
        },
        //添加字典
        addDict() {
            let parentCode
            if (this.selectOption.length > 0) {
                this.parentId = this.selectOption[this.selectOption.length - 1];
                parentCode = this.getSelectedDataCode(this.dataList, [...this.selectOption])
            }
            dictSave({
                code: this.addForm.code,
                value: this.addForm.value,
                name: this.addForm.name,
                parentId: this.parentId,
                parentCode,
                remark: this.addForm.remark
            })
                .then(() => {
                    this.getTreeList(); //保存成功，重新获取数据
                })
            this.addShow = false;
        },
        // 递归获取字典树的code
        getSelectedDataCode(datas, ids) {
            const id = ids[0]
            const data = datas.find(item => item.id == id)
            if (ids.length > 1) {
                const childIds = ids.splice(1)
                return this.getSelectedDataCode(data.childList, childIds)
            }
            return data.code
        }
    },
    mounted() {
        this.getTreeList();
    }
};
</script>
