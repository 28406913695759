<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="query.type" placeholder="请选择类型">
                        <el-option label="全部" value=""></el-option>
                        <template v-for="value in typeSort">
                            <el-option :label="typeAry[value]" :value="value" :key="value" v-if="typeAry[value]"></el-option>
                        </template>
                        <!-- <el-option label="提现" value=3></el-option>
                        <el-option label="征友" value=4></el-option>
                        <el-option label="申请好友" value=2></el-option>
                        <el-option label="动态打赏" value=0></el-option>
						<el-option label="聊天礼物" value=1></el-option>
						<el-option label="动态激励-动态" value=5></el-option>
						<el-option label="动态激励-评论" value=6></el-option>
						<el-option label="动态激励-回复" value=7></el-option> -->
                    </el-select>
                </el-form-item>
                <el-form-item label="收支">
                    <el-select v-model="query.inOut" placeholder="请选择收支">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="收入" value="0"></el-option>
						<el-option label="支出" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="待审核" value=0></el-option>
                        <el-option label="通过" value=1></el-option>
                        <el-option label="拒绝" value=2></el-option>
                        <el-option label="已到账" value=3></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间">
					<el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
				</el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-row :gutter="12">
                    <el-col :span="4">
                        <el-card shadow="hover" style="width: 100%;margin-bottom: 10px;">
                            <!-- <div style="width: 100%;"> -->
                                <el-statistic :value="totalMoney" title="金额统计"></el-statistic>
                            <!-- </div> -->
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="用户昵称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="用户ID" min-width="80" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="money" label="金额" min-width="100" align="center"></el-table-column>
            <el-table-column prop="totalMoney" label="剩余金额" min-width="100" align="center"></el-table-column>
            <el-table-column prop="inOut" label="收支" min-width="100" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.inOut=== 0" style="color: #67C23A">收入</span>
                    <span v-else-if="scope.row.inOut=== 1" style="color: #F56C6C">支出</span>
				</template>
            </el-table-column>
            <el-table-column prop="type" label="类型" min-width="100" align="center">
                <template slot-scope="scope">
					<!-- <span v-if="scope.row.type=== 0">动态打赏</span>
					<span v-else-if="scope.row.type=== 2">申请好友</span>
                    <span v-else-if="scope.row.type=== 1">聊天礼物</span>
                    <span v-else-if="scope.row.type=== 3">提现</span>
                    <span v-else-if="scope.row.type=== 4">征友</span>
                    <span v-else-if="scope.row.type=== 5">动态激励-动态</span>
                    <span v-else-if="scope.row.type=== 6">动态激励-评论</span>
                    <span v-else-if="scope.row.type=== 7">动态激励-回复</span> -->
                    {{ typeAry[scope.row.type] }}
				</template>
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="100" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.status=== 0">待审核</span>
                    <span v-else-if="scope.row.status=== 1">通过</span>
					<span v-else-if="scope.row.status=== 2">拒绝</span>
                    <span v-else-if="scope.row.status=== 3" style="color: #67C23A">已到账</span>
				</template>
            </el-table-column>
            <el-table-column prop="useDesc" label="用途" min-width="180" align="center">
                <template slot-scope="scope">
                    {{ scope.row.useDesc }}
                    <div class="div" v-if="scope.row.inOut === 0 && [0,1].indexOf(scope.row.type) >= 0">
                        <template v-if="scope.row.type === 0 && scope.row.sourceId">
                            -<a :href="`/article/${scope.row.sourceId}`" target="_blank">动态</a>
                        </template>
                        <template v-if="scope.row.giveUserId">
                            -<a :href="`/user/${scope.row.giveUserId}`" target="_blank">{{ scope.row.giveUserNumber }}</a>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    balancePage,
    balanceStatistics
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            selectDate: [],
            dataList: [],
            tableHeight: null,
            query: {
                nickName: "",
                userNumber: "",
                type: "",
                inOut: "",
                status: "3"
            },
            totalMoney: 0,
            formLabelWidth: "80px",

            typeAry: [
                '动态打赏',
                '申请好友',
                '聊天礼物',
                '提现',
                '征友',
                '动态激励-动态',
                '动态激励-评论',
                '动态激励-回复',
                '推广奖励',
                '群聊礼物',
                '',
                '视频连麦收益',
                '连麦礼物收益',
            ],
            typeSort: [0,2,1,3,4,8,9,11,12,10,5,6,7],

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getBalanceList();
            this.getBalanceStatistics()
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getBalanceList();
        },
        //获取待审核动态分页列表
        getBalanceList() {
            balancePage({
                current: this.page,
                size: this.size,
                inOut: this.query.inOut,
                status: this.query.status,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                type: this.query.type,
                startTime: this.selectDate != null ? this.selectDate[0] : null,
				endTime: this.selectDate != null ? this.selectDate[1] : null,
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                })
        },
        // 获取统计金额
        getBalanceStatistics() {
            balanceStatistics({
                current: this.page,
                size: this.size,
                inOut: this.query.inOut,
                status: this.query.status,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                type: this.query.type,
                startTime: this.selectDate != null ? this.selectDate[0] : null,
				endTime: this.selectDate != null ? this.selectDate[1] : null,
            })
                .then(response => {
                    this.totalMoney = response
                })
        }
    },
    mounted() {
        this.getBalanceList();
        this.getBalanceStatistics()

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>