import { render, staticRenderFns } from "./UserDetail.vue?vue&type=template&id=62c53c64&scoped=true&"
import script from "./UserDetail.vue?vue&type=script&lang=js&"
export * from "./UserDetail.vue?vue&type=script&lang=js&"
import style0 from "./UserDetail.vue?vue&type=style&index=0&id=62c53c64&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c53c64",
  null
  
)

export default component.exports