<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
                <el-form-item label="标签名称">
                    <el-input v-model="query.name" placeholder="请输入标签名称" clearable @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in statusAry" :key="label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="name" label="标签名称" min-width="100" align="center"></el-table-column>
            <el-table-column prop="number" label="反馈人数" min-width="80" align="center"></el-table-column>
            <!-- <el-table-column prop="remark" label="备注" min-width="200" align="center"></el-table-column> -->
            
            <el-table-column prop="createTime" label="创建时间" min-width="140" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" min-width="80" align="center">
                <template slot-scope="scope">
                    <span :class="'color-' + statusColors[scope.row.status]">{{ statusAry[scope.row.status] }}</span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="140" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="labelVerify(scope.row.id, 1)" v-if="scope.row.status !== 1">采纳</el-button>
                    <el-button size="mini" type="warning" @click="labelVerify(scope.row.id,2)" v-if="scope.row.status == 0">忽略</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import setTableHeight from '@/utils/setTableHeight'
import { getTalentLabelApplyPage, verifyTalentLabelApply } from '@/api/api'
export default {
    data() {
        return {
            fileDomain: 'https://file.oiki.cc/',
            page: 1,
            size: 10,
            total: 0,
            query: {
                name: '',
                status: 0,
            },
            tableHeight: null,
            dataList: [{}],

            statusAry: ['新增','已采纳','已忽略'],
            statusColors: ['warning','success','danger'],
        }
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getLabelApplyList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getLabelApplyList();
        },
        getLabelApplyList() {
            getTalentLabelApplyPage({
                current: this.page,
                size: this.size,
                ...this.query
            }).then(({total, records}) => {
                this.total = total;
                this.dataList = records.map(item => {
                    const files = item.content?.split(',')
                    if (files) {
                        item.files = files
                    }

                    return item
                });
            })
        },
        labelVerify(id, status) {
            verifyTalentLabelApply({id,status}).then(() => {
                this.getLabelApplyList()
            })
            // this.$message.info('功能尚未对接')
        }
    },
    mounted() {
        this.getLabelApplyList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>

<style scoped>

</style>