<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
                <el-form-item label="用户编号">
                    <el-input v-model="query.userNumber" placeholder="请输入用户编号"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item label="连麦状态">
                    <el-select v-model="query.status" placeholder="请选择状态" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(label, value) in statusAry" :key="value" :label="label" :value="value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="连麦时间">
					<el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="orderNum" label="连麦ID" min-width="160" align="center"></el-table-column>
            <el-table-column prop="channelId" label="房间ID" min-width="260" align="center"></el-table-column>
            <el-table-column prop="taskId" label="易盾直播检测任务ID" min-width="260" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="呼叫者" min-width="300" align="center">
                <template slot-scope="scope">
                    {{ scope.row.nickName }} - 
                    <a :href="'/userNumber/' + scope.row.userNumber" target="_blank">{{ scope.row.userNumber }}</a> - 
                    <span v-if="scope.row.gender === 0" style="color: #c78b13">未知</span>
					<span v-else-if="scope.row.gender === 1" style="color: #112db9">男</span>
					<span v-else-if="scope.row.gender === 2" style="color: #b91187">女</span>
                     - 
                    {{ scope.row.personalityName }}
                </template>
            </el-table-column>
            <el-table-column prop="userNumber" label="达人" min-width="300" align="center">
                <template slot-scope="scope">
                    {{ scope.row.talentNickName }} - 
                    <a :href="'/userNumber/' + scope.row.talentUserNumber" target="_blank">{{ scope.row.talentUserNumber }}</a> - 
                    <span v-if="scope.row.talentGender === 0" style="color: #c78b13">未知</span>
					<span v-else-if="scope.row.talentGender === 1" style="color: #112db9">男</span>
					<span v-else-if="scope.row.talentGender === 2" style="color: #b91187">女</span>
                     - 
                    {{ scope.row.talentPersonalityName }}
                </template>
            </el-table-column>
            
            <el-table-column prop="duration" label="连麦时长" min-width="100" align="center">
                <template slot-scope="scope">
                    {{ getCallTime(scope.row) }}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="" label="挂断方" min-width="140" align="center"></el-table-column> -->
            <el-table-column prop="giftNumber" label="送礼K币" min-width="100" align="center">
                <template slot-scope="scope">
                    {{ scope.row.giftNumber || 0 }}
                </template>
            </el-table-column>
            <el-table-column prop="freeDuration" label="是否使用免费时长" min-width="140" align="center">
                <template slot-scope="scope">
                    {{ scope.row.freeDuration > 0 ? '是' : '否' }}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="连麦时间" min-width="140" align="center"></el-table-column>
            <el-table-column prop="status" label="通话状态" min-width="100" align="center">
                <template slot-scope="scope">
                    {{ statusAry[scope.row.status] }}
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import setTableHeight from '@/utils/setTableHeight'
import { getTalentCallPage } from '@/api/api'
export default {
    data() {
        return {
            fileDomain: 'https://file.oiki.cc/',
            page: 1,
            size: 10,
            total: 0,
            query: {
                userNumber: '',
                nickName: '',
                status: '',
            },
            selectDate: [],
            tableHeight: null,
            dataList: [],

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

            statusAry: ['待接听','同意','拒绝','取消通话','超时','完成通话','通话异常'],
        }
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getTalentCallList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getTalentCallList();
        },
        getTalentCallList() {
            getTalentCallPage({
                current: this.page,
                size: this.size,
                ...this.query,
                startTime: this.selectDate && this.selectDate[0] || null,
                endTime: this.selectDate && this.selectDate[1] || null,
            }).then(({total, records}) => {
                this.total = total;
                this.dataList = records.map(item => {
                    const files = item.content?.split(',')
                    if (files) {
                        item.files = files
                    }

                    return item
                });
            })
        },
        getCallTime(row) {
            const { startTime, endTime } = row
            if (startTime && endTime) {
                const start = new Date(startTime)
                const end = new Date(endTime)
                const timestamp = end.getTime() - start.getTime()
                // console.log(timestamp,'timestamp')

                // 转换为秒
                const totalSeconds = Math.floor(timestamp / 1000);
                
                // 计算小时、分钟和秒
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = totalSeconds % 60;
                
                // 格式化为 HH:MM:SS
                return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            }
            return ''
        }
    },
    mounted() {
        this.getTalentCallList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>

<style scoped>

</style>