<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
                <el-form-item label="用户编号">
                    <el-input v-model="query.userNumber" placeholder="请输入用户编号"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="待处理" value="0"></el-option>
                        <el-option label="已处理" value="1"></el-option>
                        <el-option label="已忽略" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="orderNum" label="连麦ID" min-width="160" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="投诉人" min-width="200" align="center">
                <template slot-scope="scope">
                    {{ scope.row.nickName }}
                    -
                    <a :href="'/userNumber/' + scope.row.userNumber" target="_blank">{{ scope.row.userNumber }}</a> - 
                    <span v-if="scope.row.gender === 0" style="color: #c78b13">未知</span>
					<span v-else-if="scope.row.gender === 1" style="color: #112db9">男</span>
					<span v-else-if="scope.row.gender === 2" style="color: #b91187">女</span>
                     - 
                    {{ scope.row.personalityName }}
                </template>
            </el-table-column>
            <el-table-column prop="type" label="投诉人角色" min-width="120" align="center">
                <template slot-scope="scope">
                    {{  scope.row.type === 0 ? '呼叫方' : '接听方'  }}
                </template>
            </el-table-column>
            <el-table-column prop="userNumber" label="被投诉人" min-width="200" align="center">
                <template slot-scope="scope">
                    {{ scope.row.toNickName }}
                    -
                    <a :href="'/userNumber/' + scope.row.toUserNumber" target="_blank">{{ scope.row.toUserNumber }}</a> - 
                    <span v-if="scope.row.toGender === 0" style="color: #c78b13">未知</span>
					<span v-else-if="scope.row.toGender === 1" style="color: #112db9">男</span>
					<span v-else-if="scope.row.toGender === 2" style="color: #b91187">女</span>
                     - 
                    {{ scope.row.toPersonalityName }}
                </template>
            </el-table-column>
            
            <el-table-column prop="createTime" label="投诉时间" min-width="140" align="center"></el-table-column>
            <el-table-column prop="category" label="投诉原因" min-width="140" align="center"></el-table-column>
            <el-table-column prop="content" label="原因描述" min-width="140" align="center"></el-table-column>
            <el-table-column prop="startTime" label="连麦时间" min-width="140" align="center"></el-table-column>
            <el-table-column prop="duration" label="连麦时长" min-width="140" align="center">
                <template slot-scope="scope">
                    {{ getCallTime(scope.row) }}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="140" align="center">
                <template slot-scope="scope">
                    <span :class="[statusColors[scope.row.status]]">{{ statusAry[scope.row.status] }}</span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="220" align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.status === 0" type="success" size="mini" icon="el-icon-check" @click="checkStatus(scope.row.id, 1)">设为已处理</el-button>
                    <el-button v-if="scope.row.status === 0" type="warning" size="mini" icon="el-icon-close" @click="checkStatus(scope.row.id, 2)">忽略</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import setTableHeight from '@/utils/setTableHeight'
import { getTalentReportPage, reportUpdate } from '@/api/api'
export default {
    data() {
        return {
            fileDomain: 'https://file.oiki.cc/',
            page: 1,
            size: 10,
            total: 0,
            query: {
                userNumber: '',
                nickName: '',
                status: '0',
            },
            tableHeight: null,
            dataList: [{}],

            statusAry: ['待处理','已处理','已忽略'],
            statusColors: ['color-warning','color-success','color-danger'],
        }
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getReportList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getReportList();
        },
        getReportList() {
            getTalentReportPage({
                current: this.page,
                size: this.size,
                ...this.query
            }).then(({total, records}) => {
                this.total = total;
                this.dataList = records.map(item => {
                    const files = item.content?.split(',')
                    if (files) {
                        item.files = files
                    }

                    return item
                });
            })
        },
        checkStatus(id, status) {
            reportUpdate({ id, status }).then(() => {
                this.getReportList();
            })
        },
        getCallTime(row) {
            const { startTime, endTime } = row
            if (startTime && endTime) {
                const start = new Date(startTime)
                const end = new Date(endTime)
                const timestamp = end.getTime() - start.getTime()
                console.log(timestamp,'timestamp')

                // 转换为秒
                const totalSeconds = Math.floor(timestamp / 1000);
                
                // 计算小时、分钟和秒
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = totalSeconds % 60;
                
                // 格式化为 HH:MM:SS
                return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            }
            return ''
        }
    },
    mounted() {
        this.getReportList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>

<style scoped>

</style>