<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="群名称">
					<el-input v-model="query.name" placeholder="群名称" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="群主昵称">
					<el-input v-model="query.nickName" placeholder="群主昵称" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="群主ID">
					<el-input v-model="query.owner" placeholder="群主ID" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="群分类">
					<el-select v-model="query.categoryId" placeholder="群分类" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.name" :value="item.id" v-for="item in categoryList" :key="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="warning" icon="el-icon-circle-plus-outline" @click="addRow">创建群聊</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="icon" label="群头像" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 40px;" :src="fileDomain + scope.row.icon" :preview-src-list="[fileDomain + scope.row.icon]" />
                </template>
            </el-table-column>
			<el-table-column prop="name" label="群名称" min-width="100" align="center"></el-table-column>
			<el-table-column prop="labels" label="群标签" min-width="100" align="center"></el-table-column>
			<el-table-column prop="categoryName" label="群分类" min-width="100" align="center"></el-table-column>
			<el-table-column prop="owner" label="群主" min-width="100" align="center">
                <template slot-scope="scope">
                    {{ scope.row.nickName }}
                    <!-- (<a :href="'/userNumber/' + scope.row.owner" target="_blank" >{{ scope.row.owner }}</a>) -->
                </template>
            </el-table-column>
            <el-table-column prop="womanCoinNumber" label="女性进群费用" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="{'color-warning': scope.row.womanCoinNumber > 0}">{{ scope.row.womanCoinNumber > 0 ? scope.row.womanCoinNumber : '免费' }}</span>
                </template>
            </el-table-column>
			<el-table-column prop="manCoinNumber" label="男性进群费用" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="{'color-warning': scope.row.manCoinNumber > 0}">{{ scope.row.manCoinNumber > 0 ? scope.row.manCoinNumber : '免费' }}</span>
                </template>
            </el-table-column>
			<el-table-column prop="vipCoinNumber" label="贵族进群费用" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="{'color-warning': scope.row.vipCoinNumber > 0}">{{ scope.row.vipCoinNumber > 0 ? scope.row.vipCoinNumber : '免费' }}</span>
                </template>
            </el-table-column>
			<el-table-column prop="autoKickOut" label="自动踢出不活跃用户天数" min-width="110" align="center"></el-table-column>
			<el-table-column prop="totalNumber" label="群最大容纳人数" min-width="100" align="center"></el-table-column>
			<el-table-column prop="createTime" label="建群时间" min-width="150" align="center"></el-table-column>
			<el-table-column fixed="right" label="操作" min-width="60" align="center">
				<template slot-scope="scope">
                    <el-button type="text" size="mini" @click="openDetail(scope.row)" >详情</el-button>
					<!-- <el-button type="warning" size="mini" @click="editRow(scope.row)">修改</el-button> -->
					<!-- <el-button type="danger" size="mini" @click="deleteRow(scope.row.id)">删除</el-button> -->
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

        <InfoFormDialog ref="infoFormDialog" @success="getTeamChatList" />
    </div>
</template>

<script>
import {
    teamChatPage,
    teamCategoryPage,
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
import InfoFormDialog from './components/InfoFormDialog.vue'
export default {
    components: {
        InfoFormDialog
    },
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				name: '',
				nickName: '',
				owner: '',
				categoryId: '',
            },

            fileDomain: 'https://file.oiki.cc/',
            categoryList: [],
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getTeamChatList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.lastPayTime = []
			this.expireTime = []

			this.page = 1
			this.getTeamChatList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getTeamChatList()
		},
		changeClick(page) {
			this.page = page
			this.getTeamChatList()
		},
		getTeamChatList() {
			teamChatPage({
				"current": this.page,
				"size": this.size,
				"name": this.query.name,
				"nickName": this.query.nickName,
				"owner": this.query.owner,
				"categoryId": this.query.categoryId,
			})
				.then((response) => {
					this.total = response.total
					this.tableData = response.records
				})
				.catch((error) => {
					console.log(error)
				});
		},
        getCategoryList() {
            teamCategoryPage().then(res => {
                this.categoryList = res
            })
        },
        
        addRow() {
            this.$refs.infoFormDialog.openForm()
        },
        editRow(row) {
            this.$refs.infoFormDialog.openForm(row)
        },
        openDetail(row) {
            // sessionStorage.setItem('teamChatInfo', JSON.stringify(row))
            // window.open(`/chatGroupDetail/${row.id}`)
            this.$router.push(`/chatGroupDetail/${row.tid}`)
        }
	},
	created() {
		this.getTeamChatList()
        this.getCategoryList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>

</style>