<template>
    <div>
        <!-- <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
                <el-form-item label="用户编号">
                    <el-input v-model="query.userNumber" placeholder="请输入用户编号"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item label="审核状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in statusAry" :key="label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div> -->
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="userNumber" label="用户编号" min-width="120" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="nickName" label="用户昵称" min-width="120" align="center"></el-table-column>
            <el-table-column prop="gender" label="性别" min-width="50" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.gender === 0" style="color: #c78b13">未知</span>
					<span v-else-if="scope.row.gender === 1" style="color: #112db9">男</span>
					<span v-else-if="scope.row.gender === 2" style="color: #b91187">女</span>
				</template>
			</el-table-column>
            <el-table-column prop="personalityName" label="社交人格" min-width="80" align="center"></el-table-column>
            <el-table-column prop="type" label="修改项目" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ typeAry[scope.row.type] }}
                </template>
            </el-table-column>

            <el-table-column label="原内容" min-width="200" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.type === 1">
                        <div class="flex">
                            <div class="images-item" v-for="(file, index) in scope.row.content.split(',')" :key="index">
                                <template v-if="file.indexOf('mp4') > -1">
                                    <video style="width: 100%; height: 100%;" controls autoplay loop muted :src="fileDomain + file" />
                                </template>
                                <template v-else>
                                    <el-image style="width: 100%; height: 100%;" :src="fileDomain + file" :preview-src-list="[fileDomain + file]" ></el-image>
                                </template>
                                <!-- <i class="el-icon-s-ticket cover-icon" v-if="scope.row.ext === file"></i> -->
                            </div>
                        </div>
                    </div>
                    <div v-else>{{ scope.row.content }}</div>
                </template>
            </el-table-column>

            <el-table-column label="修改后内容" min-width="200" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.type === 1">
                        <div class="flex">
                            <div class="images-item" v-for="(file, index) in scope.row.newContent.split(',')" :key="index">
                                <template v-if="file.indexOf('mp4') > -1">
                                    <video style="width: 100%; height: 100%;" controls autoplay loop muted :src="fileDomain + file" />
                                </template>
                                <template v-else>
                                    <el-image style="width: 100%; height: 100%;" :src="fileDomain + file" :preview-src-list="[fileDomain + file]" ></el-image>
                                </template>
                                <!-- <i class="el-icon-s-ticket cover-icon" v-if="scope.row.ext === file"></i> -->
                            </div>
                        </div>
                    </div>
                    <div v-else>{{ scope.row.newContent }}</div>
                </template>
            </el-table-column>

            
            <el-table-column prop="createTime" label="时间" min-width="140" align="center"></el-table-column>
            <el-table-column prop="status" label="审核状态" min-width="80" align="center">
				<template slot-scope="scope">
					<span :class="[`color-${statusColors[scope.row.status]}`]">{{statusAry[scope.row.status]}}</span>
				</template>
			</el-table-column>
            <el-table-column fixed="right" label="操作" min-width="180" align="center">
                <template slot-scope="scope">
                    <!-- <el-button v-if="scope.row.status === 0" type="success" size="mini" @click="checkClick(scope.row.id, 1, 0, null)">一键同意</el-button> -->
                    <template v-if="scope.row.status === 0">
                        <el-button type="success" size="mini" icon="el-icon-check" @click="approved(scope.row)">通过</el-button>
                        <el-button type="warning" size="mini" icon="el-icon-close" @click="refuse(scope.row)">驳回</el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog title="是否通过修改？" :visible.sync="approvedDialogVisible" width="30%" :close-on-click-modal="false">
            
            <div class="cover" v-if="checkRow?.ext?.indexOf('mp4') > -1">
                <div>本次修改更换了封面 选择的封面为视频</div>
                <div v-if="newGifCover">
                    <el-image :src="fileDomain + newGifCover" style="width: 100px;" :preview-src-list="[fileDomain + newGifCover]"  />
                </div>
                <div v-else>所选封面为视频文件 需进行截取并生成GIF</div>
                <el-button type="primary" size="mini" @click="openGifDialog">操作</el-button>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeApprovedDialog">取 消</el-button>
				<el-button type="primary" :disabled="checkConfirmDisabled" :loading="checkLoading" @click="checkApproved">确 定</el-button>
            </div>
        </el-dialog>
        
        <GifDialog ref="GifDialog" @confirm="confirmGif" />

        <el-dialog title="驳回理由" :visible.sync="refuseDialogVisible" width="600px" :close-on-click-modal="false">
            <el-input type="textarea" :rows="4" v-model="refuseContent" placeholder="请输入驳回原因" />

            <div slot="footer" class="dialog-footer">
                <el-button @click="refuseDialogVisible = false">取 消</el-button>
				<el-button type="primary" :disabled="refuseConfirmDisabled" :loading="checkLoading" @click="checkRefuse">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import setTableHeight from '@/utils/setTableHeight'
import { getTalentUpdatePage, checkTalent } from '@/api/api'
import GifDialog from './components/GifDialog.vue'
export default {
    components: {
        GifDialog
    },
    data() {
        return {
            fileDomain: 'https://file.oiki.cc/',
            page: 1,
            size: 10,
            total: 0,
            /* query: {
                userNumber: '',
                nickName: '',
                status: '',
            }, */
            tableHeight: null,
            dataList: [{}],
            typeAry: ['分类','真人照片或视频','价格','标签'],
            statusAry: ['待审核','已通过','已驳回','已禁止'],
            statusColors: ['warning','success','danger','danger'],

            checkRow: null,
            newGifCover: null,
            checkLoading: false,

            refuseContent: '',

            approvedDialogVisible: false,
            refuseDialogVisible: false,            
        }
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    computed: {
        isValidTimeRange() {
            const start = this.parseTimeString(this.startTime)
            const end = this.parseTimeString(this.endTime)
            return start !== null && end !== null && start < end && end <= this.videoDuration
        },
        checkConfirmDisabled() {
            if (this.checkRow === null || (this.checkRow?.ext?.indexOf('mp4') > -1 && this.newGifCover === null)) {
                return true
            }
            return false
        },
        refuseConfirmDisabled() {
            return !this.refuseContent
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getUpdateList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getUpdateList();
        },
        getUpdateList() {
            getTalentUpdatePage({
                current: this.page,
                size: this.size,
            }).then(({total, records}) => {
                this.total = total;
                this.dataList = records.map(item => {
                    const files = item.content?.split(',')
                    if (files) {
                        item.files = files
                    }

                    return item
                });
            })
        },
        approved(row) {
            this.checkRow = row
            this.approvedDialogVisible = true
        },
        refuse(row) {
            this.checkRow = row
            this.refuseDialogVisible = true
        },
        
        initVerifyData() {
            this.checkRow = null
        },
        
        closeApprovedDialog() {
            this.approvedDialogVisible = false
            this.initVerifyData()
        },
        checkApproved() {
            if (this.checkConfirmDisabled) return;
            const formData = {
                id: this.checkRow.id,
                status: 1
            }
            if (this.checkRow?.ext?.indexOf('mp4') > -1 && this.newGifCover) {
                formData.ext = this.newGifCover
            }
            console.log(formData, 'formData')
            // return;
            this.checkLoading = true
            checkTalent(formData).then(() => {
                this.approvedDialogVisible = false
                this.checkRow = null
                this.newGifCover = null
                this.getUpdateList()
            }).finally(() => {
                this.checkLoading = false
            })
        },
        checkRefuse() {
            const formData = {
                id: this.checkRow.id,
                status: 2,
                remark: this.refuseContent
            }
            console.log(formData,'formData')
            // return;
            this.checkLoading = true
            checkTalent(formData).then(() => {
                this.refuseDialogVisible = false
                this.getUpdateList()
            }).finally(() => {
                this.checkLoading = false
            })
        },
        openGifDialog() {
            this.$refs.GifDialog.openGifDialog(this.checkRow.ext)
        },
        confirmGif(gifPath) {
            this.newGifCover = gifPath
        }
    },
    mounted() {
        this.getUpdateList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>

<style scoped>
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.images-item {
    width: 60px;
    height: 60px;
    margin: 2px;
    position: relative;
}

.images-item .cover-icon {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 10px;
    color: #fff;
    background-color: #ff5858;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

.video-card {
    margin-bottom: 20px;
}

.video-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
}

.video-player {
    max-width: 100%;
    max-height: 400px;
}

.video-gif {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.controls {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.time-range-selector {
    margin: 20px 0;
    padding: 0 20px;
}

.time-slider {
    margin: 0 20px 15px;
}

.quick-time-buttons {
    display: flex;
    gap: 5px;
    margin-left: 10px;
}

.time-inputs {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.time-input {
    width: 120px;
}

.frame-interval {
    display: flex;
    align-items: center;
    gap: 10px;
}

.frames-card {
    margin-top: 20px;
    text-align: center;
}

.frames-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.frames-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 10px;
}

.frames-header h3 {
    margin: 0;
}

.frames-settings {
    display: flex;
    align-items: center;
    gap: 10px;
}

.delay-label {
    color: #606266;
    font-size: 14px;
}

.frame-item {
    position: relative;
    width: 167px;
}

.frame-item img {
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-radius: 4px;
}

.frame-actions {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    gap: 5px;
}

.frame-time {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
}

.preview-card {
    margin-top: 20px;
    text-align: center;
}

.preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.gif-preview {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}

.refuse-item {
    margin-top: 20px;
}

.refuse-item .title-row {
    display: flex;
    gap: 30px;
    margin-bottom: 10px;
}
</style>